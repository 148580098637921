import React, { useEffect } from "react";
import { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { VALIDATION_MESSAGES } from "../utils/validationMessages";
import * as Yup from "yup";
import { changeSkuBlock } from "../network/lib/changeSkuBlock";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { BlockedSKUTable } from "../components/BlockedSKUTable";
import { UnblockAllButton } from "../components/UnblockAllButton";
import { getBlockedSkus } from "../network/lib/getBlockedSkus";
import { countRemainingLieferscheineAndAmountForBlockedSkus } from "../network/lib/countRemainingLieferscheineAndAmountForBlockedSkus";
import { countHiddenLieferscheine } from "../network/lib/countHiddenLieferscheine";
import { handleErrors } from "../utils/handleErrors";

interface BlockerProps {
  SectionTitle: string;
  lane_ids: number[];
}

export const BlockProducts = ({ SectionTitle, lane_ids }: BlockerProps) => {
  const [skuChange, setSkuChange] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [hiddenLieferscheineCount, setHiddenLieferscheineCount] = useState([]);
  const [blockedSkus, setBlockedSkus] = useState([]);
  const [remainingLieferscheine, setRemainingLieferscheine] = useState([
    { product_sku: "", product_name: "",storage_location: "", lieferscheine_amount: 0, total_amount: 0 },
  ]);

  const refreshPageAfterAllSkuUnblocked = () => {
    setSkuChange((prevSkuChange) => !prevSkuChange);
  };

  const handleGetBlockedSkus = async () => {
    try {
      await getBlockedSkus(lane_ids).then((response) => {
        if (response.status === 200) {
          console.log(response?.data);
          setBlockedSkus(response?.data);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleCountRemainingLieferscheineAndAmountForBlockedSkus = async () => {
    try {
      await countRemainingLieferscheineAndAmountForBlockedSkus(lane_ids).then(
        (response) => {
          if (response?.status === 200) {
            console.log(response);
            setRemainingLieferscheine(response?.data);
          }
        }
      );
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleCountHiddenLieferscheine = async () => {
    try {
      await countHiddenLieferscheine(lane_ids).then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setHiddenLieferscheineCount(response?.data);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  useEffect(() => {
    handleGetBlockedSkus();
    handleCountRemainingLieferscheineAndAmountForBlockedSkus();
    handleCountHiddenLieferscheine();
  }, [skuChange]);

  return (
    <>
      <div className="flex flex-col items-center">
        {/* Change header for sku block section based on warehouse */}
        <h2 className="text-2xl font-bold">{SectionTitle}</h2>
        <Formik
          initialValues={{
            product_sku: "",
            block: true,
          }}
          validationSchema={Yup.object({
            product_sku: Yup.string().required(
              VALIDATION_MESSAGES.inputNotFilled
            ),
            block: Yup.boolean().required(VALIDATION_MESSAGES.inputNotFilled),
          })}
          onSubmit={async (data, { setStatus, resetForm }) => {
            try {
              await changeSkuBlock(
                {
                  product_sku: data.product_sku,
                  block: data.block,
                },
                lane_ids
              ).then((response) => {
                if (response.status === 200) {
                  setSkuChange((prevSkuChange) => !prevSkuChange);
                  setStatus("SKU blocked!");
                  resetForm();
                }
              });
            } catch (error: any) {
              handleErrors(
                error,
                {
                  400: "SKU cannot be blocked",
                  401: "Not enough permissions to block SKU",
                  404: "SKU not found in Xentral",
                },
                setError,
                setErrMsg
              );
              setTimeout(() => {
                setError(false);
              }, 3000);
            }
          }}
        >
          {({ status }) => (
            <Form id="login-form">
              <div className="flex items-center px-2">
                <div className="flex flex-col w-full max-w-xs">
                  <div className="flex gap-2">
                    <div>
                      <label className="label label-text" htmlFor="product_sku">
                        Produkt SKU
                      </label>
                      <Field
                        name="product_sku"
                        type="text"
                        className="input input-bordered"
                        id="product_sku"
                      />
                    </div>
                    <div>
                      <label className="label label-text" htmlFor="block">
                        Aktion
                      </label>
                      <Field
                        name="block"
                        id="block"
                        className="input input-bordered"
                        as="select"
                      >
                        <option value="true">Sperren</option>
                        <option value="false">Entsperren</option>
                      </Field>
                      <ErrorMessage name="block" component="div" />
                    </div>
                  </div>
                  <div className="flex gap-4 items-end">
                    <button
                      type="submit"
                      id="btn-login"
                      className="btn btn-primary w-40 mt-6"
                    >
                      Ausführen
                    </button>
                    <div className="flex items-end">
                      <p className="font-bold">Gesperrte Lieferscheine</p>
                      <span className="font-mono text-2xl">
                        <span></span>
                        {hiddenLieferscheineCount}
                      </span>
                    </div>
                    {status && <div className="text-green-700">{status}</div>}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error && (
        <div className="flex justify-center">
          <ErrorBar message={errMsg} />
        </div>
      )}
      <h2 className="text-xl font-bold mt-4">Geperrte Skus:</h2>
      <div className="flex gap-1 flex-wrap">
        {blockedSkus.map((sku, index) => (
          <div
            key={index}
            className="badge badge-error badge-outline badge-lg mt-2"
          >
            {sku}
          </div>
        ))}
      </div>
      <>
        <BlockedSKUTable remainingLieferscheine={remainingLieferscheine} />
        <UnblockAllButton
          lanes={lane_ids}
          refreshFunc={refreshPageAfterAllSkuUnblocked}
        />
      </>
    </>
  );
};

import { useEffect, useState, useRef } from "react";
import * as _ from "lodash";
import React from "react";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { ErrorBar } from "./ErrorBar";
import ScanMultipleModal from "./ScanMultipleModal";
import { useNavigate } from "react-router-dom";
import { SuccessBar } from "./SuccessBar";

interface PickingTableProps {
  processed: any[];
}
export function PickingTableDummy({ processed }: PickingTableProps) {
  const codeRef = useRef<any>(null);
  const [shipmentPosition, setShipmentPosition] = useState<any[]>([]);
  const [lastScannedEan, setLastScannedEan] = useState("");
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [maxScanAmount, setMaxScanAmount] = useState(1);
  const [multipleProduct, setMultipleProduct] = useState("");
  const [scanningDone, setScanningDone] = useState(false);

  const navigate = useNavigate();

  const productIsMultiple = (ean: any) => {
    const shouldShowModal = true;

    // Exit early if modal should not be shown
    if (!shouldShowModal) {
      return [false, {}];
    }

    // minimum Product Amount required to show modal
    let minProductAmount = 10;
    let hasNonMultiple = shipmentPosition.find((obj) => {
      return (
        obj.ean === ean &&
        obj.done === false &&
        obj.amountNeeded - obj.amountScanned >= 1 &&
        obj.amountNeeded - obj.amountScanned < 2
      );
    });
    if (hasNonMultiple) {
      return [false, {}];
    }

    // Check if Product Amount is above minProductAmount
    let result = shipmentPosition.find((obj) => {
      return (
        obj.ean === ean &&
        obj.done === false &&
        obj.amountNeeded - obj.amountScanned >= minProductAmount
      );
    });
    if (result == null) {
      return [false, {}];
    } else {
      let productName = result.cells?.find((obj: any) => {
        return obj.columnName === "product_name";
      })?.value;
      return [
        true,
        {
          ean: ean,
          productName: productName,
          maxAmount: result.amountNeeded - result.amountScanned,
        },
      ];
    }
  };

  const handlePreScan = () => {
    const multipleResult: any = productIsMultiple(codeRef.current.value || "");

    console.log(multipleResult);
    if (multipleResult[0] === false) {
      handleScan(1);
    } else {
      // Open the modal
      setShowModal(true);
      setMaxScanAmount(multipleResult[1].maxAmount);
      setMultipleProduct(multipleResult[1].productName);
    }
  };

  const handleScan = (scanAmount: number) => {
    setShipmentPosition((prevItems: any[]) => {
      let hadArticleMatch = false;
      let wasIncremented = false;
      let wasAmountReached = false;
      console.log(prevItems);
      const updatedItems = prevItems.map((item: any) => {
        const { ean, amountNeeded, amountScanned, cells } = item;
        const istCellIndex = cells.findIndex(
          (cell: any) => cell.columnName === "ist"
        );

        if (ean !== codeRef.current.value) {
          return item;
        }

        if (amountNeeded === amountScanned || amountNeeded <= amountScanned) {
          wasAmountReached = true;
          return item;
        }

        if (hadArticleMatch) {
          return item;
        }

        const itemCopy = { ...item, amountScanned: amountScanned + scanAmount };
        const cellCopy = [...cells];
        cellCopy[istCellIndex].value += scanAmount;
        wasIncremented = true;
        hadArticleMatch = true;

        if (amountNeeded <= itemCopy.amountScanned) {
          itemCopy.done = true;
        }

        return { ...itemCopy, cells: cellCopy };
      });
      if (!hadArticleMatch && !wasAmountReached) {
        setErrorState(ERROR_MESSAGES.productNotInListError);
      } else if (wasAmountReached && !wasIncremented) {
        setErrorState(ERROR_MESSAGES.productAmountFulfilledError);
      }
      setLastScannedEan(codeRef.current.value);
      codeRef.current.value = "";
      codeRef.current.focus();
      return updatedItems;
    });

    setShowModal(false);
  };

  const setErrorState = (errorMsg: string) => {
    setErrMsg(errorMsg);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handlePreScan();
    }
  };

  const checkScansDone = () => {
    if (shipmentPosition == null || undefined) {
      return;
    } else if (shipmentPosition.length > 0) {
      console.log("scan", shipmentPosition);
      let unfinshedPositions = shipmentPosition.filter(
        (position: any) => position.amountNeeded !== position.amountScanned
      );
      if (unfinshedPositions.length === 0) {
        console.log("Done!");
        setScanningDone(true);
      }
    }
  };

  const refreshPage = () => {
    navigate(0);
  };

  const handleModalClose = () => {
    setShowModal(!showModal);
    codeRef.current.value = "";
    codeRef.current.focus();
  };

  const combineClassNames = (
    ...classes: (string | undefined | null)[]
  ): string => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    checkScansDone();
  }, [shipmentPosition]);

  useEffect(() => {
    setShipmentPosition(processed);
  }, [processed]);

  return (
    <>
      <div className="flex justify-center items-center">
        {showModal && (
          <ScanMultipleModal
            product={multipleProduct}
            handleClose={handleModalClose}
            handleSubmit={handleScan}
            maxScanAmount={maxScanAmount}
          />
        )}
      </div>
      <div className="flex gap-4 flex-col items-center">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Barcode Scannen</span>
          </label>
          <label className="input-group">
            <input
              id="barcode-input"
              autoFocus
              inputMode="none"
              type="text"
              placeholder="Barcode"
              className="input input-bordered w-[40vw]"
              ref={codeRef}
              onKeyDown={handleKeyDown}
            />
            <button id="scan-btn" className="btn" onClick={handlePreScan}>
              Scan
            </button>
          </label>
        </div>
        {error && <ErrorBar message={errMsg} />}
      </div>
      <table id="product-table" className="table-fixed w-[100vw] mt-2">
        {!scanningDone && (
          <thead className="bg-gray-50 border-b-2 border-gray-200">
            <tr>
              {shipmentPosition &&
                shipmentPosition[0]?.cells?.map((item: any) => (
                  <td key={item.columnName} className={item.columnStyles}>
                    {item.columnName}
                  </td>
                ))}
            </tr>
          </thead>
        )}
        <tbody>
          {shipmentPosition
            ?.filter((item: any) => item.done !== true)
            .map((row: any, index: any) => (
              <tr key={`${row.id}_${index}`} className={"bg-slate-200"}>
                {row?.cells?.map((element: any) => (
                  <td
                    key={row.id + element.columnName + index}
                    className={combineClassNames(
                      element.rowStyles,
                      row?.ean == lastScannedEan &&
                        row?.amountNeeded > row?.amountScanned &&
                        row?.amountScanned > 0
                        ? "bg-yellow-400"
                        : ""
                    )}
                  >
                    {element.value}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <div className="flex flex-col items-center">
        {scanningDone && (
          <>
          <SuccessBar message={"Order is done!"}/>
          <button
            id="btn-logout"
            type="button"
            onClick={refreshPage}
            className="my-4 inline-block px-6 py-2 border-2 border-gray-800 text-gray-800 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            Start Next Job
          </button>
          </>
        )}
      </div>
    </>
  );
}

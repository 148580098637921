export const INFO_MESSAGES: Record<string, string> = {
    validationSuccess: "DHL Adressenvalidierung war erfolgreich.",
    disputeAndRedirectInfo: "Klärfall wird automatisch angelegt und Sie werden auf dem nächsten Auftrag weitergeleitet.",
    disputeCreationInfo: "Klärfall wird automatisch angelegt, in Bearbeitung...",
    disputeSuccess: "Klärfall wurde erfolgreich angelegt.",
    reprintSuccessInfo: "Auftrag ist an Drucker verschickt!",
    processingPickProducts: "Pick: Alle Produkte wurden gescannt. Der Job wird verarbeitet.",
    processedPickProducts: "Pick: Der Job wurde erfolgreich verarbeitet.",
    processingPackProducts: "Pack: Alle Produkte wurden gescannt. Der Job wird verarbeitet.",
    processedPackProducts: "Pack: Der Job wurde erfolgreich verarbeitet.",
    packProcessFinished: "Job erledigt.",
    extraLabelPrinted: "Zusätzliches Label wurde erfolgreich gedruckt.",

    ranpakFallbackLabelSuccessfullyPrinted: "Label successfully printed",
  };

import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBar } from "../components/ErrorBar";
import { InfoBar } from "../components/InfoBar";
import { userLogout } from "../../shared/network/lib/logout";
import { urlForVersion } from "../../shared/utils/common";
import { getPrinters } from "../../shared/network/lib/getPrintersForUser";
import VerifyPickerModal from "../components/VerifyPickerModal";
import { CONFIGURATIONS as C } from "../../shared/utils/configurations";

export function StartPage() {
  const navigate = useNavigate();
  const { userId, userInfo }: any = useOutletContext();
  const [isAdmin, setIsAdmin] = useState(false);
  const [userProperlyConfigured, setUserProperlyConfigured] = useState(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [err, setErr] = useState<Boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [info, setInfo] = useState<Boolean>(false);
  const [hideStart, setHideStart] = useState<Boolean>(false);
  const [verifyPickerModalOpen, setVerfiyPickerModalOpen] =
    useState<boolean>(false);
  const [verifyFastLaneModalOpen, setVerifyFastLaneModalOpen] =
    useState<boolean>(false);

  const pickingUrl = urlForVersion("/picking");

  const humanize = (str: string) => {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  const handleLogout = async () => {
    try {
      await userLogout().then((response) => {
        if (response.status === 200) {
          navigate("/login");
        }
      });
    } catch (error) {
      console.log("Error");
    }
  };

  const isExportLane = (lane_name: string) => {
    try{
      return lane_name.toLowerCase().includes("export");
    }
    catch (error) {
      return false;
    }  
  }

  const isFastLane = (lane_name: string) => {
    try{
      return lane_name.toLowerCase().includes("_fl") || lane_name.toLowerCase().includes(" FL");
    }
    catch (error) {
      return false
  }}

  const verifyUserSettings = async (userInfo: { [key: string]: any }) => {
    let printnode_label_printer_id;
    let printnode_printer_id;
    let data;
    try {
      data = await fetchPrintersData();
      // Save the data here
      printnode_label_printer_id = data?.printnode_label_printer_id;
      printnode_printer_id = data?.printnode_printer_id;
      console.log("Printers data:", data);
      if (isExportLane(userInfo.lane_name) ) {openModal()}
      else if (isFastLane(userInfo.lane_name)) {openFastLaneModal()}
    } catch (error) {
      // Handle the error if needed
      console.error("Error fetching printers:", error);
    }

    // Use the data outside the try block
    if (data) {
      console.log("Using data:", data);
      console.log("Printnode label printer ID:", printnode_label_printer_id);
      console.log("Printnode printer ID:", printnode_printer_id);
    }
    console.log("user info", userInfo);
    userInfo["printnode_label_printer_id"] = printnode_label_printer_id;
    userInfo["printnode_printer_id"] = printnode_printer_id;

    let criticalMissing = [];
    let missing = [];
    if (isExportLane(userInfo.lane_name)) {
      printnode_printer_id = userInfo.printnode_printer_id;
      if (printnode_printer_id === null || printnode_printer_id === "" || printnode_printer_id === 0) {
        criticalMissing.push(humanize("printnode_printer_id"));
      }
    }
    for (const [key, value] of Object.entries(userInfo)) {
      if (value === null || value === "" || value === 0) {
        if (key === "label_printer" || key === "printnode_label_printer_id") {
          criticalMissing.push(humanize(key));
        } else {
          missing.push(humanize(key));
        }
      }
    }
    if (criticalMissing.length === 0) {
      setUserProperlyConfigured(true);
    } else {
      setHideStart(true);
      setErr(true);
      setErrMsg(`Fehlend:\n${criticalMissing.join(" & ")}`);
    }
    if (missing.length > 0) {
      setInfo(true);
      setInfoMsg(`Fehlend:\n${missing.join(" & ")}`);
    }
  };

  const openModal = () => {
    setVerfiyPickerModalOpen(true);
  };

  const closeModal = () => {
    setVerfiyPickerModalOpen(false);
  };

  const openFastLaneModal = () => {
    setVerifyFastLaneModalOpen(true);
  };

  const closeFastLaneModal = () => {
    setVerifyFastLaneModalOpen(false);
  }

  const handleAdminCheck = () => {
    const scopes = userInfo?.scopes || [];

    if (scopes.includes("admin")) {
      setIsAdmin(true);
      return;
    } else {
      return;
    }
  };

  async function fetchPrintersData() {
    try {
      const response = await getPrinters();
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching printers:", error);
      throw error;
    }
  }

  console.log(userId);
  useEffect(() => {
    verifyUserSettings(userInfo);
    handleAdminCheck();
  }, [userInfo]);

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl font-bold mt-6">Startseite</h1>
      <div className="divider"></div>
      <div className="flex flex-col gap-2 items-center">
        <h2 className="text-2xl font-bold">Wilkommen!</h2>
        <div>
          <p className="text-xl inline">Benutzer: </p>
          <p className="inline font-bold">{userInfo?.pre_name}</p>
        </div>
        <div>
          <p className="text-xl inline">Lane: </p>
          <p className="inline font-bold">{userInfo?.lane_name}</p>
        </div>
      </div>
      <VerifyPickerModal
        title="Export Lane Kennwort Scannen"
        isOpen={verifyPickerModalOpen}
        truePassword={C.EXPORT_LANE_PW}
        onClose={closeModal}
      />

      <VerifyPickerModal
        title="Fast Lane Kennwort Scannen"
        isOpen={verifyFastLaneModalOpen}
        truePassword={C.FAST_LANE_PW}
        onClose={closeFastLaneModal}
      />

      <div className="divider"></div>
      {err && <ErrorBar message={errMsg} />}
      {info && <InfoBar message={infoMsg} />}

      {userProperlyConfigured && !hideStart && (
        <Link to={pickingUrl}>
          <button
            id="go-to-pick-btn"
            type="button"
            className="mt-10 inline-block px-12 py-4 border-2 border-4 border-green-500 text-green-500 font-medium text-md leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            Neuer Auftrag Starten
          </button>
        </Link>
      )}

      {isAdmin && (
        <Link to="/admin">
          <button
            id="go-to-pick-btn"
            type="button"
            className="mt-10 inline-block px-12 py-3 border-2 border-4 border-gray-500 text-gray-500 font-medium text-md leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            Admin Panel
          </button>
        </Link>
      )}

      <button
        id="btn-logout"
        type="button"
        onClick={handleLogout}
        className="my-24 inline-block px-6 py-2 border-2 border-gray-800 text-gray-800 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
      >
        Ausloggen
      </button>
    </div>
  );
  }

import React from "react";

interface SwitchData {
    switch_title : string;
    switch_text_on: string;
    switch_text_off: string;
    handleToggle: () => void;
    isToggled: boolean;
}

export const Switch= ({ switch_title, switch_text_on, switch_text_off, handleToggle, isToggled }: SwitchData) => {
    
        return <>
        <div className="divider"></div>
        <div className="flex gap-1 flex-col">
          <h2 className="text-2xl font-bold text-center">
            {switch_title}
          </h2>
          <div className="flex justify-center my-6">
            <label className="flex cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  className="toggle toggle-success"
                  checked={isToggled}
                  onChange={handleToggle}
                />
              </div>
              <div className="ml-3 font-medium">
                {isToggled ? switch_text_on : switch_text_off}
              </div>
            </label>
          </div>
        </div>
        <div className="divider"></div>
      </>
}
interface ContainerIdRequirements {
  minValue: number;
  maxValue: number;
  minStringLength: number;
}

const requirements: ContainerIdRequirements = {
  minValue: 1000000,
  maxValue: 20000000,
  minStringLength: 6,
};

const findContainerIdsForPayload = (obj: any): any[] => {
    let result: Array<string | number> = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (key === "container_id" || key === "containerId") {
          result.push(value);
        }
  
        if (typeof value === "object" && value !== null) {
          result = result.concat(findContainerIdsForPayload(value));
        } else if (Array.isArray(value)) {
          value.forEach((element: any) => {
            result = result.concat(findContainerIdsForPayload(element));
          });
        }
      }
    }
    return result;
  };

const checkContainerIdRequirements = (containerId: string): boolean => {
  const meetsMinValueRequirement = (containerId: string): boolean => {
    const parsedContainerId = parseInt(containerId, 10);
    return (
      !isNaN(parsedContainerId) && parsedContainerId >= requirements.minValue
    );
  };

  const meetsMaxValueRequirement = (containerId: string): boolean => {
    const parsedContainerId = parseInt(containerId, 10);
    return (
      !isNaN(parsedContainerId) && parsedContainerId <= requirements.maxValue
    );
  };

  const meetsMinStringLengthRequirement = (containerId: string): boolean => {
    const containerString = `${containerId}`
    return containerString.length >= requirements.minStringLength;
  };

  return (
    meetsMinValueRequirement(containerId) &&
    meetsMaxValueRequirement(containerId) &&
    meetsMinStringLengthRequirement(containerId)
  );
};

export const isDynamicContainerPayload = (payload: any): any => {
  const containerIds = findContainerIdsForPayload(payload);
  return containerListContainsDynamicContainers(containerIds);
};

const containerListContainsDynamicContainers = (
  containerList: any[]
): boolean => {
  for (const containerId of containerList) {
    if (checkContainerIdRequirements(containerId)) {
      // The containerId meets the requirements for a 'dynamic' container
      return true;
    }
  }
  // None of the containerIds meet the requirements
  return false;
};

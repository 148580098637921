import React, { useRef } from "react";

interface KakiContainerTableProps {
  containers: string[];
}

const KakiContainerTable: React.FC<KakiContainerTableProps> = ({
  containers,
}) => {
  const containerScrollRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {containers.length > 0 && (
        <div
          className="w-full overflow-y-auto max-h-[150px]"
          ref={containerScrollRef}
        >
          <table className="w-full mt-1 bg-white table-zebra overflow-scroll">
            <thead className="font-bold bg-gray-100">
              <tr>
                <th>#</th>
                <th>Container ID</th>
              </tr>
            </thead>
            <tbody>
              {containers.map((container, index) => (
                <tr key={String(index) + "_row"}>
                  <th>{index + 1}</th>
                  <th>{container}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default KakiContainerTable;

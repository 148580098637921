import React, { useEffect, useState } from "react";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InnerHeader } from "../components/InnerHeader";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { getPickingContainer } from "../../shared/network/lib/picking";
import { formatShippingPositionFromAPI } from "./ScanProductsPage";
import { ProcessedData } from "../../shared/types/data";
import { ProductTable } from "../components/ProductTable";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { resetContainer } from "../../shared/network/lib/resetContainer";
import { InfoBar } from "../../v1/components/InfoBar";
import { isMultiPicking, isMultiPickingRanpak, isRgbWarehouse, urlForVersion } from "../../shared/utils/common";

export const ResetContainerPage = () => {
  const [searchParams] = useSearchParams();
  const containerId = searchParams.get("containerid");
  const [errMsg, setErrMsg] = useState("");
  const [orderId, setOrderId] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [shipmentPosition, setShipmentPosition] = useState<ProcessedData[]>([]);
  const { toggleSpinner } = useSpinner();
  const navigate = useNavigate();

  const fetchContainerData = () => {
    toggleSpinner({ showSpinner: true });
    getPickingContainer(containerId as string)
      .then((response: any) => {
        const doCellTranslation = false
        if (response.data) {
          // Define a translation object
          const translation: Record<string, string> = {
            produkt: "Product",
            lagerort: "Location",
            soll: "Target",
            ist: "Current",
          };

          let shipment_position = formatShippingPositionFromAPI(
            response.data.shipment_position
          );

          if (
            doCellTranslation &&
            shipment_position &&
            shipment_position[0] &&
            shipment_position[0].cells
          ) {
            for (let i = 0; i < shipment_position[0].cells.length; i++) {
              let cell = shipment_position[0].cells[i];
              let headerKey = cell.columnHeader.toLowerCase();

              if (translation.hasOwnProperty(headerKey)) {
                cell.columnHeader = translation[headerKey];
              } else {
                console.log(`Translation not found for key: ${headerKey}`);
              }
            }
          } else {
            console.log(
              "shipment_position or its properties are missing or undefined."
            );
          }

          setShipmentPosition(shipment_position);
          setOrderId(response.data.shipment_head.auftrag);
          setDeliveryNumber(response.data.shipment_head.lieferschein);
          toggleSpinner({ showSpinner: false });
        }
      })
      .catch((error: any) => {
        setError(true);
        setErrMsg(error?.response?.data?.message);
        toggleSpinner({ showSpinner: false });
      });
  };

  const handleResetContainer = () => {
    toggleSpinner({ showSpinner: true });
    resetContainer(containerId).then((response) => {
      if (response.status === 200) {
        setInfo(true);
        setInfoMsg(response?.data?.message);
        toggleSpinner({ showSpinner: false });
        setTimeout(() => {
          setInfo(false);
          if (isRgbWarehouse() && isMultiPicking()) {
            navigate(urlForVersion("/mop/container_selection"));
          }
          else if (!isRgbWarehouse() && isMultiPicking() || !isRgbWarehouse() && isMultiPickingRanpak()) {
            navigate(urlForVersion("/mop/kaki/container_selection"));
          } 
          else {
            navigate(urlForVersion("/scan"));
          }
        }, 3000);
      }
    });
  };

  useEffect(() => {
    fetchContainerData();
  }, []);

  return (
    <div className="bg-app min-h-screen p-4 flex flex-col justify-between">
      <div className="mt-4 w-full flex flex-col">
        <InnerHeader
          title={COMMON_MESSAGES.resetContainerPageTitle}
          showBackButton={true}
        />
        <div className="mt-12 w-full flex flex-col gap-4 items-center">
          <div className="mt-4 text-center">
            <p>Container: {containerId}</p>
            <p>
              Order: <b>{orderId}</b>
            </p>
            <p>LS: {deliveryNumber}</p>
          </div>
          <ProductTable shipmentPosition={shipmentPosition} />

          {error && <ErrorBar message={errMsg} />}
          {info && <InfoBar message={infoMsg}></InfoBar>}

          <PrimaryButton
            type="button"
            label={COMMON_MESSAGES.resetContainer}
            onClick={handleResetContainer}
          />
        </div>
      </div>
    </div>
  );
};

import axiosPrivate from "../privateApiClient";

export function saveDispute(params: any){
    return axiosPrivate.post('/api/v1/picking/versand_dispute', null, {params: params});
}

export function saveDisputeBatch(body: any, params: any){
    return axiosPrivate.post('/api/v1/picking/versand_dispute_batch', body, {params: params});
}

export function getDisputes(){
    return axiosPrivate.get('/api/v1/picking/dispute_types', );
}


import { getUserRole } from "../../utils/common";
import axiosPrivate from "../privateApiClient";

type PickingContainerLoadParams = {
    container_id: string;
    picker_id: number;
    versand_id: number;
}

type PickingContainerUnloadParams = {
    container_id: string;
    packer_id: number;
    versand_id: number;
}

type PickingContainerLoadResponse = {
    id: number;
    versand_id: number;
    container_id: string;
}

type PickingBatchContainer = {
    container_id: string;
    versand_id: number;
  };
  
  type PickingBatchContainerLoadParams = {
    containers: PickingBatchContainer[];
    picker_id: number;
  };

export function getPickingDetails(query:any){
    return axiosPrivate.get('/api/v1/picking', {params: query});
}

export function getPickingContainer(containerId: string){
    const dynamic = ['30', '31', '32'].includes(String(getUserRole()));
    return axiosPrivate.get<PickingContainerLoadResponse>(`/api/v1/picking/container/${containerId}`, {params: {'dynamic': dynamic}});
}

export function postPickingContainerLoad(data: PickingContainerLoadParams){
    return axiosPrivate.post<PickingContainerLoadResponse>('/api/v1/picking/container/load', data);
}


export function postBatchPickingContainerLoad(body: PickingBatchContainerLoadParams) {
    return axiosPrivate.post('/api/v1/picking/container/load_batch', body, {params : {}});
  }

  export function postDynamicBatchPickingContainerLoad(body: PickingBatchContainerLoadParams) {
    return axiosPrivate.post('/api/v1/picking/container/load_batch_dynamic', body, {params : {}});
  }

export function postPickingContainerUnload(data: PickingContainerUnloadParams){
    const dynamic = ['30', '31', '32'].includes(String(getUserRole()));
    return axiosPrivate.patch<PickingContainerLoadResponse>('/api/v1/picking/container/unload', data, {params : {dynamic: dynamic}});
}

export function getContainerStatus(containerId: string){
    const dynamic = getUserRole() == 30;

    return axiosPrivate.get<PickingContainerLoadResponse>(`/api/v1/picking/container/${containerId}/status`, {params: {'dynamic_container': dynamic}});
}

export function getAveragePerMonth(){
    return axiosPrivate.get(`/api/v1/picking/average_picking_in_month`);
}
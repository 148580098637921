import { isCypressTest } from '../utils/common'
import { COMMON_MESSAGES } from '../utils/commonMessages';

type TableConfigProps = {
  column: 'product_name' | 'storage_location' | 'quantity' | 'ist' | 'ean',
  columnHeader: string,
  rowStyles: string,
  columnStyles: string
}

const tableConfig: TableConfigProps[] = [
  {
    column: "product_name",
    columnHeader: COMMON_MESSAGES.columnProductName,
    rowStyles:
      "font-semibold text-xxs text-left border-gray-400 border-b-2 border-r-2 break-all",
    columnStyles: `text-xxs text-left w-[30vw] border-gray-500 border-y-2 break-all`,
  },
  {
    column: "storage_location",
    columnHeader: COMMON_MESSAGES.columnStorageLocation,
    rowStyles:
      "text-xs text-center font-bold border border-gray-400 border-b-2 border-r-2 break-all",
    columnStyles: `text-xxs text-center w-[35vw] border-gray-500 border-y-2 break-all`,
  },
  {
    column: "quantity",
    columnHeader: COMMON_MESSAGES.columnQuantity,
    rowStyles:
      "text-xs text-center border border-gray-400 border-x-2 border-b-2 break-all",
    columnStyles: `text-xxs text-center w-[12vw] border-gray-500 border-y-2 break-all`,
  },
  {
    column: "ist",
    columnHeader: COMMON_MESSAGES.columnIst,
    rowStyles:
      "text-xs text-center border border-gray-400 border-b-2 break-all",
    columnStyles: `text-xxs text-center w-[12vw] border-gray-500 border-y-2 break-all`,
  },
];

if (isCypressTest()){
  tableConfig.push(
    {
      column: "ean",
      columnHeader: COMMON_MESSAGES.columnEan,
      rowStyles:
        "text-xs text-center border border-gray-400 border-b-2 break-all",
      columnStyles: `text-xxs text-center w-[12vw] border-gray-500 border-y-2 break-all`,
    },
  )
}

export default tableConfig;


import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InfoBar } from "../../v1/components/InfoBar";
import { VALIDATION_MESSAGES } from "../utils/validationMessages";
import { useState } from "react";
import { resetDisputeByType, resetDisputes } from "../network/lib/resetDisputes";
import { COMMON_MESSAGES } from '../utils/commonMessages'
import { Selector } from '../../v1/components/Selector'
import { getDisputes } from '../network/lib/disputes'
import { PrimaryButton } from './PrimaryButton'

export const DisputeReset = () => {
  const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [disputes, setDisputes] = useState([]);

  const fetchDisputes = () => {
    let data = getDisputes()
      .then(
        (response: any) => {
          setDisputes(response?.data.map((item: { index: string; dispute_type: string; }) => {
            return ({value: item.dispute_type, label: item.dispute_type})
          }))
        })
      .catch((error: any) => {
        console.log(error);
      });
    return data;
  };

  useEffect(() => {
    fetchDisputes()
  }, []);

  const handleResetDisputes = async () => {
    let result = await resetDisputes()
    setInfo(true)
    console.log(result)
    setInfoMsg(result?.data?.message)
  }

  const handleResetDisputeByType = async (dispute: string) => {
    let result = await resetDisputeByType(dispute)
    setInfo(true)
    console.log(result)
    setInfoMsg(result?.data?.message)
  }

  return (
    <div>
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-bold">Klärfälle Zurücksetzen</h2>
        <Formik
          initialValues={{
            container_id: "",
          }}
          validationSchema={Yup.object({
            container_id: Yup.string().required(
              VALIDATION_MESSAGES.inputNotFilled
            ),
          })}
          onSubmit={async (data, { resetForm }) => {
            console.log(data)
            resetForm();
          }}
        >
          {({ status }) => (
            <Form id="login-form">
              <div className="flex flex-col items-center px-2">
                <div className="flex flex-col w-full">
                  <div className="flex gap-2">
                    <div>
                      <ErrorMessage
                        className="text-red-700"
                        name="container_id"
                        component="div"
                      />
                    </div>
                    <label
                      htmlFor="my-modal-3"
                      className="btn btn-primary btn-xl mt-6"
                    >
                      App Basierte Klärfälle Zurücksetzen
                    </label>
                  </div>
                  <div className="flex gap-2">
                    {status && <div className="text-green-700">{status}</div>}
                  </div>
                </div>

              </div>
            </Form>
          )}
        </Formik>

        <div className='flex flex-row'>
          <Formik
            initialValues={{dispute: ''}}
            validationSchema={Yup.object({
              dispute: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
            })}
            onSubmit={async (data) => {
              try {
                await handleResetDisputeByType(data.dispute)
              } catch (error: any) {
                console.error('Error: => ', error)
              }
            }}
          >
            <Form id='dispute-form' >
              <div className="flex flex-row w-full items-end justify-end gap-2">
                <div className="flex flex-col mt-4 items-start w-full">
                  <label className="label label-text mt-3" htmlFor="dispute"> {COMMON_MESSAGES.dispute} </label>
                  <Selector options={disputes} name="dispute"  placeholder={COMMON_MESSAGES.selectDispute} />
                  <ErrorMessage className="text-red-700" name="dispute" component='div'/>
                </div>

                <PrimaryButton
                  type='submit'
                  className="rounded-lg w-full my-4 h-12 p-4"
                  label={COMMON_MESSAGES.save}
                />
              </div>
            </Form>
          </Formik>
        </div>

        <div className="flex gap-2">
          {info && <InfoBar message={infoMsg}></InfoBar>}
        </div>
        
        <div className="flex items-center justify-center mt-4">
          <input type="checkbox" id="my-modal-3" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-3"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold">Folgende App Basierte Klärfälle Zurücksetzen:</h3>
              <h2>Drucker druckt nicht</h2>
              <h2>Adresse ungültig</h2>

              <div className="flex gap-2 justify-between mt-4">
                <label htmlFor="my-modal-3" className="btn btn-md btn-error">
                  Abbrechen
                </label>

                <label
                  htmlFor="my-modal-3"
                  className="btn btn-md btn-success"
                  onClick={() => handleResetDisputes()}
                >
                  Ausführen
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

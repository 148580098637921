import * as React from "react";
import * as ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./shared/context/AuthProvider";
import ErrorBoundary from "./v1/components/ErrorBoundary";
import { SpinnerContextProvider } from "./shared/context/SpinnerProvider";
import { GlobalSpinner } from "./shared/components/Spinner";
import { SessionContextProvider } from "./shared/context/SessionContext";

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <SpinnerContextProvider>
        <AuthProvider>
          <SessionContextProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
            <GlobalSpinner />
          </SessionContextProvider>
        </AuthProvider>
      </SpinnerContextProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);

import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { COMMON_MESSAGES } from "../../../../shared/utils/commonMessages";
import { SearchBar } from "../../../components/SearchBar";
import {
  isPick,
  isRgbWarehouse,
  urlForVersion,
} from "../../../../shared/utils/common";
import { InnerHeader } from "../../../components/InnerHeader";
import { getContainerStatus } from "../../../../shared/network/lib/picking";
import { useSpinner } from "../../../../shared/context/SpinnerProvider";
import { ErrorBar } from "../../../../v1/components/ErrorBar";
import { FormikState } from "formik";
import { PrimaryButton } from "../../../../shared/components/PrimaryButton";
import { getBatchPicking } from "../../../../shared/network/lib/batchPicking";
import retrieveMopScans from "../../../utils/retrieveMOPScans";
import { getKpis } from "../../../../shared/network/lib/getKpis";
import { MopConfig } from "../../../../shared/config/mopConfig";

export const ContainerSelectionPage = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { toggleSpinner } = useSpinner();
  const [showOrderInContainer, setShowOrderInContainer] = useState(false);
  const [containerId, setcontainerId] = useState("");
  const [selectedContainers, setSelectedContainers] = useState<string[]>([]);
  const [pickingKpis, setPickingKpis] = useState({
    amount: 0,
    minutes_worked: 0,
    minutes_per_order: 0,
  });
  const [kpisRetrieved, setKpisRetrieved] = useState(false);
  const { userInfo }: any = useOutletContext();
  const [showContinuewithLastJobButton, setShowContinuewithLastJobButton] =
    useState(false);
  
  const handleSearch = async (
    value: string,
    resetValue: (
      nextState?: Partial<FormikState<{ packagecode: string }>> | undefined
    ) => void
  ) => {
    let url;
    setShowOrderInContainer(false);
    setErrMsg("");
    const param = `?barcode=${value}`;
    url = isPick() ? `/picking${param}` : `/check-view${param}`;
    if (selectedContainers.includes(value)) {
      setErrMsg(`Container ${value} ist schon in der Liste enthalten.`);
      return;
    }
    if (selectedContainers.length >= MopConfig.maxContainerNumber) {
      setErrMsg(
        `Die Höchstzahl von ${MopConfig.maxContainerNumber} Containern kann nicht überschritten werden.`
      );
      return;
    }
    try {
      const response = await getContainerStatus(value);
      if (response.status === 200) {
        setSelectedContainers((prevState) => {
          // Check if the item is not already in the array
          if (!prevState.includes(value)) {
            return [...prevState, value];
          }
          return prevState;
        });
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        setErr(true);
        setErrMsg(error?.response?.data?.message);
        toggleSpinner({ showSpinner: false });
      } else if (error.response.status === 422) {
        setErr(true);
        setErrMsg(error?.response?.data?.message);

        setShowOrderInContainer(true);
        setcontainerId(value);

        toggleSpinner({ showSpinner: false });
      } else if (error.response.status === 404) {
        navigate(urlForVersion(url));
      }
    }

    resetValue({ values: { packagecode: "" } });
  };

  const handleRemove = (index: number) => {
    setSelectedContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers.splice(index, 1);
      return updatedContainers;
    });
  };

  const handleConfirm = async () => {
    console.log(selectedContainers);
    const body = JSON.stringify({
      ids: selectedContainers,
    });
    console.log(body);
    try {
      toggleSpinner({ showSpinner: true });
      let response = await getBatchPicking(body);
      if (response.status === 200) {
        setErr(false);
        console.log(response?.data);
        if (response?.data?.data?.length === 0) {
          console.log("No data!");
          toggleSpinner({ showSpinner: false });
          return;
        }
        toggleSpinner({ showSpinner: false });
        navigate(urlForVersion("/mop/picking"), {
          state: { fullPayload: response?.data?.data },
        });
      }
    } catch (error: any) {
      console.log(error);
      console.log(error?.response?.status);
    }
  };

  const handleGetPickingKpis = async () => {
    try {
      const isPick = true
      let response = await getKpis(isPick);
      if (response.status === 200) {
        console.log(response);
        setPickingKpis(response?.data);
        setKpisRetrieved(true);
        toggleSpinner({ showSpinner: false });
      }
    } catch (error: any) {
      console.log(error);
      console.log(error?.response?.status);
    }
  };

  const handleContinueWithPrevious = async () => {
    try {
      navigate(urlForVersion("/mop/picking"), {
        state: { fullPayload: {} },
      });
    } catch (error: any) {
      console.log(error);
      console.log(error?.response?.status);
    }
  };

  const navigateToContainerView = async () => {
    navigate(urlForVersion(`/reset-container?containerid=${containerId}`));
  };

  const isFullEmptyPayloadObject = (obj: any) => {
    if (typeof obj === "undefined") {
      return false;
    }

    return (
      typeof obj === "object" && // Check if it's an object
      obj !== null && // Ensure it's not null
      Object.keys(obj).length === 1 && // Ensure it has only one key
      obj.hasOwnProperty("fullPayload") && // Check if it has the 'fullPayload' key
      typeof obj.fullPayload === "object" && // Check if the value of 'fullPayload' is an object
      obj.fullPayload !== null && // Ensure the value of 'fullPayload' is not null
      Object.keys(obj.fullPayload).length === 0 // Check if the 'fullPayload' object is empty
    );
  };

  useEffect(() => {
    if (isRgbWarehouse()) {
      handleGetPickingKpis();
    }
  }, []);

  useEffect(() => {
    const storage = retrieveMopScans();
    if (storage.hasOwnProperty("storedMopScans")) {
      // 'storedMopScans' attribute exists in the storage object

      // Check if 'storedMopScans' is an object and not null
      if (
        typeof storage.storedMopScans === "object" &&
        storage.storedMopScans !== null
      ) {
        if (
          Object.keys(storage.storedMopScans).length != 0 &&
          !isFullEmptyPayloadObject(storage?.fullPayload)
        ) {
          // 'storedMopScans' is an empty object
          let storageUserId = 0;
          try {
            storageUserId = storage.fullPayload.fullPayload[0].user?.user_id;
          } catch (error) {
            console.log(error);
          }
          console.log(storageUserId == userInfo?.user_id);
          console.log(!storage?.isDone);
          if (storageUserId == userInfo?.user_id && !storage?.isDone) {
            setShowContinuewithLastJobButton(true);
          }
        }
      }
    }
  }, [userInfo]);

  return (
    <div className="bg-app min-h-screen">
      <div className="max-w-screen-md min-h-screen mx-auto p-4 flex flex-col justify-between">
        <div className="mt-4 w-full flex flex-col items-center">
          <h1 className="text-3xl text-center">Multi-Order Picking</h1>
          <h1 className="text-3xl text-center">Scanne Containers</h1>
          <InnerHeader title="" showBackButton={true} urlBackButton="/start" />
          <div className="mt-8 w-full ml-2 flex flex-col">
            {kpisRetrieved && Object.keys(pickingKpis).length > 0 && (
              <>
                <div className="flex justify-center gap-1">
                  <p>Aufträge gepickt heute:</p>
                  <p className="font-bold">{pickingKpis?.amount}</p>
                </div>
                <div className="flex justify-center gap-1">
                  <p>Minuten pro Auftrag:</p>
                  <p className="font-bold">{pickingKpis?.minutes_per_order}</p>
                </div>
              </>
            )}
          </div>
          <SearchBar onClick={handleSearch} />
          <div onClick={() => setErrMsg("")}>
            {err && errMsg && <ErrorBar message={errMsg} />}
          </div>
          <div className="mt-6 w-full text-center">
            {showOrderInContainer && (
              <PrimaryButton
                type="button"
                label={COMMON_MESSAGES.viewOrderInContainer}
                onClick={navigateToContainerView}
              />
            )}
          </div>
          <table className="w-full mt-1 bg-white table-zebra">
            <thead className="font-bold bg-gray-100">
              <tr>
                <th>#</th>
                <th>Container ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {selectedContainers.map((container, index) => (
                <tr key={String(index) + "_row"}>
                  <th>{index + 1}</th>
                  <th>{container}</th>
                  <th className="flex justify-center items-center py-1">
                    <button
                      id={String(index)}
                      className="btn btn-sm btn-circle btn-error btn-outline"
                      onClick={() => handleRemove(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedContainers.length > 0 && (
            <div className="w-52 mt-5">
              <PrimaryButton
                type="button"
                label="PICKING STARTEN"
                onClick={handleConfirm}
              />
            </div>
          )}
          {!selectedContainers.length && showContinuewithLastJobButton && (
            <div className="w-80 mt-6">
              <PrimaryButton
                type="button"
                label="MIT DEM VORHERIGEN AUFTRAG FORTFAHREN"
                onClick={handleContinueWithPrevious}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { InfoBar } from "../../v1/components/InfoBar";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { VALIDATION_MESSAGES } from "../utils/validationMessages";
import { createManifest } from "../network/lib/createManifest";
import { useState } from "react";
import { useSpinner } from "../context/SpinnerProvider";

export const ManifestCreation = () => {
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [info, setInfo] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const { toggleSpinner } = useSpinner();

  const handleCreateManifest = async (country: string) => {
    console.log('Manifested')
    console.log(country)
    setIsButtonDisabled(true)
    try {
      setInfoMsg("");
      toggleSpinner({ showSpinner: true });
      let response = await createManifest(country);

      if (response.status === 201) {
        console.log(response)
        setInfo(true);
        setInfoMsg(`Manifest created for ${country}!`)
        toggleSpinner({ showSpinner: false });
      }

      setTimeout(() => {
        setInfo(false);
      }, 5000);
    } catch (error: any) {
      let msg = error?.response?.data?.message
      console.log(msg)
      setErr(true);
      setErrMsg(`Could not create Manifest. \n${msg}`);
      toggleSpinner({ showSpinner: false });
      setTimeout(() => {
        setInfo(false);
      }, 5000);
    }
  };

  const countries = [
    { code: "CH", name: "Schweiz" },
    { code: "GB", name: "Vereinigtes Königreich" },
    // Add more countries here...
  ];

  return (
    <div>
      <div className="flex flex-col items-center py-4">
        <h2 className="text-2xl font-bold">7Senders Manifest Erstellen</h2>
        <Formik
          initialValues={{
            country: "",
          }}
          validationSchema={Yup.object({
            country: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
          })}
          onSubmit={async (data, { resetForm }) => {
            console.log(data)
            handleCreateManifest(data.country)
            resetForm();
          }}
        >
          {({ status }) => (
            <Form id="login-form">
              <div className="flex flex-col items-center px-2">
                <div className="flex flex-col w-full max-w-xs">
                  <div className="flex gap-6 items-end">
                    <div>
                    <label className="label label-text" htmlFor="container_id">
                      Country
                    </label>
                    <Field as="select" name="country" className="input input-bordered">
                      <option value="">Land Auswählen</option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name} ({country.code})
                        </option>
                      ))}
                    </Field>
                    </div>
                    <button
                      type="submit"
                      id="btn-login"
                      className="btn btn-primary w-28 mt-6"
                      disabled={isButtonDisabled}
                    >
                    Manifest Erstellen
                    </button>
                  </div>
                  <div className="flex gap-2">
                    {status && <div className="text-green-700">{status}</div>}
                    {info && <InfoBar message={infoMsg}></InfoBar>}
                    {err && <ErrorBar message={errMsg}></ErrorBar>}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

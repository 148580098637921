import axiosPrivate from "../privateApiClient";

export function startShipping(
  body: any,
  check_open: boolean = false,
  is_d2c: boolean = true,
  is_additional_label: boolean = false
) {
  const url = "api/v1/shipping";

  return axiosPrivate.post(url, body, {
    params: { check_open, is_d2c, is_additional_label },
  });
}

export function generateAndStoreZplLabelForDynamicContainer(
  container_id: string
) {
  const url = "api/v1/shipping/generate_and_store_zpl_label_for_dynamic_container";
  return axiosPrivate.post(url, null, { params: { container_id } });
}

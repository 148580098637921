export const COMMON_MESSAGES: Record<string, string> = {
    pick: 'Pick',
    pack: 'Pack',
    startNewJob: 'START NEW JOB',
    finishJob: 'AUFTRAG ABSCHLIESSEN',
    scan: 'SCAN',
    scanPickPageTitle: 'Scanne Container',
    scanMultiPickTitle: 'Scanne Container für MOP',
    scanB2BPickPageTitle: 'Scanne Wagen',
    scanPickPageTitleB2B: 'Scanne Wagen',
    scanBarcode: 'Scanne Barcode',
    scanContainerBarcode: 'Scan Container-Barcode', 
    scanProductBarcode: 'Scanne Produkt-Barcode',
    clarificationBtn: 'KLÄRFALL',
    pickingPage: 'PICK',
    columnProductName: 'Produkt',
    columnStorageLocation: 'Lagerort',
    columnQuantity: 'Soll',
    columnIst: 'Ist',
    columnEan: 'Ean',
    clarificationPageTitle: 'Klärfall',
    stockMissing: 'Lein Bestand',
    otherIssues: 'Anderer Grund',
    back: 'ZURÜCK',
    scanPackPageTitle: 'Paket scannen',
    packingPage: 'PACK',
    logout: 'LOGOUT',
    done: 'FERTIG',
    confirmation: 'Bestätigung',
    confirmationB2B: 'B2B - Label Druck',
    rePrintLabel: 'LABEL ERNEUT DRUCKEN',
    rePrintLabelWithWarning: 'LABEL TROTZ WARNUNG ERNEUT DRUCKEN',
    save: 'SPEICHERN',
    dispute: 'Klärfall',
    selectDispute: 'Klärgrund auswählen',
    disputeGoToCaptain: 'Bitte gehen Sie zum Schichtleiter für die Freigabe der Erstellung des Klärfalles.',
    showCompleteOrder: 'Kompletter Auftrag Anzeigen',
    completeOrder: 'Kompletter Auftrag',
    viewOrderInContainer: 'Bestellung im container anzeigen',
    showContainer: 'SHOW CONTAINER',
    resetContainerPageTitle: 'Reset Container',
    resetContainer: 'RESET CONTAINER',
    scanByTracking: 'Scanne Trackingnummer/Container',
    qaCheckPageTitle: 'Qualitätskontrolle',
    containerView: 'Containeransicht',
    trackingCodeView: 'Tracking Code Ansicht',
    jobDone: 'Prüfen und Auftrag abschliessen',
    printLabel: 'LABEL DRUCKEN',
    finishPack: 'FERTIGSTELLEN',
    nextStep: 'Weiter',
    resetAllContainers: 'Alle Container zurücksetzen',
    shipmentsPerMonth:'Durchschnitt diesen Monats',
    containerNotMatch:'Container-ID stimmt nicht überein',
    printExtraLabel: 'ZUSÄTZLICHES LABEL DRUCKEN',
    finalContainerScanTitle: 'Scannen Sie den Container noch einmal um den Auftrag abzuschließen.',
    finalContainerScanTitleB2B: 'Scannen Sie den Wagen noch einmal um den Auftrag abzuschließen.',
    continuePreviousJob: 'CONTINUE PREVIOUS JOB',
    noMoreProductsLeft: 'There are no products or containers left to process. Please empty/remove all of them and start over.',
    packRanpak: 'Pack - Ranpak',
    printRanpakLabelManually: 'PRINT LABEL'
}

export const isDirectPrint = () => {
    switch (window.location.hostname) {
      case "rgb-endscan.testing.haute-cuisine.io":
        return false;
      case "rgb-endscan.haute-cuisine.io":
        return false;
      case "kaki-endscan.haute-cuisine.io":
        return false;
      default:
        return false;
    }
  };
import _ from 'lodash';
import { Product } from "../../types/MOP/kaki/types";

const findCommonAttribute = (arr: any[], preferredAttribute: string): string => {
  // Check if 'productName' attribute exists in all elements
  if (arr.every((element) => preferredAttribute in element)) {
    return preferredAttribute; // Return 'productName' if it exists in all elements
  }

  // Get the keys of the first element
  const keys = Object.keys(arr[0]);

  // Check if all elements have the same keys
  if (
    arr.every((element) =>
      Object.keys(element).every((key) => keys.includes(key))
    )
  ) {
    return keys[0]; // Return the first common attribute name
  }

  return ""; // No common attribute found
}

const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  let arr1_copy = _.cloneDeep(arr1)
  let arr2_copy = _.cloneDeep(arr2)
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  const commonAttribute = findCommonAttribute(
    [...arr1, ...arr2],
    "productName"
  );

  // If a common attribute was found, sort by it
  if (commonAttribute) {
    arr1_copy.sort((a, b) => {
      if (a[commonAttribute] < b[commonAttribute]) return -1;
      if (a[commonAttribute] > b[commonAttribute]) return 1;
      return 0;
    });

    arr2_copy.sort((a, b) => {
      if (a[commonAttribute] < b[commonAttribute]) return -1;
      if (a[commonAttribute] > b[commonAttribute]) return 1;
      return 0;
    });

    // Convert the sorted arrays to JSON strings
    const sortedArr1 = JSON.stringify(arr1_copy);
    const sortedArr2 = JSON.stringify(arr2_copy);

    // Compare the sorted strings
    return sortedArr1 === sortedArr2;
  }

  return false; // No common attribute found
}

const compareByLocation = (a: Product, b: Product): number => {
  if (b.storageLocation > a.storageLocation) {
    return 1;
  }

  if (b.storageLocation < a.storageLocation) {
    return -1;
  }

  return 0;
};

const compareByLastTwoNumbers = (a: Product, b: Product): number => {
  const regex = /^[A-Z]?-?\d+-(\d+)-(\d+)/;

  const matchesA = a.storageLocation.match(regex);
  const matchesB = b.storageLocation.match(regex);

  if (!matchesA || !matchesB) {
    return 0;
  }

  const lastTwoNumbersA = +(matchesA?.[1] + matchesA?.[2]);
  const lastTwoNumbersB = +(matchesB?.[1] + matchesB?.[2]);

  if (lastTwoNumbersB > lastTwoNumbersA) {
    return 1;
  }

  if (lastTwoNumbersB < lastTwoNumbersA) {
    return -1;
  }

  return 0;
};

const isOtherStorageLocationNumber = (storageLocation: string): boolean => {
  return (
    !storageLocation.startsWith("M") &&
    !/^\d/.test(storageLocation) &&
    !/^K-\d+-\d+-\d+/.test(storageLocation) &&
    !storageLocation.startsWith("F")
  );
};

const mapTransformedDataToProductCategories = (
  products: Product[]
): Map<string, Product[]> => {
  const mappedCategories = new Map();

  mappedCategories.set(
    "M",
    products
      .filter((product) => product.storageLocation.startsWith("M"))
      .sort(compareByLocation)
  );
  mappedCategories.set(
    "Numbers",
    products
      .filter((product) => /^\d/.test(product.storageLocation))
      .sort(compareByLocation)
  );
  mappedCategories.set(
    "K",
    products
      .filter(
        (product) =>
          product.storageLocation.startsWith("K") &&
          !product.storageLocation.endsWith("SONSTIGE")
      )
      .sort(compareByLocation)
  );
  mappedCategories.set(
    "F",
    products
      .filter((product) => product.storageLocation.startsWith("F"))
      .sort(compareByLocation)
  );
  mappedCategories.set(
    "Others",
    products
      .filter((product) =>
        isOtherStorageLocationNumber(product.storageLocation)
      )
      .sort(compareByLocation)
  );

  return mappedCategories;
};

const optimizeProductPickingRoute = (products: Product[]): Product[] => {
  const productCategories = mapTransformedDataToProductCategories(products);
  const firstNumberRegEx = /^[A-Z]?-?(\d+)-\d+-\d+/;

  const groups: Map<string, Map<number, Product[]>> = new Map();

  productCategories.forEach((products, key) => {
    if (key === "Others") {
      const othersCategoryMap = new Map();

      othersCategoryMap.set(1, products);
      groups.set(key, othersCategoryMap);

      return;
    }

    // create pairs with first number in storage location number (even number with direct next odd number)
    const pairs: Map<number, Product[]> = new Map();
    products.forEach((product) => {
      const match = product.storageLocation.match(firstNumberRegEx);

      if (!match?.[1]) {
        return;
      }

      const firstNumber = +match[1];
      const isOdd = +firstNumber % 2 !== 0;
      const pairKey = isOdd ? firstNumber + 1 : firstNumber;

      if (!pairs.has(pairKey)) {
        pairs.set(pairKey, []);
      }

      const exisitingPairContent = pairs.get(pairKey) as Product[];
      pairs.set(pairKey, [...exisitingPairContent, product]);
    });

    // sort pairs by second and third shelf number
    pairs.forEach((pairProducts, _) => {
      pairProducts.sort(compareByLastTwoNumbers);
    });

    groups.set(key, pairs);
  });

  let result: Product[] = [];
  groups.forEach((group, _) => {
    group.forEach((pair, _) => {
      result = [...result, ...pair];
    });
  });


  if (areArraysEqual(products, result)) {
    return result;
  } else {
    console.log('SORTING ERROR')
    return products;
  }
};

export { optimizeProductPickingRoute };

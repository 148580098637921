import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InfoBar } from "../../v1/components/InfoBar";
import { VALIDATION_MESSAGES } from "../utils/validationMessages";
import {
  resetAllContainers,
  resetContainer,
} from "../network/lib/resetContainer";
import { useState } from "react";
import { useSpinner } from "../context/SpinnerProvider";
import { COMMON_MESSAGES } from "../utils/commonMessages";
import { isRgbWarehouse } from "../utils/common";

export const ContainerReset = () => {
  const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [allowAllreset, setAllowAllReset] = useState(false);
  const { toggleSpinner } = useSpinner();

  const handleResetAllContainers = async () => {
    try {
      setInfoMsg("");
      toggleSpinner({ showSpinner: true });
      let response = await resetAllContainers();

      if (response.status === 200) {
        setInfo(true);
        setInfoMsg(response.data.message);
        toggleSpinner({ showSpinner: false });
      }

      setTimeout(() => {
        setInfo(false);
      }, 5000);
    } catch (error: any) {
      setInfo(true);
      setInfoMsg(error?.response?.data?.message);
      toggleSpinner({ showSpinner: false });
      setTimeout(() => {
        setInfo(false);
      }, 5000);
    }
  };

  useEffect(() => {
    if(isRgbWarehouse()) {setAllowAllReset(false)}
    else {
      setAllowAllReset(true)
    }
  }, []);

  return (
    <div>
      <div className="flex flex-col items-center py-4">
        <h2 className="text-2xl font-bold">Container Resetten</h2>
        <Formik
          initialValues={{
            container_id: "",
          }}
          validationSchema={Yup.object({
            container_id: Yup.string().required(
              VALIDATION_MESSAGES.inputNotFilled
            ),
          })}
          onSubmit={async (data, { resetForm }) => {
            try {
              await resetContainer(data.container_id).then((response) => {
                if (response.status === 200) {
                  console.log(response?.data);
                  setInfo(true);
                  setInfoMsg(response?.data?.message);
                  setTimeout(() => {
                    setInfo(false);
                  }, 3000);
                  resetForm();
                }
              });
            } catch (error: any) {
              console.log(error);
              if (!error?.response) {
                setInfo(true);
                setInfoMsg(
                  `Container Reset Failed ${error?.response?.data?.message}`
                );
                setTimeout(() => {
                  setInfo(false);
                }, 3000);
              } else if (error.response?.status === 400) {
                setInfo(true);
                setInfoMsg(
                  `Container Reset Failed ${error?.response?.data?.message}`
                );
                setTimeout(() => {
                  setInfo(false);
                }, 3000);
              } else if (error.response?.status === 401) {
                setInfo(true);
                setInfoMsg(
                  `Container Reset Failed ${error?.response?.data?.message}`
                );
                setTimeout(() => {
                  setInfo(false);
                }, 3000);
              } else {
                setInfo(true);
                setInfoMsg(
                  `Container Reset Failed ${error?.response?.data?.message}`
                );
                setTimeout(() => {
                  setInfo(false);
                }, 3000);
              }
            }
          }}
        >
          {({ status }) => (
            <Form id="login-form">
              <div className="flex flex-col items-center px-2">
                <div className="flex flex-col w-full max-w-xs">
                  <div className="flex flex-col items-center gap-2 items-end">
                    <div>
                      <label
                        className="label label-text"
                        htmlFor="container_id"
                      >
                        Container ID
                      </label>
                      <Field
                        name="container_id"
                        type="text"
                        className="input input-bordered"
                        id="container_id"
                      />
                    </div>
                    <button
                      type="submit"
                      id="btn-login"
                      className="btn btn-primary w-28 mt-4"
                    >
                      Reset Container
                    </button>
                    {allowAllreset && (
                      <button
                        type="button"
                        id="btn-login"
                        onClick={handleResetAllContainers}
                        className="btn btn-warning btn-primary w-50 mt-8"
                      >
                        {COMMON_MESSAGES.resetAllContainers}
                      </button>
                    )}
                  </div>
                  <ErrorMessage
                    className="text-red-700"
                    name="container_id"
                    component="div"
                  />
                  <div className="flex gap-2">
                    {status && <div className="text-green-700">{status}</div>}
                    {info && <InfoBar message={infoMsg}></InfoBar>}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

import axiosPrivate from "../privateApiClient";
import { User } from '../../types/user'

export function userLogin(user: User, params: any) {
  return axiosPrivate.post("/api/v1/users/login", user, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    withCredentials: true,
    params: params
  });
}

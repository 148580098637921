const resetDisputeContainers = (): void => {
    try {
      const jsonData: string = JSON.stringify([]);
      localStorage.setItem("disputeContainer", jsonData);
  
    } catch (error) {
      // Error occurred while saving the data
      console.error("Error saving data to storedMopScans:", error);
    }
  };
  
  export default resetDisputeContainers;
import React, { Component, ErrorInfo, ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log the error to an error reporting service
    console.error(error, errorInfo);
  }

  handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const href = event.currentTarget.getAttribute("href");
    if (href) {
      window.location.href = href;
    }
  };

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col content-center">
          <div className="w-full flex justify-center">
            <p className="text-2xl font-bold mt-8 text-center">
              Diese Seite scheint nicht verfügbar zu sein....
            </p>
          </div>
          <div className="flex justify-center">
            <Link to="/login" onClick={this.handleLinkClick}>
              <button className="btn btn-outline mt-4">
                Zurück zur Startseite
              </button>
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { InnerHeader } from "../components/InnerHeader";
import { LogoutButton } from "../../shared/components/LogoutButton";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { isPick, urlForVersion, isB2B, isMultiPicking, isRgbWarehouse, isMultiPickingRanpak, isRanpakPack } from "../../shared/utils/common";
import { userLogout } from "../../shared/network/lib/logout";

const determineTitle = (): string => {
  if (isPick()) {
    if (isB2B()) {
      return COMMON_MESSAGES.pick + " - B2B";
    } else {
      return COMMON_MESSAGES.pick;
    }
  } else if (isMultiPicking()) {
    return "Multi-Order Picking";
  } else if (isMultiPickingRanpak()) {
    return "MOP - Ranpak";
  }

  if (isB2B()) {
    return COMMON_MESSAGES.pack + " - B2B";
  } else if (isRanpakPack()) {
    return COMMON_MESSAGES.packRanpak;
  }
  
  return COMMON_MESSAGES.pack;
}

export const StartPage = () => {
  const navigate = useNavigate();
  const { userInfo }: any = useOutletContext();

  const handleLogout = async () => {
    try {
      await userLogout().then((response) => {
        let url;
        if(isB2B()){url = '/b2b'}
        else {url = '/login'}
        if (response.status === 200) {
          navigate(url);
        }
      });
    } catch (error) {
      console.log("Error");
    }
  };
  console.log(userInfo)

  const handleStart = () => {
    if (isMultiPicking() || isMultiPickingRanpak()){
      const url = isRgbWarehouse() ? "/mop/container_selection" : "/mop/kaki/container_selection"
      navigate(urlForVersion(url))
    }
    else{
    navigate(urlForVersion("/scan"))
    }
  }

  return (
    <div className="bg-app min-h-screen py-8 flex flex-col justify-center items-center">
      <InnerHeader
        title={determineTitle()}
        showBackButton={false}
      />
      <div className="mt-14">
        <p>
          Nutzername: <span className="font-bold">{userInfo?.pre_name}</span>
        </p>
        {isPick() && (
          <p>
            Ort: <span className="font-bold">{userInfo?.lane_name}</span>
          </p>
        )}
      </div>
      <div className="grow flex items-center">
        <div className="w-52">
          <PrimaryButton
            type="button"
            label={COMMON_MESSAGES.startNewJob}
            onClick={handleStart}
          />
        </div>
      </div>

      <div className="w-36 mt-12">
        <LogoutButton onClick={handleLogout} />
      </div>
    </div>
  );
};

import { Switch } from '@headlessui/react';
import React from 'react';


interface WarningButtonProps {
  name: string;
  placeholder: string;
  onchange?: ()=> void;
}
export const WarningButton = ({ name, placeholder, onchange }: WarningButtonProps) => {

    return (
      <Switch
        name={name}
        onChange={onchange}
        className="relative inline-flex h-10 w-full max-w-xs items-center rounded-full bg-blue-200 py-4 px-0 click:opacity-90"
      >
        <span  className= 'inline-block h-10 w-10 transform rounded-full bg-blue-500 transition text-white p-2'>!</span>
          <span className="px-2">{placeholder}</span>

      </Switch>
    )
}

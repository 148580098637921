import React, { useState, useEffect, useRef } from "react";
import { userLogin } from "../../shared/network/lib/login";

import { ErrorBar } from './ErrorBar';
import { Selector } from './Selector';
import { getLanes } from '../../shared/network/lib/getLanes';
import { getVersion } from '../../shared/network/lib/getVersion';

import { laneDefault, defaultRanpakPickLane } from "../../shared/config/lanes";
import { rolesDefault } from "../../shared/config/roles";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { VALIDATION_MESSAGES } from "../../shared/utils/validationMessages";
import { setFrontEndLane, setGlobalRole, urlForVersion } from "../../shared/utils/common";
import { userLogout } from "../../shared/network/lib/logout";
import { isRgbWarehouse } from "../../shared/utils/common";
import { CONFIGURATIONS as C } from "../../shared/utils/configurations";

export const Login = () => {
  let MULTI_ORDER_LANE_NAMES = C.MULTI_ORDER_LANE_NAMES;
  const [errMsg, setErrMsg] = useState("");
  const [selectedRole, setSelectedRole] = useState({
    value: undefined,
    label: undefined,
  });
  const [error, setError] = useState(false);
  const [lanes, setLanes] = useState(
    laneDefault.map((item) => ({
      value: item.kommissionierung_id.toString(),
      label: item.lane_name,
    }))
  );

  const [version, setVersion] = useState({"version":"", "build_date":""})

  const [multi_order_lanes, setMultiOrderLanes] = useState(
    laneDefault.map((item) => ({
      value: item.kommissionierung_id.toString(),
      label: item.lane_name,
    }))
  );
  const ranpakRoleIDs= [C.ROLE.RANPAK_MULTI_ORDER_PICK.ID, 
                        C.ROLE.RANPAK_MULTI_ORDER_PACK.ID, 
                        C.ROLE.RANPAK_PRINT.ID];
  const rgbOmitLaneSelectionForRoles = [C.ROLE.MULTI_ORDER_PICK.ID,]
  const omitLaneSelectionForRoles = [C.ROLE.PACK.ID,
                                    C.ROLE.RANPAK_MULTI_ORDER_PICK.ID,
                                    C.ROLE.RANPAK_MULTI_ORDER_PACK.ID,
                                    C.ROLE.RANPAK_PRINT.ID];
  const roles = rolesDefault
  .map((item) => ({
    value: item.role_id.toString(),
    label: item.role_name,
  }))
  .filter((item) => {
    if (isRgbWarehouse()) {
      return !( ranpakRoleIDs.map((item) => item.toString()).includes(item.value) );
    } else {
      return true;
    }
  });
  const ref = useRef<any>();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await userLogout();
    } catch (error) {
      console.log("Error");
    }
  };

  const fetchVersion = () => {
    let data = getVersion()
    .then(
      (response: any) => {
        console.log(response);
        setVersion(response?.data)
      }
    )
    .catch(
      (error: any) => {
        console.log(error);
      }
    )
    return data;
  }

  // fetch lanes
  const fetchLanes = () => {
    let data = getLanes()
      .then((response: any) => {
        const filteredData = response?.data.filter(
          (item: { lane_name: string }) =>
            !(
              isRgbWarehouse() &&
              MULTI_ORDER_LANE_NAMES.includes(String(item.lane_name))
            )
        );
        setLanes(
          filteredData.map(
            (item: { kommissionierung_id: string; lane_name: string }) => {
              return {
                value: item.kommissionierung_id.toString(),
                label: item.lane_name,
              };
            }
          )
        );

        const multiOrderLanes = response?.data.filter(
          (item: { lane_name: string }) =>
            isRgbWarehouse() &&
            MULTI_ORDER_LANE_NAMES.includes(String(item.lane_name))
        );

        setMultiOrderLanes(
          multiOrderLanes.map(
            (item: { kommissionierung_id: string; lane_name: string }) => {
              return {
                value: item.kommissionierung_id.toString(),
                label: item.lane_name,
              };
            }
          )
        );
      })
      .catch((error: any) => {
        console.log(error);
      });
    return data;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }

    handleLogout()
    fetchLanes()
    fetchVersion()
  }, []);

  const convert_iso_timestamp_to_date = (iso_timestamp: string) => {
    let date = new Date(iso_timestamp);
    if (isNaN(date.getTime())) { return '2023-01-01'; }
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return year+'-' + month + '-'+ day;
  }
  // redeploy
  const packageJson = require("../../../package.json");

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        lane: undefined,
        role: undefined,
      }}
      validationSchema={Yup.object({
        username: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
        password: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
        role: Yup.number().required(VALIDATION_MESSAGES.roleNotSelected),
        lane: Yup.number().when("role", {
          is: (role: number) => (role === C.ROLE.PACK.ID || role === C.ROLE.RANPAK_MULTI_ORDER_PICK.ID || role === C.ROLE.RANPAK_MULTI_ORDER_PACK.ID || role === C.ROLE.RANPAK_PRINT.ID),
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required(VALIDATION_MESSAGES.laneNotSelected),
        }),
      })}
      onSubmit={async (data) => {
        try {
          console.log("ROLE", data.role);
          
          const isRanpakPickRole = data.role === C.ROLE.RANPAK_MULTI_ORDER_PICK.ID.toString();

          await userLogin(
            { username: data.username, password: data.password },
            {
              //TODO: fix default lane value send for PACK route
              lane_id: data.lane ?? (isRanpakPickRole ? defaultRanpakPickLane.kommissionierung_id : C.LANE.MORE_NONEU.kommissionierung_id),
              lane_name:
                lanes.find(({ value }) => value === data.lane)?.label ??
                (isRanpakPickRole ? defaultRanpakPickLane.lane_name : C.LANE.MORE_NONEU.lane_name),
            }
          ).then((response) => {
            if (response.status === 200) {
              setGlobalRole(data.role);
              setFrontEndLane(data.lane);
              let url;
              if (!isRgbWarehouse() && 
                (
                  data.role == C.ROLE.MULTI_ORDER_PICK.ID || 
                  data.role == C.ROLE.RANPAK_MULTI_ORDER_PICK.ID
                )
              ) {
                url = C.URL.MOP_KAKI_START;
              }
              else if (!isRgbWarehouse() && (data.role == C.ROLE.RANPAK_PRINT)) {url = C.URL.RANPAK_KAKI_MANUAL_LABEL_PRINT;}
              else (url = C.URL.START)
              if(data.lane == 5 && data.role == 3){
                navigate(urlForVersion(C.URL.START_DUMMY));
                return
              }
              navigate(urlForVersion(url));
            }
          });
        } catch (error: any) {
          setError(true);
          if (!error?.response) {
            setErrMsg("Keine Server-Antwort.");
          } else if (error.response?.status === 400) {
            setErrMsg("Missing Username or Password");
          } else if (error.response?.status === 401) {
            setErrMsg("Falsche Login-Daten.");
          } else {
            setErrMsg("Login Failed");
          }
        }
      }}
    >
      <Form id="login-form">
        <div className="flex flex-col items-center mt-2 px-2">
          <h1 className="text-3xl font-bold mt-3">Kommi-App</h1>
          <div className="flex flex-col w-full max-w-xs">
            <label className="label label-text" htmlFor="username">
              {" "}
              Username{" "}
            </label>
            <Field
              name="username"
              type="text"
              className="input input-bordered"
              id="username"
              inputMode={isRgbWarehouse() ? "text" : "none"}
            />
            <ErrorMessage
              className="text-red-700"
              name="username"
              component="div"
            />

            <label className="label label-text mt-3" htmlFor="password">
              {" "}
              Passwort{" "}
            </label>
            <Field
              name="password"
              type="password"
              className="input input-bordered"
              id="password"
              inputMode={isRgbWarehouse() ? "text" : "none"}
            />
            <ErrorMessage
              className="text-red-700"
              name="password"
              component="div"
            />

            <label className="label label-text mt-2" htmlFor="lane">
              {" "}
              Rolle{" "}
            </label>
            <Selector
              options={roles}
              name="role"
              placeholder="Bitte Rolle auswählen"
              setSelected={setSelectedRole}
            />
            <ErrorMessage
              className="text-red-700"
              name="role"
              component="div"
            />

            {omitLaneSelectionForRoles.map((r) => r.toString()).includes(selectedRole?.value) ||
            (rgbOmitLaneSelectionForRoles.map((r) => r.toString()).includes(selectedRole?.value) && isRgbWarehouse()) ? null : (
              <>
                <label className="label label-text" htmlFor="lane">
                  {" "}
                  Ort{" "}
                </label>
                <Selector
                  options={lanes}
                  name="lane"
                  placeholder="Bitte Ort auswählen"
                />
                <ErrorMessage
                  className="text-red-700"
                  name="lane"
                  component="div"
                />
              </>
            )}

            {isRgbWarehouse() && selectedRole?.value === C.ROLE.MULTI_ORDER_PICK.ID.toString() && (
              <>
                <label className="label label-text" htmlFor="lane">
                  {" "}
                  Ort{" "}
                </label>
                <Selector
                  options={multi_order_lanes}
                  name="lane"
                  placeholder="Bitte Ort auswählen"
                />
                <ErrorMessage
                  className="text-red-700"
                  name="lane"
                  component="div"
                />
              </>
            )}
            <button
              type="submit"
              id="btn-login"
              className="btn btn-primary w-full max-w-xs mt-3"
            >
              Einloggen
            </button>
            <button
              onClick={() => navigate(C.URL.B2b_LOGIN)}
              className="btn btn-outline w-full max-w-xs mt-6"
            >
              Optionen
            </button>

            {error && (
              <div className="flex justify-center">
                <ErrorBar message={errMsg} />
              </div>
            )}
          </div>
          <div className="my-4 text-center">
            <p className="text-[12px]">
              FE || v.{packageJson?.version} || {packageJson?.buildDate} || MOP
            </p>
          </div>
          <div className="my-4 text-center">
          <p className="text-[12px]">BE || {version?.version} || {convert_iso_timestamp_to_date(version?.build_date)} </p>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

import React from "react";
import { useNavigate } from 'react-router-dom';
import { classNames } from '../../shared/utils/classNames';
import { urlForVersion } from "../../shared/utils/common";

interface InnerHeaderProps {
  title: string
  className?: string
  showBackButton: boolean,
  urlBackButton?: string
}

export const InnerHeader = ({title, className = '', showBackButton, urlBackButton = ''}: InnerHeaderProps) => {
  const navigate = useNavigate();
  const backToPreviousPage = () => {
    if (urlBackButton) {
      navigate(urlForVersion(urlBackButton));
    } else {
      navigate(-1);
    }
  }
  return (
    <div className={classNames(className, "relative flex flex-row pt-4 items-center justify-center w-full")}>
      {showBackButton &&
          <div className="absolute left-0 flex items-center">
              <button
                  className="w-9"
                  onClick={backToPreviousPage}
              >
                  <svg
                      className="h-9 w-9 fill-current md:h-8 md:w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                  >
                      <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
                  </svg>
              </button>
          </div>
      }
      <h1 className='text-3xl'>{title}</h1>
    </div>
  );
};

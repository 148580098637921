import React from "react";

interface ModalProps{
    submit: any
  }

export const ReprintModal = ({ submit }: ModalProps) => {
    
  return (
    <div className="flex items-center justify-center mt-4">
      <label htmlFor="my-modal-1" className="btn text-white">
        Letzter Auftrag neu Drucken
      </label>

      <input type="checkbox" id="my-modal-1" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="my-modal-1"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Letzter Auftrag neu Drucken</h3>

          <div className="flex gap-2 justify-between mt-4">
            <label htmlFor="my-modal-1" className="btn btn-md btn-error">
              Abbrechen
            </label>

            <label
              htmlFor="my-modal-1"
              className="btn btn-md btn-success"
              onClick={submit}
            >
              Drucken
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ERROR_MESSAGES: Record<string, string> = {
  DisputeCreationError: "Klärfall konnte nicht angelegt werden, bitte melden beim Manager.",
  generalShippingError: "Es gab ein Problem beim Shipping.",
  dhlAdressError: "DHL API Adressfehler",
  processedLieferscheinError: "Der Auftrag hat einen bereits verarbeiteter Lieferschein",
  productDataError: "Der Auftrag hat Fehlende Stammdaten, der Klärfall wurde angelegt.",
  disputeTypeProcessedLieferschein: "Lieferschein bereits verarbeitet",
  disputeTypeShippingError: "Drucker druckt nicht",
  disputeTypeValidationError: "Adresse ungültig",
  noPickingDataAvailableError: "Die Kommissionierdaten konnten nicht vom Server abgerufen werden.",
  noPickingDataFoundError: "Es konnte keinen Auftrag abgerufen werden. Bitte prüfen Sie den Kommissionierlauf.",
  pickingPreValidationError: "Die Adressüberprüfung ist fehlgeschlagen. Der Klärfall wird automatisch angelegt.",
  reprintError: "Label konnte nicht neu gedruckt werden.",
  productAmountFulfilledError: "Das Produkt ist bereits auf die erforderliche Menge gescannt worden.",
  productNotInListError: "Das Produkt, das Sie gescannt haben, ist nicht in der Liste der zu liefernden Artikel enthalten.",
  needCode: "Need to put a code to scan a product",
  errorProcessingProducts: "An error occurred while processing the products",
  packingNotFound: "Der Code {code} wurde keiner Entnahme zugeordnet",
  shippingAlreadyProcessed: "WARNUNG: Der Auftrag ist bereits von einem anderen Nutzer verarbeitet worden.",
  shippingAlreadyProcessedNoPrint: "WARNUNG: Der Auftrag ist bereits von einem anderen Nutzer verarbeitet worden und wird daher nicht automatisch gedruckt.",
  shippingSafetyError: "ERROR: Dieser Auftrag scheint schon verarbeitet/doppelt im System anwesend zu sein. Bitte Prüfen sie den Lieferschein und Aufträg.",
  containerUnloadingError: "Der Container mit ID: {code} konnte im System nicht geleert werden.",
  qualityCodeNotMatch: "Zu diesem Wert konnte keine Container ID oder Liefernummmer gefunden werde",
  dhlOutageNotification: "DHL API ist nicht verfügbar - 7S ist als Fallback aktiv",

  ranpakFallbackLabelError: "Failed to print the label",
};

import axiosPrivate from "../privateApiClient";
import { ShipmentPositionDataResponse } from '../../types/picking'

type ContainerLoadResponse = {
    container_id: string;
    lieferschein: string;
    lieferschein_id: string;
    picker_id: string;
    picker_name: string;
    picked_at: string;
    packer_name: string;
    packed_at: string;
    versand_id: string;

    shipping_positions: ShipmentPositionDataResponse[];
}


export function checkContentByContainerId(containerId: string){
    return axiosPrivate.get<ContainerLoadResponse>(`api/v1/quality/container/${containerId}`);
}

export function checkContentByTrackingCode(trackingCode: string){
    return axiosPrivate.get<ContainerLoadResponse>(`api/v1/quality/tracking_code/${trackingCode}`);
}

import React from "react";


export const SizeTest = () => {
  

  return (
    <div>
      <div style={{height: 480 , width: 360, backgroundColor: "red"}}>, 

      </div>

      <div style={{height: 32 , width: 360, border: "solid blue", borderWidth: 4}}>

      </div>

    </div>
    
  );
};
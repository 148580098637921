import React, { useState, useEffect } from "react";
import { COMMON_MESSAGES } from "../../../../shared/utils/commonMessages";
import { InnerHeader } from "../../../components/MOP/kaki/InnerHeader";
import { useLocation } from "react-router-dom";
import { PrimaryButton } from "../../../../shared/components/PrimaryButton";
import { WarningButton } from "../../../../shared/components/WarningButton";
import { ProductInfo } from "../../../components/MOP/ProductInfo";
import { KakiMopProductTable } from "../../../components/MOP/kaki/KakiMopProductTable";
import { SmallPopupDialog } from "../../../components/SmallPopupDialog";
import { postBatchPickingContainerLoad, postDynamicBatchPickingContainerLoad } from "../../../../shared/network/lib/picking";
import { SuccessBar } from "../../../../v1/components/SuccessBar";
import { SearchBarCustomFocus } from "../../../components/SearchBarCustomFocus";
import { useSpinner } from "../../../../shared/context/SpinnerProvider";
import { ErrorBar } from "../../../../v1/components/ErrorBar";
import { saveDisputeBatch } from "../../../../shared/network/lib/disputes";
import { ERROR_MESSAGES } from "../../../../shared/utils/errorMessages";
import { urlForVersion } from "../../../../shared/utils/common";
import { useNavigate } from "react-router-dom";
import retrieveMopScans from "../../../utils/retrieveMOPScans";
import saveMopScans from "../../../utils/saveMOPScans";
import savePayload from "../../../utils/savePayload";
import { isEqual } from "lodash";
import resetDisputeContainers from "../../../utils/resetDisputeContainers";
import { MopConfig } from "../../../../shared/config/mopConfig";
import { KakiLocationModal } from "../../../components/MOP/kaki/KakiLocationModal";
import { disputeConfig } from "../../../../shared/config/disputeConfig";
import { PopupDialog } from "../../../components/PopupDialog";
import KakiContainerTable from "../../../components/MOP/kaki/KakiContainerTable";
import { optimizeProductPickingRoute } from "../../../services/kaki/StorageLocationService";
import { CompleteContainerOverlay } from "../../../components/MOP/kaki/CompleteContainerOverlay";
import { Product } from "../../../types/MOP/kaki/types";
import { InfoBar } from "../../../../v1/components/InfoBar";
import { isDynamicContainerPayload } from "../../../../shared/utils/isContainerPayloadDynamic";

export const KaKiMultiOrderPickingPage = () => {
  const navigate = useNavigate();
  const { toggleSpinner } = useSpinner();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [infoMsg, setInfoMsg] = useState("");
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showInfoBar, setShowInfoBar] = useState(false);
  const [showNextProductButton, setShowNextProductButton] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDipsuteButton, setShowDisputeButton] = useState(true);
  const [showDisputeDialog, setShowDisputeDialog] = useState(false);
  const [showRedirectButton, setShowRedirectButton] = useState(false);
  const [retryContainers, setRetryContainers] = useState<any>([]);
  const [showPreDisputeDialog, setShowPreDisputeDialog] = useState(false);
  const [showContainerCompleteOverlay, setShowContainerCompleteOverlay] =
    useState(false);
  const [showContainerRemovalDialog, setShowContainerRemovalDialog] =
    useState(false);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [currentContainerIndex, setCurrentContainerIndex] = useState(0);
  const [fullPayloadState, setFullPayloadState] = useState<any[]>([]);
  const [affectedContainers, setAffectedContainers] = useState<any[]>([]);
  const [showContainerRemovalConfirm, setShowContainerRemovalConfirm] =
    useState(false);
  const [transformedProducts, setTransformedProducts] = useState([
    {
      productName: "Sample Product",
      storageLocation: "Sample Location",
      productEan: "000000",
      containers: [
        {
          versand_id: 0,
          containerId: 99999,
          quantity_needed: 5,
          quantity_scanned: 0,
          done: false,
        },
      ],
    },
  ]);
  const [currentContainerId, setCurrentContainerId] = useState<any>(99999);
  const [currentProduct, setCurrentProduct] = useState({
    productName: "Sample Product",
    storageLocation: "Sample Location",
    productEan: "000000",
    containers: [
      {
        versand_id: 0,
        containerId: 99999,
        quantity_needed: 5,
        quantity_scanned: 0,
        done: false,
      },
    ],
  });
  const [scanningDone, setScanningDone] = useState(false);
  const [noProductsLeft, setNoProductsLeft] = useState(false);

  const logo = require("../../../../assets/dispute_icon.png");
  const removeContainerIcon = require("../../../../assets/remove_container.png");
  const checkmark = require("../../../../assets/checkmark.png");

  const { state } = useLocation();
  let { fullPayload = {} } = state || {};

  const handleNewTransform = () => {
    setShowDisputeButton(true);
    // get the old payload
    const storage = retrieveMopScans();
    let storagePayload = storage?.fullPayload?.fullPayload;
    let hasScan = storage?.hasScan;
    // compare the old payload with the new one
    let isSamePayload = isEqual(fullPayload, storagePayload);
    if (
      !storagePayload &&
      Object.keys(fullPayload).length === 0 &&
      fullPayload.constructor === Object
    ) {
      navigate(urlForVersion("/mop/kaki/container_selection"));
    }
    // if it's different we're saving it with hasScan False

    const continueLastJob =
      storagePayload &&
      Object.keys(fullPayload).length === 0 &&
      fullPayload.constructor === Object;

    if (!isSamePayload && !continueLastJob) {
      savePayload(fullPayload);
      resetDisputeContainers();
    }

    if ((isSamePayload && hasScan) || continueLastJob) {
      console.log("Setting fullpayload to storage");
      setFullPayloadState((prevPayload) => {
        console.log(prevPayload);
        console.log(storagePayload);
        return storagePayload;
      });
      let tranformedStorage = storage?.storedMopScans?.transformedProducts;
      let storedProductIndex = storage?.storedMopScans?.productIndex;
      let storedCurrentContainerId =
        storage?.storedMopScans?.currentContainerId;

      setTransformedProducts(tranformedStorage);
      setCurrentProduct(tranformedStorage[storedProductIndex]);
      setCurrentContainerId(storedCurrentContainerId);
      setCurrentProductIndex(storedProductIndex);
      const isNotDone: any = tranformedStorage[storedProductIndex]?.containers?.some((obj: any) => Object.values(obj).includes(false)) ?? false;
      if (!isNotDone) {
        handleStoredContainerDone(
          tranformedStorage,
          storedCurrentContainerId,
          storedProductIndex
        );
        setShowNextProductButton(true);
      }

      handleShowLocationModalWithFocus();
      return;
    }

    setFullPayloadState(fullPayload);

    let transformedData: Product[] = [];
    fullPayload.forEach((item: any) => {
      const { container_id, versand_id, shipment_position } = item;
      shipment_position.forEach((position: any) => {
        const { product_name, ean, quantity, storage_location } = position;

        const existingProductIndex = transformedData.findIndex(
          (product: any) => product.productName === product_name
        );
        const productAlreadyPresentForContainer = !!transformedData[
          existingProductIndex
        ]?.containers.find(
          (container: any) => container.containerId === container_id
        );
        if (existingProductIndex !== -1 && !productAlreadyPresentForContainer) {
          // Product already exists, add the container information
          transformedData[existingProductIndex].containers.push({
            containerId: container_id,
            versand_id,
            shipment_position: position,
            quantity_needed: quantity,
            quantity_scanned: 0,
            done: false,
          });
        } else if (
          existingProductIndex !== -1 &&
          productAlreadyPresentForContainer
        ) {
          const existingContainerIndex = transformedData[
            existingProductIndex
          ]?.containers.findIndex(
            (container: any) => container.containerId === container_id
          );
          transformedData[existingProductIndex].containers[
            existingContainerIndex
          ]["quantity_needed"] += quantity;
        } else {
          // Product doesn't exist, create a new entry
          const newProduct = {
            productName: product_name,
            productEan: ean,
            storageLocation: storage_location,
            containers: [
              {
                containerId: container_id,
                versand_id,
                shipment_position: position,
                quantity_needed: quantity,
                quantity_scanned: 0,
                done: false,
              },
            ],
          };
          transformedData.push(newProduct);
        }
      });
    });
    try {
      transformedData = optimizeProductPickingRoute(transformedData);
    } catch (error) {
      // Handle the error here
      console.error("An error occurred while sorting:", error);
    }

    // Deduplicate container IDs within each product
    transformedData.forEach((product: any) => {
      const uniqueContainerIds = new Set();
      product.containers = product.containers.filter((container: any) => {
        if (!uniqueContainerIds.has(container.containerId)) {
          uniqueContainerIds.add(container.containerId);
          return true;
        }
        return false;
      });
      setShowDialog(true);
    });

    setTransformedProducts(transformedData);
    setCurrentProduct(transformedData[0]);
    setCurrentContainerId(
      transformedData[currentProductIndex].containers[0].containerId
    );

    handleShowLocationModalWithFocus();
  };

  const handleShowLocationModalWithFocus = () => {
    setShowLocationModal(true);
    handleTryFocus();
  };

  const handleUpdateContainerIndex = () => {
    let containerIndex = transformedProducts[
      currentProductIndex
    ].containers.findIndex((obj) => obj.containerId === currentContainerId);
    setCurrentContainerIndex(containerIndex);
  };

  const handleChangeCurrentProduct = () => {
    setCurrentContainerIndex(0);
    if (currentProductIndex + 1 < transformedProducts.length) {
      setCurrentProduct(transformedProducts[currentProductIndex + 1]);
      setCurrentProductIndex((prevIndex) => {
        return prevIndex + 1;
      });
      setCurrentContainerId(
        transformedProducts[currentProductIndex + 1].containers[0].containerId
      );
      saveMopScans(
        transformedProducts,
        currentProductIndex + 1,
        transformedProducts[currentProductIndex + 1].containers[0].containerId
      );
    } else {
      setCurrentProduct(transformedProducts[0]);
      setCurrentProductIndex((prevIndex) => {
        return prevIndex - prevIndex;
      });
      setCurrentContainerId(transformedProducts[0].containers[0].containerId);
      saveMopScans(
        transformedProducts,
        0,
        transformedProducts[0].containers[0].containerId
      );
    }

    if (!scanningDone) {
      setShowDialog(true);
      setShowNextProductButton(false);
    }

    handleShowLocationModalWithFocus();
  };

  const handleScan = (value: string) => {
    let scanAmount = 1;
    console.log(scanAmount);
    if (value != currentProduct.productEan) {
      setErr(true);
      setErrMsg("Please scan the correct article");
      return false;
    }
    setErr(false);
    setErrMsg("");
    let containerIndex = transformedProducts[
      currentProductIndex
    ].containers.findIndex((obj) => obj.containerId === currentContainerId);
    if (
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_needed -
        transformedProducts[currentProductIndex].containers[containerIndex]
          .quantity_scanned <
      1
    ) {
      return false;
    }
    // update transformedProducts
    setTransformedProducts((prevProducts) => {
      let productCopy = [...prevProducts];
      let containerIndex = productCopy[
        currentProductIndex
      ].containers.findIndex((obj) => obj.containerId === currentContainerId);
      productCopy[currentProductIndex].containers[
        containerIndex
      ].quantity_scanned += 1;
      if (
        productCopy[currentProductIndex].containers[containerIndex]
          .quantity_scanned ===
        productCopy[currentProductIndex].containers[containerIndex]
          .quantity_needed
      ) {
        productCopy[currentProductIndex].containers[containerIndex].done = true;
      }
      saveMopScans(productCopy, currentProductIndex, currentContainerId);
      return productCopy;
    });

    if (
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_scanned ===
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_needed
    ) {
      setTimeout(() => {
        setShowContainerCompleteOverlay(true);
      }, 400);
    }
    return false;
  };

  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
    handleShouldShowContainerCompletion();
    handleTryFocus();
  };

  const handleShouldShowContainerCompletion = () => {
    let containerIndex = transformedProducts[
      currentProductIndex
    ].containers.findIndex((obj) => obj.containerId === currentContainerId);
    if (
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_scanned ===
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_needed
    ) {
      setTimeout(() => {
        setShowContainerCompleteOverlay(true);
      }, 100);
    }
  };

  const handleContainerDone = (containerId: any) => {
    console.log("EXECUTING CONTAINER DONE");
    // Check if there is another container within the product
    const remainingContainers = transformedProducts[
      currentProductIndex
    ].containers.filter(
      (item) => item.done === false && item.containerId !== containerId
    );
    if (remainingContainers.length > 0) {
      handleUpdateContainerIndex();
      setCurrentContainerId((prevId: any) => {
        console.log(prevId);
        saveMopScans(
          transformedProducts,
          currentProductIndex,
          remainingContainers[0].containerId
        );
        return remainingContainers[0].containerId;
      });
      setShowDialog(true);
    } else {
      if (checkContainersDone(transformedProducts).length === 0) {
        setScanningDone(true);
        handleBatchContainerLoad()
        return;
      }
      handleChangeCurrentProduct();
    }
  };

  const handleStoredContainerDone = (
    storedProducts: any,
    storedContainerId: any,
    storedProductIndex: any
  ) => {
    // Check if there is another container within the product
    const remainingContainers = storedProducts[
      storedProductIndex
    ].containers.filter(
      (item: any) =>
        item.done === false && item.containerId !== storedContainerId
    );
    if (remainingContainers.length > 0) {
      setCurrentContainerId((prevId: any) => {
        console.log(prevId);
        saveMopScans(
          storedProducts,
          storedProductIndex,
          remainingContainers[0].containerId
        );
        return remainingContainers[0].containerId;
      });
      setShowDialog(true);
    } else {
      if (checkContainersDone(storedProducts).length === 0) {
        setScanningDone(true);
        console.log('EXECUTING STORED CONTAINER DONE WITH')
        console.log(storedProducts)
        console.log(storedContainerId)
        console.log(storedProductIndex)
        handleBatchContainerLoad()
      }
      setShowNextProductButton(true);
    }
  };

  const handleBatchContainerLoad = async () => {
    const storage = retrieveMopScans();
    let disputeContainer = storage?.disputeContainer;
    toggleSpinner({ showSpinner: true });
    // setScanningDone(false);
    setShowNextProductButton(false);
    console.log('handleBatchContainerLoad with storage', storage)
    console.log('Full payload state', fullPayloadState)
    console.log('Full payload state user', fullPayloadState[0]?.user?.user_id)
    const userId = fullPayloadState[0]?.user?.user_id || 1
    let containerArray = fullPayloadState
      .map((originalObject: any) => ({
        container_id: originalObject.container_id,
        versand_id: originalObject.versand_id,
      }))
      .filter((item: any) => !disputeContainer.includes(item.container_id));

    if (retryContainers.length > 0) {
      containerArray = containerArray.filter((item) =>
        retryContainers.includes(item.container_id)
      );
    }

    let body = {
      picker_id: userId,
      containers: containerArray,
    };

    console.log("BODY FOR BATCH LOAD", body)
    try {
      let response = null;
      setShowInfoBar(false);
      setShowRedirectButton(false);
      if(isDynamicContainerPayload(retrieveMopScans())){response = await postDynamicBatchPickingContainerLoad(body)}
      else {response = await postBatchPickingContainerLoad(body);}

      if (response.status === 201) {
        toggleSpinner({ showSpinner: false });
        if (response?.data?.issues.length > 0) {
          setRetryContainers(response?.data?.issues);
          console.log("SETTING ISSUE CONTAINERS", response?.data?.issues);
          let formattedIssues = response.data.issues.map(
            (issue: any) => `#${issue}`
          );
          setShowInfoBar(true);
          setInfoMsg(
            `The following containers could not be completed due to a problem. ${formattedIssues.join(
              ", "
            )}. Please empty and set aside.  The other containers were successfully completed.`
          );
          setShowRedirectButton(true);
        } else {
          setShowDisputeButton(false)
          setShowSuccessBar(true);
          setSuccessMsg("Success, ready for packing!");
          setTimeout(function () {
            saveMopScans({}, currentProductIndex, currentContainerId, [], true);
            navigate(urlForVersion("/mop/kaki/container_selection"));
          }, 7000);
        }
      } else if (response.status != 201) {
        toggleSpinner({ showSpinner: false });
        setShowInfoBar(true);
        setInfoMsg(`Es gab ein Problem: ${response?.data}`);
        setShowRedirectButton(true);
      }
    } catch (e: any) {
      toggleSpinner({ showSpinner: false });
      setShowInfoBar(true);
      setInfoMsg(e);
    }
  };

  const checkContainersDone = (transformedProducts: any) => {
    const notDoneContainers = [];

    // Iterate over each item in transformedProducts
    for (let i = 0; i < transformedProducts.length; i++) {
      const item = transformedProducts[i];

      // Check if 'containers' array exists and it's not empty
      if (Array.isArray(item.containers) && item.containers.length > 0) {
        // Iterate over each 'containers' array
        for (let j = 0; j < item.containers.length; j++) {
          const container = item.containers[j];

          // Check if 'done' attribute exists and it's false
          if (container.done !== true) {
            // Save the container that is not done
            notDoneContainers.push({ index: i, containerId: container.id });
          }
        }
      } else {
        // Save the index and container id if containers array is missing or empty
        notDoneContainers.push({ index: i, containerId: undefined });
      }
    }

    // Return the array of containers that are not done
    return notDoneContainers;
  };

  const handleContinueAfterIssue = () => {
    saveMopScans({}, currentProductIndex, currentContainerId, [], true);
    navigate(urlForVersion("/mop/container_selection"));
  };

  function removeContainersFromProducts(
    products: any[],
    containerIds: any[]
  ): [any[], number[], number[]] {
    const originalIndexes = products.map((_, index) => index);
    const deletedIndexes: number[] = [];

    const updatedProducts = products
      .map((product, index) => {
        const updatedContainers = product.containers.filter(
          (container: any) => !containerIds.includes(container.containerId)
        );

        if (updatedContainers.length === 0) {
          deletedIndexes.push(index);
          return null; // Remove the product if it has no more containers
        }

        return { ...product, containers: updatedContainers };
      })
      .filter(Boolean); // Filter out null values (products with no containers)
    if (updatedProducts.length === 0) {
      setNoProductsLeft(true);
      setShowDialog(false);
      return [products, originalIndexes, []];
    } else {
      return [updatedProducts, originalIndexes, deletedIndexes];
    }
  }

  function determineNewIndex(
    allIndexes: number[],
    currentIndex: number,
    deletedIndexes: number[]
  ): number {
    const currentIndexDeleted = deletedIndexes.includes(currentIndex);
    const indexesRemovedBeforeCurrent = deletedIndexes.filter(
      (index) => index < currentIndex
    ).length;
    const indexesNotDeletedAndAboveCurrent = allIndexes.filter(
      (index) => !deletedIndexes.includes(index) && index > currentIndex
    );

    let newIndex = 0;

    if (currentIndexDeleted) {
      if (indexesNotDeletedAndAboveCurrent.length >= 1) {
        newIndex = currentIndex - indexesRemovedBeforeCurrent;
      }
      if (indexesNotDeletedAndAboveCurrent.length === 0) {
        newIndex = currentIndex - indexesRemovedBeforeCurrent - 1;
      }
    } else if (!currentIndexDeleted) {
      newIndex = currentIndex - indexesRemovedBeforeCurrent;
    }

    return newIndex;
  }

  const handleBatchDisputeSubmit = () => {
    const newDisputeContainers = currentProduct?.containers.map(
      (item) => item?.containerId
    );
    const disputeVersandIds = currentProduct?.containers.map(
      (item) => item?.versand_id
    );

    const storage = retrieveMopScans();
    console.log(storage);
    let disputeContainer = storage?.disputeContainer;
    const uniqueDisputeContainers = Array.from(
      new Set(disputeContainer.concat(newDisputeContainers))
    );
    saveDisputeBatch(disputeVersandIds, { dispute_type: "TEST_MOP_BATCH" })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setTransformedProducts((prevProducts: any) => {
            const [newProducts, originalIndexes, deletedIndexes] =
              removeContainersFromProducts(
                prevProducts,
                uniqueDisputeContainers
              );

            const newDeterminedIndex = determineNewIndex(
              originalIndexes,
              currentProductIndex,
              deletedIndexes
            );
            let newIndex = newDeterminedIndex;

            setCurrentProduct(newProducts[newIndex]);
            if (checkContainersDone(newProducts).length === 0) {
              setCurrentProductIndex(newIndex);
              setScanningDone(true);
              saveMopScans(
                newProducts,
                newIndex,
                newProducts[newIndex]?.containers[0]?.containerId,
                uniqueDisputeContainers
              );
              return newProducts;
            }
            const unfinishedContainerIndex = newProducts[
              newIndex
            ].containers.findIndex((item: any) => !item.done);
            if (unfinishedContainerIndex != -1) {
              setCurrentContainerId(
                newProducts[newIndex].containers[unfinishedContainerIndex]
                  .containerId
              );
              setCurrentProductIndex(newIndex);
              saveMopScans(
                newProducts,
                newIndex,
                newProducts[newIndex].containers[unfinishedContainerIndex]
                  .containerId,
                uniqueDisputeContainers
              );
            } else {
              // Check whether current product has unfinished containers
              setShowNextProductButton(true);
              setShowDialog(false);
              saveMopScans(
                newProducts,
                newIndex,
                newProducts[newIndex].containers[0].containerId,
                uniqueDisputeContainers
              );
            }
            return newProducts;
          });
          setShowDisputeDialog(false);
          setShowContainerRemovalDialog(true);
          setTimeout(function () {
            setShowContainerRemovalConfirm(true);
          }, 3500);
        } else if (response.status >= 400) {
          setErr(true);
          setErrMsg(ERROR_MESSAGES.DisputeCreationError);
        }
        toggleSpinner({ showSpinner: false });
      })
      .catch((error) => {
        setErrMsg(
          error?.data?.message?.error_message ||
            ERROR_MESSAGES.DisputeCreationError
        );
        toggleSpinner({ showSpinner: false });
      });
  };

  const handleBackAndRestart = () => {
    navigate(urlForVersion("/mop/kaki/start"));
    saveMopScans({}, currentProductIndex, currentContainerId, [], true);
  };

  const handleDisputeCodeScan = (value: string) => {
    console.log(value);
    if (value === disputeConfig.disputeCode) {
      setShowPreDisputeDialog(false);
      setShowDisputeDialog(true);
    }
    console.log(
      "Following Containers will be Affected",
      currentProduct?.containers.map((e) => e.containerId)
    );
    setAffectedContainers(currentProduct?.containers.map((e) => e.containerId));
  };

  const handleCheckContainerId = (value: any) => {
    if (value == currentContainerId) {
      setShowDialog((prevState) => {
        return !prevState;
      });
    }
  };

  const handleTryFocus = () => {
    const input = document.getElementById("barcodescan");
    input?.focus();
  };

  const handleFinishDisputeConfirmation = () => {
    setShowContainerRemovalDialog(false);
    setShowContainerRemovalConfirm(false);
    if (checkContainersDone(transformedProducts).length === 0) {
      setScanningDone(true);
      handleBatchContainerLoad()
    }
    if (!scanningDone) {
      handleShowLocationModalWithFocus();
    }
  };

  useEffect(() => {
    if (!MopConfig.initialContainerScanNeeded) {
      handleTryFocus();
    }
    if (showDialog === false) {
      const timer = setTimeout(() => {
        // Execute your function here
        handleTryFocus();
      }, 50); // 500 milliseconds = 0.5 seconds

      return () => {
        // Cleanup function to cancel the timer if the component unmounts
        clearTimeout(timer);
      };
    }
    return;
  }, [showDialog, currentProductIndex]);

  useEffect(() => {
    handleNewTransform();
  }, []);

  useEffect(() => {
    handleUpdateContainerIndex();
  }, [currentContainerId]);

  return (
    <>
      <PopupDialog
        showDialog={showContainerRemovalDialog}
        hideDialog={() => setShowContainerRemovalDialog(false)}
        title={`Remove Container(s)`}
      >
        <div className="flex flex-col justify-center items-center w-full my-2">
          <img className="max-w-[100px]" src={removeContainerIcon} alt="Logo" />
        </div>
        <div className="bg-red-400 flex items-center justify-center">
          <h1 className="text-white text-[24px] font-bold text-center">
            Remove Container(s)
          </h1>
        </div>
        <h1 className="text-center my-4">
          Please empty & remove the following Containers from the cart to
          continue.
        </h1>
        <KakiContainerTable containers={affectedContainers} />
        <div className="mt-4">
          {showContainerRemovalConfirm && (
            <button
              type="button"
              className="bg-primary-button text-white w-full py-2 rounded"
              onClick={() => handleFinishDisputeConfirmation()}
            >
              Continue
            </button>
          )}
          {!showContainerRemovalConfirm && (
            <button
              type="button"
              className="bg-gray-300 cursor-not-allowed text-white w-full py-2 rounded"
            >
              Continue
            </button>
          )}
        </div>
      </PopupDialog>

      <SmallPopupDialog
        showDialog={noProductsLeft}
        hideDialog={() => handleBackAndRestart()}
        title={`No products or containers left to process.`}
      >
        <div className="m-5 text-center flex flex-col gap-2">
          <h1 className="font-bold"></h1>
          <h1 className="font-bold">
            Please empty/remove all containers and start over.
          </h1>
        </div>
        <PrimaryButton
          type="button"
          label={"Zurück"}
          onClick={() => handleBackAndRestart()}
        />
      </SmallPopupDialog>

      <PopupDialog
        showDialog={noProductsLeft}
        hideDialog={() => handleBackAndRestart()}
        title={`No products or containers left to process.`}
      >
        <div className="m-5 text-center flex flex-col gap-2">
          <h1 className="font-bold"></h1>
          <h1 className="font-bold">
            Please empty/remove all containers and start over.
          </h1>
        </div>
        <PrimaryButton
          type="button"
          label={"Zurück"}
          onClick={() => handleBackAndRestart()}
        />
      </PopupDialog>

      <CompleteContainerOverlay
        showDialog={showContainerCompleteOverlay}
        setShowDialog={setShowContainerCompleteOverlay}
        currentContainerId={currentContainerId}
        handleScanCallback={() => {
          setShowDialog(false);
          handleContainerDone(currentContainerId);
          setTimeout(() => {
            handleTryFocus();
          }, 75);
        }}
      />

      {showLocationModal && (
        <KakiLocationModal
          storageLocation={currentProduct.storageLocation}
          onScanHandler={handleCloseLocationModal}
        />
      )}
      {!showLocationModal && (
        <div className="bg-app min-h-screen pt-[15px]">
          <div className="max-w-screen-md min-h-screen mx-auto px-5 flex flex-col items-center">
            <InnerHeader
              title={"Multi Order Picking"}
              showBackButton={false}
              urlBackButton="/mop/kaki/container_selection"
            />
            {scanningDone && (
              <div className="w-full px-7 flex flex-col justify-center items-center mb-6 mt-2">
              <div className="flex flex-col justify-center items-center w-full mt-2 mb-4">
                <img className="max-w-[150px]" src={checkmark} alt="checkmark" />
              </div>
                {showSuccessBar && <SuccessBar message={successMsg} />}
                {showInfoBar && <InfoBar message={infoMsg} />}
                {showRedirectButton && (
                  <div className="w-60 my-4 flex flex-col gap-4">
                    <PrimaryButton
                      type="button"
                      label={"Retry"}
                      onClick={handleBatchContainerLoad}
                    />

                    <PrimaryButton
                      type="button"
                      label={"Continue"}
                      onClick={() => handleContinueAfterIssue()}
                    />
                  </div>
                )}
                {err && <ErrorBar message={errMsg} />}
              </div>
            )}
            {!scanningDone && (
              <>
                <div className="h-[115px] w-full flex justify-end flex-col">
                  <ProductInfo product={currentProduct} />
                </div>

                <div className="flex flex-col justify-center items-center w-full mt-[30px]">
                  <SearchBarCustomFocus
                    fieldId="barcodescan"
                    focusOnIdWhenDone="barcodescan"
                    onClick={handleScan}
                    labelText={COMMON_MESSAGES.scanProductBarcode}
                    changeFocusWhenDone={false}
                    inputWidth="w-1/2"
                    buttonWidth="w-1/2"
                  />
                </div>
              </>
            )}
            <div className="w-full px-7 flex flex-col justify-center items-center gap-10">
              {/* {scanningDone && !showRedirectButton && (
                <div className="w-60 mt-12">
                  <PrimaryButton
                    type="button"
                    label={"Finish Job"}
                    onClick={handleBatchContainerLoad}
                  />
                </div>
              )} */}
              {showNextProductButton && <></>}
              {err && (
              <div className="mb-2">
                <ErrorBar message={errMsg} />
              </div>
            )}
            </div>


            {!scanningDone && (
              <>
                <div className="text-[16px]">
                  Container&nbsp;
                  <span className="font-bold">{currentContainerId}</span>
                </div>
                <div className="text-[16px] mb-[6px] font-bold">
                  Nr. {currentContainerIndex + 1} /{" "}
                  {currentProduct.containers.length}
                </div>

                <KakiMopProductTable
                  productData={currentProduct}
                  currentContainerId={currentContainerId}
                />
              </>
            )}

            {showDipsuteButton && (
              <div className="w-full px-7 flex flex-col justify-center items-center gap-10 mt-2">
                <div className="w-36">
                  <WarningButton
                    name="klarfall-btn"
                    placeholder={COMMON_MESSAGES.clarificationBtn}
                    onchange={() => setShowPreDisputeDialog(true)}
                  />
                </div>
              </div>
            )}
          </div>

          <PopupDialog
            showDialog={showDisputeDialog}
            hideDialog={() => setShowPreDisputeDialog(false)}
            title={`Klärfall für Produkt Anlegen`}
          >
            <div className="flex flex-col gap-6">
              <ProductInfo product={currentProduct} />
              <div>
                <h2 className="text-xl text-center">
                  Product is in Containers:
                </h2>
              </div>
              <KakiContainerTable containers={affectedContainers} />
              <PrimaryButton
                type="button"
                label={"Bestätigen"}
                onClick={() => handleBatchDisputeSubmit()}
              />
              <PrimaryButton
                type="button"
                label={"Zurück"}
                onClick={() => setShowDisputeDialog(false)}
              />
            </div>
          </PopupDialog>

          <PopupDialog
            showDialog={showPreDisputeDialog}
            hideDialog={() => setShowPreDisputeDialog(false)}
            title={"Klärfall QR-Code abscannen"}
          >
            <div className="absolute flex items-center">
              <button
                className="w-9"
                onClick={() => {
                  setShowPreDisputeDialog(false);
                }}
              >
                <svg
                  className="h-9 w-9 fill-current md:h-8 md:w-8"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
                </svg>
              </button>
            </div>
            <div className="flex flex-col justify-center items-center w-full my-10">
              <img className="max-w-[275px]" src={logo} alt="Logo" />
            </div>
            <div className="flex  mt-2 justify-center">
              <h1>{COMMON_MESSAGES.disputeGoToCaptain}</h1>
            </div>
            <SearchBarCustomFocus
              fieldId="disputescan"
              focusOnIdWhenDone="barcodescan"
              onClick={handleDisputeCodeScan}
              labelText={"Scan Klärfall QR-Code"}
              changeFocusWhenDone={false}
            />
          </PopupDialog>

          {MopConfig.initialContainerScanNeeded && (
            <SmallPopupDialog
              showDialog={showDialog}
              hideDialog={() => setShowDialog(false)}
              title={`Scanne Container ${currentContainerId}`}
            >
              <SearchBarCustomFocus
                onClick={handleCheckContainerId}
                fieldId="containerPopup"
                focusOnIdWhenDone="barcodescan"
                changeFocusWhenDone={true}
              />
            </SmallPopupDialog>
          )}
        </div>
      )}
    </>
  );
};

import React, { FC, useState, useEffect } from "react";
import { classNames } from "../utils/classNames";

interface PrimaryButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "submit" | "button";
  label: string;
  className?: string;
}

export const CountdownButton: FC<PrimaryButtonProps> = ({
  type = "submit",
  label,
  className = "rounded-md",
  ...props
}) => {
  const [isClickable, setIsClickable] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds < 2) {
        setIsClickable(true);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <button
        type={type}
        className={classNames(
          className,
          ` text-white w-full py-2 relative ${
            isClickable ? "bg-primary-button" : "bg-gray-200 text-gray-800 cursor-not-allowed"
          }`
        )}
        onClick={() => {
          if (!isClickable) return;
          setIsClickable(false);
        }}
        disabled={!isClickable}
        {...props}
      >
        <div className="relative">
          {seconds > 0 && (
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
              {seconds}
            </div>
          )}
          <p className="text-center">{label}</p>
        </div>
      </button>
    </>
  );
};

export const CONFIGURATIONS: Record<string, any> = {
    MULTI_ORDER_LANE_NAMES: ["RGB2", "RGB2 Export"],
    ROLE: { PICK: { NAME: "Pick", ID: 1 }, 
             PACK: { NAME: "Pack", ID: 2 }, 
             PICK_PACK: { NAME: "Pick & Pack", ID: 3 },
             B2B_1_PICK: { NAME: "B2B 1 - PICK", ID: 4 },
             B2B_1_PACK: { NAME: "B2B 1 - PACK", ID: 5 },
             B2B_2_PICK_PACK: { NAME: "B2B 2 - PICK & PACK", ID: 6 }, 
             MULTI_ORDER_PICK: { NAME: "Multi-Order: Pick", ID: 18 }, 
             RANPAK_MULTI_ORDER_PICK: { NAME: "Ranpak - Multi-Order: Pick", ID: 30 }, 
             RANPAK_MULTI_ORDER_PACK: { NAME: "Ranpak - Multi-Order: Pack", ID: 31 }, 
             RANPAK_PRINT: { NAME: "Ranpak - Print", ID: 32 }
            },
    LANE: { 
            MORE_EU: { kommissionierung_id: 1, lane_name: "more_eu" },
            MORE_NONEU: { kommissionierung_id: 2, lane_name: "more_noneu" },
            SYN_EU: { kommissionierung_id: 3, lane_name: "syn_eu" },
            SYN_NONEU: { kommissionierung_id: 4, lane_name: "syn_noneu" },
            GOT7_EU: { kommissionierung_id: 5, lane_name: "got7_eu" },
            GOT7_NONEU: { kommissionierung_id: 6, lane_name: "got7_noneu" },
            FTESN_EU: { kommissionierung_id: 7, lane_name: "ftesn_eu" },
            FTESN_NONEU: { kommissionierung_id: 8, lane_name: "ftesn_noneu" },
            FTESN_EXPRESS: { kommissionierung_id: 9, lane_name: "ftesn_express" },
            SONDERFALL_EU: { kommissionierung_id: 10, lane_name: "sonderfall_eu" },
            SONDERFALL_NONEU: { kommissionierung_id: 11, lane_name: "sonderfall_noneu" },
            MORE_FL: { kommissionierung_id: 12, lane_name: "more_fl" },
            GOT7_FL: { kommissionierung_id: 13, lane_name: "got7_fl" },
            SYN_FL: { kommissionierung_id: 14, lane_name: "syn_fl" },
            FTESN_FL: { kommissionierung_id: 15, lane_name: "ftesn_fl" },
            FTESN_EXPRESS_FL: { kommissionierung_id: 16, lane_name: "ftesn_express_fl" },
            SONDERFALL_FL: { kommissionierung_id: 17, lane_name: "sonderfall_fl" },
            MOP: { kommissionierung_id: 18, lane_name: "MOP" },
            KAKI_B2B_1: { kommissionierung_id: 6, lane_name: "B2B 1" },
            KAKI_B2B_2: { kommissionierung_id: 7, lane_name: "B2B 2" },
        },
    URL: { 
        MOP_KAKI_START: "/mop/kaki/start",
        RANPAK_KAKI_MANUAL_LABEL_PRINT: "/ranpak/kaki/manual_label_print",
        START: "/start",
        START_DUMMY: "/start-dmy",
        B2b_LOGIN: "/b2b",
        LOGIN: '/login',
        VERSION_1_PREFIX: "/v1",
        VERSION_2_PREFIX: "/v2",
    },
    EXPORT_LANE_PW: "246543",
    FAST_LANE_PW: "246543",
};
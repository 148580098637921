import { getUserRole } from "../../utils/common";
import axiosPrivate from "../privateApiClient";

export function resetContainer(container_id: any){
    const dynamic = ['30', '31', '32'].includes(String(getUserRole()));
    return axiosPrivate.post(`api/v1/picking/container/reset/${container_id}`, {}, {params: {'dynamic': dynamic}});
}

export function resetAllContainers(){
    return axiosPrivate.post(`api/v1/picking/container/reset_all`);
}
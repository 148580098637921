import React from "react";
import { useState } from "react";
import { unblockWarehouse } from "../network/lib/unblockWarehouse";
import { InfoBar } from "../../v1/components/InfoBar";

interface UnblockAllButtonProps{
    lanes: number[],
    refreshFunc: () => void
}

export const UnblockAllButton = ({lanes, refreshFunc}: UnblockAllButtonProps) => {

    const [info, setInfo] = useState<Boolean>(false);
    const [infoMsg, setInfoMsg] = useState<string>("");

    const handleUnblockAllPerWarehouse = async (lanes: number[]) => {
        await unblockWarehouse(lanes);
        refreshFunc();
        setInfo(true);
        setInfoMsg("Alle SKUs wurden entsperrt!");
        setTimeout(() => {
          setInfo(false);
        }, 3000);

    }

    return <>
        {info && <InfoBar message={infoMsg}></InfoBar>}
        <button
              id="btn-login"
              className="btn btn-md btn-outline mt-4"
              onClick={() => handleUnblockAllPerWarehouse(lanes)}
            >
              Alle SKUs entsperren
            </button>
    </>
}
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_MESSAGES } from "../../../../shared/utils/commonMessages";
import { SearchBar } from "../../../components/SearchBar";
import { isPick, urlForVersion } from "../../../../shared/utils/common";
import { InnerHeader } from "../../../components/MOP/kaki/InnerHeader";
import { getContainerStatus } from "../../../../shared/network/lib/picking";
import { useSpinner } from "../../../../shared/context/SpinnerProvider";
import { ErrorBar } from "../../../../v1/components/ErrorBar";
import { FormikState } from "formik";
import { PrimaryButton } from "../../../../shared/components/PrimaryButton";
import { getBatchPicking } from "../../../../shared/network/lib/batchPicking";
import { MopConfig } from "../../../../shared/config/mopConfig";
import { InfoBar } from "../../../../v1/components/InfoBar";

export const KakiContainerSelectionPage = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const { toggleSpinner } = useSpinner();
  const [showOrderInContainer, setShowOrderInContainer] = useState(false);
  const [containerId, setContainerId] = useState("");
  const [selectedContainers, setSelectedContainers] = useState<string[]>([]);
  const containerScrollRef = useRef<HTMLDivElement | null>(null);

  const package_box = require("../../../../assets/PNG/package_4947410.png");
  const qr_scan = require("../../../../assets/PNG/qr-scan_9561805.png");

  const handleSearch = async (
    value: string,
    resetValue: (
      nextState?: Partial<FormikState<{ packagecode: string }>> | undefined
    ) => void
  ) => {
    let url;
    setShowOrderInContainer(false);
    setErrMsg("");
    const param = `?barcode=${value}`;
    url = isPick() ? `/picking${param}` : `/check-view${param}`;
    if (selectedContainers.includes(value)) {
      setErrMsg(`Container ${value} already scanned.`);
      return;
    }
    if (selectedContainers.length >= MopConfig.maxContainerNumber) {
      setErr(true);
      setErrMsg("You can’t add more containers.");
      return;
    }
    try {
      const response = await getContainerStatus(value);
      if (response.status === 200) {
        setSelectedContainers((prevState) => {
          // Ensure only new/unique containerIds get added
          if (!prevState.includes(value)) {
            return [...prevState, value];
          }
          return prevState;
        });
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 404) {
        setErr(true);
        setErrMsg(error?.response?.data?.message);
        toggleSpinner({ showSpinner: false });
      } else if (error?.response?.status === 422) {
        setErr(true);
        setErrMsg(error?.response?.data?.message);

        setShowOrderInContainer(true);
        setContainerId(value);

        toggleSpinner({ showSpinner: false });
      } else if (error?.response?.status === 404) {
        navigate(urlForVersion(url));
      }
    }

    resetValue({ values: { packagecode: "" } });
  };

  const handleRemove = (index: number) => {
    setSelectedContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers.splice(index, 1);
      return updatedContainers;
    });
  };

  const handleConfirm = async () => {
    const body = JSON.stringify({
      ids: selectedContainers,
    });
    console.log(body);
    try {
      toggleSpinner({ showSpinner: true });
      let response = await getBatchPicking(body);
      if (response.status === 200) {
        setErr(false);
        console.log(response?.data);
        if (response?.data?.data?.length === 0) {
          console.log("No data!");
          setInfo(true)
          setInfoMsg("Keine Aufträge verfügbar!")
          toggleSpinner({ showSpinner: false });
          return;
        }
        toggleSpinner({ showSpinner: false });
        localStorage.removeItem("mopKakiSelectedContainersCache");
        navigate(urlForVersion("/mop/kaki/picking"), {
          state: { fullPayload: response?.data?.data },
        });
      }
    } catch (error: any) {
      console.log(error);
      console.log(error?.response?.status);
    }
  };

  const navigateToContainerView = async () => {
    localStorage.setItem(
      "mopKakiSelectedContainersCache",
      JSON.stringify(selectedContainers)
    );
    navigate(urlForVersion(`/reset-container?containerid=${containerId}`));
  };

  useEffect(() => {
    const oldSelectedContainers = localStorage.getItem(
      "mopKakiSelectedContainersCache"
    );
    let oldContainersList = null;
    if (oldSelectedContainers) {
      try {
        oldContainersList = JSON.parse(oldSelectedContainers);
        setSelectedContainers(oldContainersList);
      } catch (error) {
        console.error(
          "Error parsing mopKakiSelectedContainersCache value:",
          error
        );
      }
    }
  }, []);

  return (
    <div className="bg-app min-h-screen max-w-screen-md mx-auto flex flex-col justify-between pt-[15px]">
      <div className="w-full flex flex-col items-center px-5">
        <InnerHeader
          title="Multi-Order Picking"
          showBackButton={true}
          urlBackButton="/start"
        />

        <div className="flex my-4">
          <img className="max-w-[80px]" src={package_box} alt="Package" />
          <img className="max-w-[80px]" src={qr_scan} alt="QR Scanner" />
        </div>

        <div className="w-full">
          <SearchBar
            labelText={COMMON_MESSAGES.scanContainerBarcode}
            onClick={handleSearch}
            inputWidth="w-1/2"
            buttonWidth="w-1/2"
            showValidationMsg={false}
          />
        </div>

        {err && errMsg && (
          <div
            className="w-full flex justify-center"
            onClick={() => {
              setErrMsg("");
              setShowOrderInContainer(false);
            }}
          >
            <ErrorBar message={errMsg} />
          </div>
        )}

        {info && (
          <div
            className="w-full flex justify-center"
            onClick={() => {
              setInfoMsg("");
              setInfo(false);
            }}
          >
            <InfoBar message={infoMsg} />
          </div>
        )}

        <div className="w-full my-2">
          {selectedContainers.length > 0 && !showOrderInContainer && (
            <PrimaryButton
              type="button"
              label="START PICKING"
              onClick={handleConfirm}
            />
          )}
          {showOrderInContainer && (
            <PrimaryButton
              type="button"
              label={COMMON_MESSAGES.showContainer}
              onClick={navigateToContainerView}
            />
          )}
        </div>

        <div className="w-full flex flex-col">
          {selectedContainers.length > 0 && (
            <div className="w-full shrink" ref={containerScrollRef}>
              <table className="w-full mt-1 bg-white table-zebra overflow-auto">
                <thead className="font-bold bg-gray-100 sticky top-0">
                  <tr>
                    <th>#</th>
                    <th>Container ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {[...selectedContainers].reverse().map((container, index) => (
                    <tr key={String(index) + "_row"}>
                      <th>{selectedContainers.length - index}</th>
                      <th>{container}</th>
                      <th className="flex justify-center items-center py-1">
                        <button
                          id={String(index)}
                          className="btn btn-sm btn-circle btn-error btn-outline"
                          onClick={() =>
                            handleRemove(selectedContainers.length - index - 1)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { COMMON_MESSAGES } from "../../../../shared/utils/commonMessages";
import { InnerHeader } from "../../../components/InnerHeader";
import { useLocation } from "react-router-dom";
import { PrimaryButton } from "../../../../shared/components/PrimaryButton";
import { WarningButton } from "../../../../shared/components/WarningButton";
import { ProductInfo } from "../../../components/MOP/ProductInfo";
import { MopProductTable } from "../../../components/MopProductTable";
import { SmallPopupDialog } from "../../../components/SmallPopupDialog";
import { postBatchPickingContainerLoad } from "../../../../shared/network/lib/picking";
import { SuccessBar } from "../../../../v1/components/SuccessBar";
import { SearchBarCustomFocus } from "../../../components/SearchBarCustomFocus";
import { useSpinner } from "../../../../shared/context/SpinnerProvider";
import Dropdown from "../../../components/Dropdown";
import { ErrorBar } from "../../../../v1/components/ErrorBar";
import { InfoBar } from "../../../../v1/components/InfoBar";
import { saveDispute } from "../../../../shared/network/lib/disputes";
import { ERROR_MESSAGES } from "../../../../shared/utils/errorMessages";
import { isRgbWarehouse, urlForVersion } from "../../../../shared/utils/common";
import { useNavigate } from "react-router-dom";
import retrieveMopScans from "../../../utils/retrieveMOPScans";
import saveMopScans from "../../../utils/saveMOPScans";
import savePayload from "../../../utils/savePayload";
import { isEqual } from "lodash";
import resetDisputeContainers from "../../../utils/resetDisputeContainers";
import { MopConfig } from "../../../../shared/config/mopConfig";
// import { updateActionTsForSession } from "../../../../shared/network/lib/updateActionTsForSession";

interface Item {
  container_id: string;
  // other properties
}

export const MultiOrderPickingPage = () => {
  const navigate = useNavigate();
  const { toggleSpinner } = useSpinner();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [showInfoBar, setShowInfoBar] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showNextProductButton, setShowNextProductButton] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDipsuteButton, setShowDisputeButton] = useState(false);
  const [showDisputeDialog, setShowDisputeDialog] = useState(false);
  const [showRedirectButton, setShowRedirectButton] = useState(false);
  const [retryContainers, setRetryContainers] = useState<any>([]);
  // const [workWithStorage, setWorkWithStorage] = useState(true)
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [selectedContainerForDispute, setSelectedContainerForDispute] =
    useState("");
  const [fullPayloadState, setFullPayloadState] = useState<any[]>([]);

  const [transformedProducts, setTransformedProducts] = useState([
    {
      productName: "Sample Product",
      storageLocation: "Sample Location",
      productEan: "000000",
      containers: [
        {
          containerId: 99999,
          quantity_needed: 5,
          quantity_scanned: 0,
          done: false,
        },
      ],
    },
  ]);
  const [currentContainerId, setCurrentContainerId] = useState<any>(99999);
  const [currentProduct, setCurrentProduct] = useState({
    productName: "Sample Product",
    storageLocation: "Sample Location",
    productEan: "000000",
    containers: [
      {
        containerId: 99999,
        quantity_needed: 5,
        quantity_scanned: 0,
        done: false,
      },
    ],
  });
  const [scanningDone, setScanningDone] = useState(false);

  const { state } = useLocation();
  let { fullPayload } = state;

  const handleNewTransform = () => {
    setShowDisputeButton(false);
    // get the old payload
    const storage = retrieveMopScans();
    let storagePayload = storage?.fullPayload?.fullPayload;
    let hasScan = storage?.hasScan;
    // compare the old payload with the new one
    let isSamePayload = isEqual(fullPayload, storagePayload);
    if (
      !storagePayload &&
      Object.keys(fullPayload).length === 0 &&
      fullPayload.constructor === Object
    ) {
      navigate(urlForVersion("/mop/container_selection"));
    }
    // if it's different we're saving it with hasScan False

    const continueLastJob =
      storagePayload &&
      Object.keys(fullPayload).length === 0 &&
      fullPayload.constructor === Object;

    if (!isSamePayload && !continueLastJob) {
      savePayload(fullPayload);
      resetDisputeContainers();
    }

    if ((isSamePayload && hasScan) || continueLastJob) {
      console.log("Setting fullpayload to storage");
      setFullPayloadState((prevPayload) => {
        console.log(prevPayload);
        console.log(storagePayload);
        return storagePayload;
      });
      let tranformedStorage = storage?.storedMopScans?.transformedProducts;
      let storedProductIndex = storage?.storedMopScans?.productIndex;
      let storedCurrentContainerId =
        storage?.storedMopScans?.currentContainerId;

      setTransformedProducts(tranformedStorage);
      setCurrentProduct(tranformedStorage[storedProductIndex]);
      setCurrentContainerId(storedCurrentContainerId);
      setCurrentProductIndex(storedProductIndex);
      const isNotDone: any = tranformedStorage[
        storedProductIndex
      ].containers.some((obj: any) => Object.values(obj).includes(false));
      if (!isNotDone) {
        handleStoredContainerDone(
          tranformedStorage,
          storedCurrentContainerId,
          storedProductIndex
        );
        setShowNextProductButton(true);
      }
      return;
    }

    const extractLagerNumbers = (inputString: string): string[] => {
      const regex = /^(\d+)-(\d+)-(\d+)/;
      const matches = regex.exec(inputString);
      if (matches && matches.length >= 4) {
        const [, firstNumber, secondNumber, thirdNumber] = matches;
        return [firstNumber, secondNumber, thirdNumber];
      }
      return ["0", "0", "0"];
    };

    setFullPayloadState(fullPayload);

    const transformedData: any = [];
    fullPayload.forEach((item: any) => {
      const { container_id, versand_id, shipment_position } = item;

      shipment_position.forEach((position: any) => {
        const { product_name, ean, quantity, storage_location } = position;

        const existingProductIndex = transformedData.findIndex(
          (product: any) => product.productName === product_name
        );
        const productAlreadyPresentForContainer = !!transformedData[
          existingProductIndex
        ]?.containers.find(
          (container: any) => container.containerId === container_id
        );
        if (existingProductIndex !== -1 && !productAlreadyPresentForContainer) {
          // Product already exists, add the container information
          transformedData[existingProductIndex].containers.push({
            containerId: container_id,
            versand_id,
            shipment_position: position,
            quantity_needed: quantity,
            quantity_scanned: 0,
            done: false,
          });
        } else if (
          existingProductIndex !== -1 &&
          productAlreadyPresentForContainer
        ) {
          const existingContainerIndex = transformedData[
            existingProductIndex
          ]?.containers.findIndex(
            (container: any) => container.containerId === container_id
          );
          transformedData[existingProductIndex].containers[
            existingContainerIndex
          ]["quantity_needed"] += quantity;
        } else {
          // Product doesn't exist, create a new entry
          const newProduct = {
            productName: product_name,
            productEan: ean,
            storageLocation: storage_location,
            containers: [
              {
                containerId: container_id,
                versand_id,
                shipment_position: position,
                quantity_needed: quantity,
                quantity_scanned: 0,
                done: false,
              },
            ],
          };
          transformedData.push(newProduct);
        }
      });
    });
    try {
      transformedData.sort((a: any, b: any) => {
        // Check if the storage location is filled, if not use '00-00-00'
        let storageLocationA;
        let storageLocationB;
        if (!a.containers[0].shipment_position.storage_location) {
          storageLocationA = "00-00-00";
          console.log(storageLocationA);
        } else {
          storageLocationA = a.containers[0].shipment_position.storage_location;
        }

        if (!b.containers[0].shipment_position.storage_location) {
          storageLocationB = "00-00-00";
          console.log(storageLocationB);
        } else {
          storageLocationB = b.containers[0].shipment_position.storage_location;
        }

        // Extract the locations
        const parsedLocationsA = extractLagerNumbers(storageLocationA);
        const parsedLocationsB = extractLagerNumbers(storageLocationB);

        const sortByFirst = MopConfig.sortByFirstNumber;
        const firstA = parsedLocationsA[sortByFirst ? 0 : 1];
        const secondA = parsedLocationsA[sortByFirst ? 1 : 0];

        // Extract the number between the second hyphens
        const firstB = parsedLocationsB[sortByFirst ? 0 : 1];
        const secondB = parsedLocationsB[sortByFirst ? 1 : 0];

        // Convert the extracted numbers to integers for comparison
        let intFirstA;
        let intSecondA;

        let intFirstB;
        let intSecondB;

        parseInt(firstA) ? (intFirstA = parseInt(firstA)) : (intFirstA = 0);
        parseInt(secondA) ? (intSecondA = parseInt(secondA)) : (intSecondA = 0);

        parseInt(firstB) ? (intFirstB = parseInt(firstB)) : (intFirstB = 0);
        parseInt(secondB) ? (intSecondB = parseInt(secondB)) : (intSecondB = 0);

        // Compare the first numbers, if they are equal, compare the second numbers
        if (intFirstA === intFirstB) {
          return intSecondA - intSecondB;
        }

        return intFirstA - intFirstB;
      });
    } catch (error) {
      // Handle the error here
      console.error("An error occurred while sorting:", error);
    }

    // Deduplicate container IDs within each product
    transformedData.forEach((product: any) => {
      const uniqueContainerIds = new Set();
      product.containers = product.containers.filter((container: any) => {
        if (!uniqueContainerIds.has(container.containerId)) {
          uniqueContainerIds.add(container.containerId);
          return true;
        }
        return false;
      });
      setShowDialog(true);
    });

    setTransformedProducts(transformedData);
    setCurrentProduct(transformedData[0]);
    setCurrentContainerId(
      transformedData[currentProductIndex].containers[0].containerId
    );
  };

  function getUniqueContainerIds(): string[] {
    const uniqueContainerIds: number[] = [];

    for (const product of transformedProducts) {
      for (const container of product.containers) {
        if (!uniqueContainerIds.includes(container.containerId)) {
          uniqueContainerIds.push(container.containerId);
        }
      }
    }

    return uniqueContainerIds.map(String);
  }

  // const handleUpdateActionTimestamp = async () => {
  //   console.log('updating action timestamp')
  //   try {
  //     await updateActionTsForSession();
  //   } catch (error) {
  //     console.log("Error");
  //   }
  // };

  const handleChangeCurrentProduct = () => {
    // handleUpdateActionTimestamp();
    if (currentProductIndex + 1 < transformedProducts.length) {
      setCurrentProduct(transformedProducts[currentProductIndex + 1]);
      setCurrentProductIndex((prevIndex) => {
        return prevIndex + 1;
      });
      setCurrentContainerId(
        transformedProducts[currentProductIndex + 1].containers[0].containerId
      );
      saveMopScans(
        transformedProducts,
        currentProductIndex + 1,
        transformedProducts[currentProductIndex + 1].containers[0].containerId
      );
    } else {
      setCurrentProduct(transformedProducts[0]);
      setCurrentProductIndex((prevIndex) => {
        return prevIndex - prevIndex;
      });
      setCurrentContainerId(transformedProducts[0].containers[0].containerId);
      saveMopScans(
        transformedProducts,
        0,
        transformedProducts[0].containers[0].containerId
      );
    }
    if (!scanningDone) {
      setShowDialog(true);
      setShowNextProductButton(false);
    }
  };

  const handleScan = (value: string) => {
    let scanAmount = 1;
    console.log(scanAmount);
    if (value != currentProduct.productEan) {
      return false;
    }
    let containerIndex = transformedProducts[
      currentProductIndex
    ].containers.findIndex((obj) => obj.containerId === currentContainerId);
    if (
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_needed -
        transformedProducts[currentProductIndex].containers[containerIndex]
          .quantity_scanned <
      1
    ) {
      return false;
    }
    // update transformedProducts
    setTransformedProducts((prevProducts) => {
      let productCopy = [...prevProducts];
      let containerIndex = productCopy[
        currentProductIndex
      ].containers.findIndex((obj) => obj.containerId === currentContainerId);
      productCopy[currentProductIndex].containers[
        containerIndex
      ].quantity_scanned += 1;
      if (
        productCopy[currentProductIndex].containers[containerIndex]
          .quantity_scanned ===
        productCopy[currentProductIndex].containers[containerIndex]
          .quantity_needed
      ) {
        productCopy[currentProductIndex].containers[containerIndex].done = true;
      }
      saveMopScans(productCopy, currentProductIndex, currentContainerId);
      return productCopy;
    });

    if (
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_scanned ===
      transformedProducts[currentProductIndex].containers[containerIndex]
        .quantity_needed
    ) {
      handleContainerDone(currentContainerId);
    }
    return false;
  };

  const handleContainerDone = (containerId: any) => {
    // Check if there is another container within the product
    const remainingContainers = transformedProducts[
      currentProductIndex
    ].containers.filter(
      (item) => item.done === false && item.containerId !== containerId
    );
    if (remainingContainers.length > 0) {
      setCurrentContainerId((prevId: any) => {
        console.log(prevId);
        saveMopScans(
          transformedProducts,
          currentProductIndex,
          remainingContainers[0].containerId
        );
        return remainingContainers[0].containerId;
      });
      setShowDialog(true);
    } else {
      if (checkContainersDone(transformedProducts).length === 0) {
        setScanningDone(true);
      }
      setShowNextProductButton(true);
    }
    //
  };

  const handleStoredContainerDone = (
    storedProducts: any,
    storedContainerId: any,
    storedProductIndex: any
  ) => {
    // Check if there is another container within the product
    const remainingContainers = storedProducts[
      storedProductIndex
    ].containers.filter(
      (item: any) =>
        item.done === false && item.containerId !== storedContainerId
    );
    if (remainingContainers.length > 0) {
      setCurrentContainerId((prevId: any) => {
        console.log(prevId);
        saveMopScans(
          storedProducts,
          storedProductIndex,
          remainingContainers[0].containerId
        );
        return remainingContainers[0].containerId;
      });
      setShowDialog(true);
    } else {
      if (checkContainersDone(storedProducts).length === 0) {
        console.log("WE ARE DONE!");
        setScanningDone(true);
      }
      setShowNextProductButton(true);
    }
    //
  };

  const handleBatchContainerLoad = async () => {
    const storage = retrieveMopScans();
    let disputeContainer = storage?.disputeContainer;
    toggleSpinner({ showSpinner: true });
    setScanningDone(false);
    setShowNextProductButton(false);
    const userId = fullPayloadState[0]?.user?.user_id;
    let containerArray = fullPayloadState
      .map((originalObject: any) => ({
        container_id: originalObject.container_id,
        versand_id: originalObject.versand_id,
      }))
      .filter((item: any) => !disputeContainer.includes(item.container_id));
    
      if (retryContainers.length > 0) {
        containerArray = containerArray.filter(item => retryContainers.includes(item.container_id))
      }

    let body = {
      picker_id: userId,
      containers: containerArray,
    };
    console.log(body);
    console.log("LOADING THESE CONTAINERS", containerArray);

    try {
      let response = null;
      setShowInfoBar(false)
      setShowRedirectButton(false);
      response = await postBatchPickingContainerLoad(body);
      console.log(response);
      if (response.status === 201) {
        toggleSpinner({ showSpinner: false });
        if (response?.data?.issues.length > 0) {
          setRetryContainers(response?.data?.issues)
          console.log('SETTING ISSUE CONTAINERS', response?.data?.issues)
          let formattedIssues = response.data.issues.map((issue: any) => `#${issue}`);
          setShowInfoBar(true);
          setInfoMsg(
            `Folgende Container konnten nicht abgeschlossen werden. ${formattedIssues.join(', ')}. Bitte entleeren und zur Seite stellen. \n Die anderen Containern wurden erfolgreich abgeschlossen.`
          );
          setShowRedirectButton(true);
        } else {
          setShowSuccessBar(true);
          setSuccessMsg(
            "Job erfolgreich abgeschlossen! Sie werden weitergeleitet."
          );
          saveMopScans({}, currentProductIndex, currentContainerId, [], true);
          setTimeout(function () {
            navigate(urlForVersion("/mop/container_selection"));
          }, 2000);
        }
      }
      else if (response.status != 201) {
        toggleSpinner({ showSpinner: false });
          setShowInfoBar(true);
          setInfoMsg(`Es gab ein Problem: ${response?.data}`);
          setShowRedirectButton(true);
      }
    } catch (e: any) {
      toggleSpinner({ showSpinner: false });
      setShowInfoBar(true);
      setInfoMsg(e);
    }
  };

  const checkContainersDone = (transformedProducts: any) => {
    const notDoneContainers = [];

    // Iterate over each item in transformedProducts
    for (let i = 0; i < transformedProducts.length; i++) {
      const item = transformedProducts[i];

      // Check if 'containers' array exists and it's not empty
      if (Array.isArray(item.containers) && item.containers.length > 0) {
        // Iterate over each 'containers' array
        for (let j = 0; j < item.containers.length; j++) {
          const container = item.containers[j];

          // Check if 'done' attribute exists and it's false
          if (container.done !== true) {
            // Save the container that is not done
            notDoneContainers.push({ index: i, containerId: container.id });
          }
        }
      } else {
        // Save the index and container id if containers array is missing or empty
        notDoneContainers.push({ index: i, containerId: undefined });
      }
    }

    // Return the array of containers that are not done
    return notDoneContainers;
  };

  const findItemByContainerId = (
    items: Item[],
    containerId: string
  ): any | undefined => items.find((item) => item.container_id === containerId);

  const removeItemByContainerId = (
    items: Item[],
    containerId: string
  ): Item[] => {
    return items.filter((item: any) => item.container_id !== containerId);
  };

  function removeContainerFromProducts(
    products: any[],
    containerId: string
  ): [any[], number[], number[]] {
    const originalIndexes = products.map((_, index) => index);
    const deletedIndexes: number[] = [];

    const updatedProducts = products
      .map((product, index) => {
        const updatedContainers = product.containers.filter(
          (container: any) => container.containerId !== containerId
        );

        if (updatedContainers.length === 0) {
          deletedIndexes.push(index);
          return null; // Remove the product if it has no more containers
        }

        return { ...product, containers: updatedContainers };
      })
      .filter(Boolean); // Filter out null values (products with no containers)

    return [updatedProducts, originalIndexes, deletedIndexes];
  }

  function determineNewIndex(
    allIndexes: number[],
    currentIndex: number,
    deletedIndexes: number[]
  ): number {
    const currentIndexDeleted = deletedIndexes.includes(currentIndex);
    const indexesRemovedBeforeCurrent = deletedIndexes.filter(
      (index) => index < currentIndex
    ).length;
    const indexesNotDeletedAndAboveCurrent = allIndexes.filter(
      (index) => !deletedIndexes.includes(index) && index > currentIndex
    );

    let newIndex = 0;

    if (currentIndexDeleted) {
      if (indexesNotDeletedAndAboveCurrent.length >= 1) {
        newIndex = currentIndex - indexesRemovedBeforeCurrent;
      }
      if (indexesNotDeletedAndAboveCurrent.length === 0) {
        newIndex = currentIndex - indexesRemovedBeforeCurrent - 1;
      }
    } else if (!currentIndexDeleted) {
      newIndex = currentIndex - indexesRemovedBeforeCurrent;
    }

    return newIndex;
  }

  const handleDisputeSubmit = () => {
    if (selectedContainerForDispute == "") {
      return;
    }
    const storage = retrieveMopScans();
    console.log(storage);
    let disputeContainer = storage?.disputeContainer;
    console.log("STORED DISPUTE CONTAINERS", disputeContainer);
    disputeContainer.push(selectedContainerForDispute);
    const uniqueDisputeContainers = Array.from(new Set(disputeContainer));
    console.log(fullPayload);
    const item = findItemByContainerId(
      fullPayload,
      selectedContainerForDispute
    );
    const versand_id = item?.versand_id;
    console.log("DISPUTE FOR", versand_id);
    saveDispute({
      versand_id: versand_id,
      dispute_type: "TEST MOP",
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (getUniqueContainerIds().length <= 1) {
            // setShowNextProductButton(true)
            fullPayload = fullPayload.filter(
              (item: any) => item.container_id !== selectedContainerForDispute
            );

            setShowDisputeDialog(false);
            setInfo(true);
            setInfoMsg(
              `Klärfall für Container ${selectedContainerForDispute} wurde erfolgreich angelegt. Bitte entleeren und zurückstellen. Es gibt in diesem Job keine Picking Container mehr. Sie werden weitergeleitet.`
            );
            setTimeout(function () {
              navigate(urlForVersion("/mop/container_selection"));
            }, 2000);
            return;
          }
          setInfo(true);
          setInfoMsg(
            `Klärfall für Container ${selectedContainerForDispute} wurde erfolgreich angelegt. Bitte entleeren und zurückstellen.`
          );
          setTimeout(function () {
            setInfo(false);
          }, 3000);
          removeItemByContainerId(fullPayload, selectedContainerForDispute);
          fullPayload = fullPayload.filter(
            (item: any) => item.container_id !== selectedContainerForDispute
          );
          setTransformedProducts((prevProducts: any) => {
            const [newProducts, originalIndexes, deletedIndexes] =
              removeContainerFromProducts(
                prevProducts,
                selectedContainerForDispute
              );

            const newDeterminedIndex = determineNewIndex(
              originalIndexes,
              currentProductIndex,
              deletedIndexes
            );
            let newIndex = newDeterminedIndex;

            setCurrentProduct(newProducts[newIndex]);
            if (checkContainersDone(newProducts).length === 0) {
              setCurrentProductIndex(newIndex);
              setScanningDone(true);
              saveMopScans(
                newProducts,
                newIndex,
                newProducts[newIndex].containers[0].containerId,
                uniqueDisputeContainers
              );
              return newProducts;
            }
            const unfinishedContainerIndex = newProducts[
              newIndex
            ].containers.findIndex((item: any) => !item.done);
            if (unfinishedContainerIndex != -1) {
              setCurrentContainerId(
                newProducts[newIndex].containers[unfinishedContainerIndex]
                  .containerId
              );
              setCurrentProductIndex(newIndex);
              saveMopScans(
                newProducts,
                newIndex,
                newProducts[newIndex].containers[unfinishedContainerIndex]
                  .containerId,
                uniqueDisputeContainers
              );
              setShowDialog(true);
            } else {
              // Check whether current product has unfinished containers

              setShowNextProductButton(true);
              setShowDialog(false);
              saveMopScans(
                newProducts,
                newIndex,
                newProducts[newIndex].containers[0].containerId,
                uniqueDisputeContainers
              );
            }
            return newProducts;
          });
          setSelectedContainerForDispute("");
          setShowDisputeDialog(false);
        } else if (response.status >= 400) {
          setErr(true);
          setErrMsg(ERROR_MESSAGES.DisputeCreationError);
        }
        toggleSpinner({ showSpinner: false });
      })
      .catch((error) => {
        setErrMsg(
          error?.data?.message?.error_message ||
            ERROR_MESSAGES.DisputeCreationError
        );
        toggleSpinner({ showSpinner: false });
      });
  };

  const handleCheckContainerId = (value: any) => {
    if (value == currentContainerId) {
      setShowDialog((prevState) => {
        return !prevState;
      });
    }
  };

  const handleOptionChange = (newOption: string) => {
    setSelectedContainerForDispute(newOption);
  };

  const handleTryFocus = () => {
    const input = document.getElementById("barcodescan");
    input?.focus();
  };

  const handleContinueAfterIssue = () => {
    saveMopScans({}, currentProductIndex, currentContainerId, [], true);
    navigate(urlForVersion("/mop/container_selection"));
  };

  useEffect(() => {
    if (!MopConfig.initialContainerScanNeeded) {
      handleTryFocus();
    }
    if (showDialog === false) {
      const timer = setTimeout(() => {
        // Execute your function here
        handleTryFocus();
      }, 50); // 500 milliseconds = 0.5 seconds

      return () => {
        // Cleanup function to cancel the timer if the component unmounts
        clearTimeout(timer);
      };
    }
    return;
  }, [showDialog, currentProductIndex]);

  useEffect(() => {
    handleNewTransform();
    if (!isRgbWarehouse()) {
      setShowDisputeButton(true);
    }
  }, []);

  return (
    <div className="bg-app min-h-screen">
      {/* <h1>{currentProduct?.productEan}</h1> */}
      <div className="max-w-screen-md min-h-screen mx-auto px-2 py-6 flex flex-col items-center">
        <InnerHeader
          title={"Multi Order Picking"}
          showBackButton={false}
          urlBackButton="/mop/container_selection"
        />

        <div className="w-full px-7 flex flex-col justify-center items-center mb-5 mt-2">
          {showSuccessBar && <SuccessBar message={successMsg} />}
          {showInfoBar && <InfoBar message={infoMsg} />}
          {showRedirectButton && (
            <div className="w-60 my-4 flex flex-col gap-4">
              <PrimaryButton
                type="button"
                label={"Erneut Versuchen"}
                onClick={handleBatchContainerLoad}
              />

              <PrimaryButton
                type="button"
                label={"Weiter"}
                onClick={() => handleContinueAfterIssue()}
              />
            </div>
          )}
          {err && <ErrorBar message={errMsg} />}
          {info && <InfoBar message={infoMsg} />}
        </div>

        <ProductInfo product={currentProduct} />
        <h1 className="mt-4">
          Produkt {currentProductIndex + 1} von {transformedProducts.length}
        </h1>
        <div className="flex flex-col justify-center items-center w-4/5">
          <SearchBarCustomFocus
            fieldId="barcodescan"
            focusOnIdWhenDone="barcodescan"
            onClick={handleScan}
            labelText={COMMON_MESSAGES.scanProductBarcode}
            changeFocusWhenDone={false}
          />
        </div>
        <div className="w-full px-7 flex flex-col justify-center items-center gap-10">
          {scanningDone && (
            <div className="w-60 my-4">
              <PrimaryButton
                type="button"
                label={"Finish Job"}
                onClick={handleBatchContainerLoad}
              />
            </div>
          )}
          <div className="w-60 mt-2">
            {showNextProductButton && (
              <PrimaryButton
                type="button"
                label={"Next Product"}
                onClick={() => handleChangeCurrentProduct()}
              />
            )}
          </div>
        </div>
        <div className="w-full mt-10 mb-10">
          <MopProductTable
            productData={currentProduct}
            currentContainerId={currentContainerId}
          />
        </div>

        {showDipsuteButton && (
          <div className="w-full px-7 flex flex-col justify-center items-center gap-10">
            <div className="w-36">
              <WarningButton
                name="klarfall-btn"
                placeholder={COMMON_MESSAGES.clarificationBtn}
                onchange={() => setShowDisputeDialog(true)}
              />
            </div>
          </div>
        )}
      </div>

      <SmallPopupDialog
        showDialog={showDisputeDialog}
        hideDialog={() => setShowDisputeDialog(false)}
        title={`Klärfall für Container Anlegen`}
      >
        <div className="flex flex-col gap-6 w-80">
          <Dropdown
            options={getUniqueContainerIds()}
            onChange={handleOptionChange}
            selectedOption={selectedContainerForDispute}
            placeholder={"Wähle einen Container"}
          />
          <PrimaryButton
            type="button"
            label={"Bestätigen"}
            onClick={() => handleDisputeSubmit()}
          />
        </div>
      </SmallPopupDialog>
      {MopConfig.initialContainerScanNeeded && (
        <SmallPopupDialog
          showDialog={showDialog}
          hideDialog={() => setShowDialog(false)}
          title={`Scanne Container ${currentContainerId}`}
        >
          <SearchBarCustomFocus
            onClick={handleCheckContainerId}
            fieldId="containerPopup"
            focusOnIdWhenDone="barcodescan"
            changeFocusWhenDone={true}
          />
        </SmallPopupDialog>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { SearchBar } from '../../SearchBar';
import { ErrorBar } from '../../ErrorBar';
import { disputeConfig } from '../../../../shared/config/disputeConfig';

const warehouseIcon = require("../../../../assets/warehouse.png");
const qrScanIcon = require("../../../../assets/qr_scan.png");

interface KakiLocationModalProps {
  storageLocation: string,
  onScanHandler: any,
}

export const KakiLocationModal = ({storageLocation, onScanHandler}: KakiLocationModalProps) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const transformLagerText = (input: string): string => {
    // Remove Parentheses and what's inside
    const noParentheses = input.replace(/\([^)]*\)/g, '');
    
    // Remove the dashes
    const noDashes = noParentheses.replace(/[-\s]/g, '')
    
    return noDashes
  };

  const handleLocationScan = (value: string) => {
    setShowErrorMessage(false);
    if (transformLagerText(value) === transformLagerText(storageLocation) 
      || value === disputeConfig.disputeCode
    ) {
      onScanHandler();
      return;
    }
    setShowErrorMessage(true);
  }

  const elementId = 'locationModal'

  const handleTryFocus = () => {
    const input = document.getElementById(elementId);
    input?.focus();
  };

  useEffect(() => {
      const timer = setTimeout(() => {
        // Execute your function here
        handleTryFocus();
      }, 50); // 500 milliseconds = 0.5 seconds

      return () => {
        // Cleanup function to cancel the timer if the component unmounts
        clearTimeout(timer);
      };

  }, []);

  // console.log(transformLagerText("K-10-017-3"))
  // console.log(transformLagerText('0801820'));
  // console.log(transformLagerText('08-018-10 (100)'));
  // console.log(transformLagerText('08-018-20 (100)'));
  // console.log(transformLagerText('0801820'));

  return (
    <div className="bg-app min-h-screen">
      <div className="max-w-screen-md min-h-screen mx-auto p-4 flex flex-col justify-between">
        <div className="mt-4 w-full flex flex-col items-center">
          <h1 className="text-3xl text-center">Multi-Order Picking</h1>
          <div className="flex justify-between mt-8">
            <div className="ml-[110px]">
              <img src={warehouseIcon} alt="warehouse logo" />
            </div>
            <div className="mr-[110px]">
              <img src={qrScanIcon} alt="qr scan logo" />
            </div>
          </div>
          <div className="bg-[#E0E7F9] h-[45px] mt-6 pt-2 pb-2 w-full flex items-center justify-center text-[24px] font-bold">
            {storageLocation}
          </div>
          <SearchBar 
              labelText='Scan Location-Barcode' 
              onClick={handleLocationScan}
              elementKey={elementId}
              ></SearchBar>
          {showErrorMessage && (
            <ErrorBar message='Please scan the correct location code'/>
          )}
        </div>
      </div>
    </div>
  );
}
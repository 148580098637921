import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import {
  getUserRole,
  isB2B,
  isRgbWarehouse,
  urlForVersion,
} from "../../shared/utils/common";
import tableConfig from "../../shared/config/tableConfig";
import { PickingTable } from "../components/PickingTable";
import { Modal } from "../components/Modal";
import { ReprintModal } from "../components/ReprintModal";
import { InfoBar } from "../components/InfoBar";
import { SuccessBar } from "../components/SuccessBar";
import { saveDispute, getDisputes } from "../../shared/network/lib/disputes";
// import { getPrintedCount } from "../../shared/network/lib/getPrintedCount";
import { validateShipment } from "../../shared/network/lib/validateShipment";
import { printLastEntry } from "../../shared/network/lib/printLastEntry";
import { getPickingDetails } from "../../shared/network/lib/picking";
import { ErrorBar } from "../components/ErrorBar";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
// import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import checkStoredShipmentPosition from "../../shared/utils/checkStoredShipmentPosition";
import { getAmountPacked } from "../../shared/network/lib/getAmountPacked";
import { RgbStorageLocationSortingService } from "../services/RgbStorageLocationSortingService";
import { getPackageRecommendation } from "../../shared/network/lib/getPackageRecommendation";

export function PickingPage() {
  const [shipmentPosition, setShipmentPosition] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [validationInfo, setValidationInfo] = useState<Boolean>(false);
  const [validationInfoMsg, setValidationInfoMsg] = useState<string>("");
  const [info, setInfo] = useState<Boolean>(false);
  const [reprintMsg, setReprintMsg] = useState<string>("");
  const [showReprintAlert, setShowReprintAlert] = useState<Boolean>(false);
  const [fullShipmentPayload, setFullShipmentPayload] = useState<any>([]);
  const [deliveryNumber, setDeliveryNumber] = useState();
  const [showDisputeButton, setShowDisputeButton] = useState(false);
  const [finishedJobs, setFinishedJobs] = useState(0);
  const [disputeTypes, setDisputeTypes] = useState([{ dispute_type: "" }]);
  const { userInfo }: any = useOutletContext();
  const [refreshState, setRefreshState] = useState<Boolean>(false);
  const [packageRecommendation, setPackageRecommendation] = useState("");
  const navigate = useNavigate();

  const formatShippingPositionFromAPI = (data: any) => {
    let preppedData = [];
    for (let i = 0; i < data.length; i++) {
      let rowCells = [];
      data[i]["ist"] = 0;
      for (let j = 0; j < tableConfig.length; j++) {
        if (tableConfig[j].column in data[i]) {
          rowCells.push({
            columnName: tableConfig[j].column,
            value: data[i][tableConfig[j].column],
            columnStyles: tableConfig[j].columnStyles,
            rowStyles: tableConfig[j].rowStyles,
          });
        }
      }
      let amountNeeded = parseInt(data[i].quantity);
      let amountScanned = 0;
      preppedData.push({
        id: data[i].sku,
        ean: data[i].ean,
        key: data[i].sku,
        done: amountNeeded === amountScanned,
        amountNeeded,
        amountScanned,
        cells: rowCells,
      });
    }
    return preppedData;
  };

  async function fetchData() {
    const MAX_RETRIES = 1;
    let numRetries = 0;

    while (true) {
      try {
        let response = await getPickingDetails({ picking_validation: false });
        if (response.status === 200) {
          setError(false);
          setInfo(false);
          return response?.data;
        }
      } catch (error: any) {
        console.log(error);
        console.log(error?.response?.status);
        if (numRetries === MAX_RETRIES) {
          if (error?.response.status === 422) {
            setError(true);
            setErrMsg(ERROR_MESSAGES.pickingPreValidationError);
            setInfoMsg(INFO_MESSAGES.disputeSuccess);
            setInfo(true);
            console.log(error?.response?.data?.message?.versand_id);
          }
          if (error?.response.status === 423) {
            console.log(error?.response?.data);
            setError(true);
            setErrMsg(ERROR_MESSAGES.processedLieferscheinError);
            setInfoMsg(INFO_MESSAGES.disputeSuccess);
            setInfo(true);
          }
          if (error?.response.status === 424) {
            console.log(error?.response?.data);
            setError(true);
            setErrMsg(ERROR_MESSAGES.productDataError);
          } else {
            setError(true);
            setErrMsg(ERROR_MESSAGES.noPickingDataAvailableError);
          }
        }
        // Retry the request if we haven't exceeded the max number of retries
        if (numRetries < MAX_RETRIES) {
          numRetries++;
          console.log(`Retrying... (attempt ${numRetries} of ${MAX_RETRIES})`);
          continue;
        }
      }

      break;
    }
  }

  const validationDisputeSubmit = async (versand_id: any) => {
    try {
      const response = await saveDispute({
        versand_id: versand_id,
        dispute_type: ERROR_MESSAGES.disputeTypeValidationError,
      });

      if (response.status === 200 || response.status === 201) {
        setInfoMsg(INFO_MESSAGES.disputeSuccess);
        setInfo(true);
      } else if (response.status >= 400) {
        setErrMsg(ERROR_MESSAGES.DisputeCreationError);
      }
    } catch (error: any) {
      setErrMsg(error?.data?.message || ERROR_MESSAGES.DisputeCreationError);
    }
  };

  const fetchDisputeTypes = () => {
    const data = getDisputes()
      .then((response) => setDisputeTypes(response?.data))
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  // const fetchFinishedJobs = () => {
  //   const data = getPrintedCount()
  //     .then((response) => {
  //       setFinishedJobs(response?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return data;
  // };

  // const fetchAverageShipments = () => {
  //   const data = getAveragePerMonth()
  //     .then((response) => {
  //       setAverageShipments(response?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return data;
  // };

  const doValidateShipment = async (data: any) => {
    try {
      const response: any = await validateShipment(data);
      if (response.status === 200 || response.status === 201) {
        console.log("VALIDATED");
        setValidationInfoMsg(INFO_MESSAGES.validationSuccess);
        setValidationInfo(true);
        return true;
      } else if (response.status >= 400 && response.status <= 500) {
        if (response?.status === 401 && response?.data?.error_code === 20005) {
          setInfo(true);
          setInfoMsg(ERROR_MESSAGES.dhlOutageNotification);
          return true;
        }
        console.log(`VALIDATION ERROR FOR VERSAND ID: ${data.versand_id}`);
        setError(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.pickingPreValidationError
        );
        await validationDisputeSubmit(data.versand_id);
        return false;
      }
      return false;
    } catch (error: any) {
      console.log(error);
      console.log(
        `VALIDATION ERROR FOR VERSAND ID: ${error?.response?.data?.message?.versand_id}`
      );
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.error_code === 20005
      ) {
        setInfo(true);
        setInfoMsg(ERROR_MESSAGES.dhlOutageNotification);
        return true;
      } else {
        setError(true);
        setErrMsg(
          error?.response?.data?.message?.error_message ||
            ERROR_MESSAGES.pickingPreValidationError
        );
        await validationDisputeSubmit(data.versand_id);
        return false;
      }
    }
  };

  const compareAndChooseShipmentPosition = (
    versandIdAPI: any,
    positionFromAPI: any,
    positionFromStorage: any
  ) => {
    if (
      versandIdAPI === positionFromStorage?.versand_id &&
      positionFromStorage?.role == getUserRole()
    ) {
      console.log("returning storage");
      return positionFromStorage.position;
    } else {
      console.log("returning api");
      return positionFromAPI;
    }
  };

  const prepareData = async () => {
    let data;
    try {
      data = await fetchData();
      let validated = false;
      if (!isB2B() && !data?.shipment_head?.exclude_validation) {
        validated = await doValidateShipment(data);
      } else {
        validated = true;
      }
      setDeliveryNumber(data.shipment_head.lieferschein);
      let chosenPosition = formatShippingPositionFromAPI(
        data.shipment_position
      );
      if (!isRgbWarehouse()) {
        const shipmentPositionFromStorage = checkStoredShipmentPosition();
        if (shipmentPositionFromStorage) {
          chosenPosition = compareAndChooseShipmentPosition(
            data?.versand_id,
            chosenPosition,
            shipmentPositionFromStorage
          );
        }
      }

      if (validated) {
          const sortedData = [...chosenPosition].sort(
            RgbStorageLocationSortingService
          );
          setShipmentPosition(sortedData);
        setFullShipmentPayload(data);
        setTimeout(() => {
          setValidationInfo(false);
        }, 2000);
        handleGetPackageRecommendation(data?.versand_id);
      }
    } catch (err) {
      setError(true);
      setErrMsg(
        "Es sind keine Bestellungen zur Kommissionierung verfügbar. Versuchen Sie, die Seite zu aktualisieren!"
      );
    }
  };

  const handleDisputeSubmit = (i: any) => {
    if (i == null) {
      console.log("No Option Selected");
      return;
    }
    try {
      let body = {
        versand_id: fullShipmentPayload.versand_id,
        dispute_type: disputeTypes[i],
      };
      console.log(body);
      saveDispute({
        versand_id: fullShipmentPayload.versand_id,
        dispute_type: disputeTypes[i].dispute_type,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackToStart = () => {
    navigate(urlForVersion("/start"));
  };

  const handleGetPackNumbers = () => {
    if (userInfo.hasOwnProperty("user_name")) {
      getAmountPacked(userInfo?.user_name)
        .then((response) => {
          if (response.status === 200) {
            setFinishedJobs(response?.data);
          } else if (response.status === 400) {
            console.log("Error while getting Pack Metric");
          }
        })
        .catch((error) => {
          console.log("Error getting Pack Metric", error);
        });
    }
  };

  const handleGetPackageRecommendation = (versandId: string) => {
    try {
      getPackageRecommendation(versandId).then((response) => {
        if (response?.data.length > 0) {
          setPackageRecommendation(response?.data);
        }
      });
    } catch (error) {
      console.error("Error fetching package recommendation:", error);
    }
  };

  const handleReprintSubmit = () => {
    printLastEntry()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setReprintMsg(
            response?.data?.success || INFO_MESSAGES.reprintSuccessInfo
          );
          setShowReprintAlert(true);
          setTimeout(() => {
            setShowReprintAlert(false);
          }, 3000);
        } else if (response.status === 400) {
          setReprintMsg(ERROR_MESSAGES.reprintError);
          setShowReprintAlert(true);
          setTimeout(() => {
            setShowReprintAlert(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log(error);
        setReprintMsg(ERROR_MESSAGES.reprintError);
        setShowReprintAlert(true);
        setTimeout(() => {
          setShowReprintAlert(false);
        }, 5000);
      });
  };

  // refresh page
  const refreshPage = () => {
    setRefreshState((prev) => !prev);
  };

  useEffect(() => {
    prepareData();
    fetchDisputeTypes();
  }, [refreshState]);

  useEffect(() => {
    if (isRgbWarehouse()) {
      handleGetPackNumbers();
    }
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      setInfo(false);
    }, 3500);
  }, [info]);

  console.log(fullShipmentPayload);
  return (
    <>
      {validationInfo && (
        <div className="fixed top-4 left-0 right-0 flex justify-center">
          <SuccessBar message={validationInfoMsg} />
        </div>
      )}
      <div className="flex flex-col items-center">
        <div className="absolute top-4 left-4">
          <button className="btn btn-ghost gap-2" onClick={handleBackToStart}>
            <svg
              className="h-6 w-6 fill-current md:h-8 md:w-8"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
            </svg>
          </button>
        </div>
        {isB2B() && (
          <p className="text-2xl font-bold mt-6">B2B-2 Picking Page</p>
        )}
        {!isB2B() && <p className="text-2xl font-bold mt-6">Picking Page</p>}

        <div className="divider"></div>

        <ReprintModal submit={handleReprintSubmit} />
        {showReprintAlert && <InfoBar message={reprintMsg} />}
        <div className="flex gap-2">
          <h1 className="text-4xl font-bold mt-4">LS: {deliveryNumber}</h1>
          <h1 className="text-4xl font-bold mt-4">|</h1>
          {packageRecommendation && (
            <div className="bg-green-600 rounded rounded-md mt-2 py-1 px-2">
              <p className="text-white font-bold">{packageRecommendation}</p>
            </div>
          )}
        </div>
        {isRgbWarehouse() && (
          <h1 className="text-4xl font-bold mt-4">
            Anzahl Pakete in dieser Schicht: {finishedJobs}
          </h1>
        )}

        {error && <div className="divider"></div>}
        {error && <ErrorBar message={errMsg} />}
        {info && <InfoBar message={infoMsg} />}
        {error && (
          <button className="btn btn-outline mt-4" onClick={refreshPage}>
            Seite neu laden
          </button>
        )}
        {error && <div className="divider"></div>}

        <div>
          <PickingTable
            processed={shipmentPosition}
            fullPayload={fullShipmentPayload}
            handleDisputeChange={setShowDisputeButton}
          />

          {showDisputeButton && (
            <Modal options={disputeTypes} submit={handleDisputeSubmit} />
          )}
        </div>
      </div>
    </>
  );
}

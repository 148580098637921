import React from "react";
import { ProcessedData } from "../../shared/types/data";
import { classNames } from '../../shared/utils/classNames'

export const ProductTable = ({shipmentPosition}: {shipmentPosition: ProcessedData[]}) => {
  const getRow = (row: ProcessedData, index: number, done: boolean) =>{
    return <tr key={`${row.id}_${index}`}>
      {row?.cells?.map((element, indexCell) => (
        <td
          key={row.id + element.columnName + index}
          className={classNames(element.rowStyles, done? "bg-green-500":"")}
        >
          <div className='flex flex-row gap-1'>
            {element.value}
            {indexCell === 3 && done &&
              <svg className="h-6 w-6 text-green-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26"
              stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/>
                <polyline points="22 4 12 14.01 9 11.01"/>
              </svg>
            }
          </div>
        </td>
      ))}
    </tr>
  }

  return (
    <table id="product-table" className="table-fixed w-full mt-10">
      <thead className='bg-gray-200 font-bold'>
      <tr>
        {shipmentPosition &&
          shipmentPosition[0]?.cells?.map((item) => (
            <td key={item.columnName} className={item.columnStyles}>
              {item.columnHeader}
            </td>
          ))}
      </tr>
      </thead>
      <tbody>
      {shipmentPosition
        ?.filter((item) => item.done !== true)
        .map((row, index) => (
          getRow(row, index, false)
        ))}
      {shipmentPosition
        ?.filter((item) => item.done === true)
        .map((row, index) => (
          getRow(row, index, true)
        ))}
      </tbody>
    </table>
  )
}

import { CONFIGURATIONS as C } from "../../shared/utils/configurations";

export const rolesDefault = [
  { role_id: C.ROLE.PICK.ID, role_name: C.ROLE.PICK.NAME },
  { role_id: C.ROLE.PACK.ID, role_name: C.ROLE.PACK.NAME },
  { role_id: C.ROLE.PICK_PACK.ID, role_name: C.ROLE.PICK_PACK.NAME },
  { role_id: C.ROLE.MULTI_ORDER_PICK.ID, role_name: C.ROLE.MULTI_ORDER_PICK.NAME},
  { role_id: C.ROLE.RANPAK_MULTI_ORDER_PICK.ID, role_name: C.ROLE.RANPAK_MULTI_ORDER_PICK.NAME},
  { role_id: C.ROLE.RANPAK_MULTI_ORDER_PACK.ID, role_name: C.ROLE.RANPAK_MULTI_ORDER_PACK.NAME},
  { role_id: C.ROLE.RANPAK_PRINT.ID, role_name: C.ROLE.RANPAK_PRINT.NAME },
];
import React, { useState } from "react";
import { InnerHeader } from "../components/InnerHeader";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { LogoutButton } from "../../shared/components/LogoutButton";
import { userLogout } from "../../shared/network/lib/userLogout";
import { useNavigate } from "react-router-dom";
import { SearchBar } from "../components/SearchBar";
import { FormikState } from "formik";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
import { ErrorBar } from "../components/ErrorBar";
import { SuccessBar } from "../../v1/components/SuccessBar";
import {
  getPickingContainer,
  postPickingContainerUnload,
} from "../../shared/network/lib/picking";
import { startShipping } from "../../shared/network/lib/shipping";
import { reprintVersand } from "../../shared/network/lib/reprintVersand";
import { resetContainer } from "../../shared/network/lib/resetContainer";

const qr_scan = require("../../assets/PNG/qr-scan_9561805.png");

export const RanpakPrintPage = () => {
  const navigate = useNavigate();
  const { toggleSpinner } = useSpinner();

  const [scannedContainerId, setScannedContainerId] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const showMessage = successMessage !== null || errorMessage !== null;
  const showPrintButton = scannedContainerId !== null;

  const handleLogout = async () => {
    try {
      await userLogout().then((response) => {
        if (response.status === 200) {
          navigate("/login");
        }
      });
    } catch (error) {
      console.log("Error");
    }
  };

  const handleScan = (
    value: string,
    resetValue: (
      nextState?: Partial<FormikState<{ packagecode: string }>> | undefined
    ) => void
  ) => {
    // any precheck necessary?
    setScannedContainerId(value);
    resetValue({ values: { packagecode: "" } });
  };

  const handlePrintJob = async () => {
    try {
      let payload = await getContainerContentWithoutNavigate(
        scannedContainerId
      );
      if (!payload) {
        return;
      }
      await postShipment(payload);
    } catch (error: any) {
      console.log(error?.response);
      if (error.response.status === 422) {
        setErrorMessage(error?.response?.data?.message);
        toggleSpinner({ showSpinner: false });
      } else if (error.response.status === 404) {
        setErrorMessage(error?.response?.data?.message);
        toggleSpinner({ showSpinner: false });
      }
    }
  };

  const handleReprintSubmit = async () => {
    let payload: any;
    try {
      payload = await getContainerContentWithoutNavigate(scannedContainerId);
      if (!payload) {
        return;
      }
      if (!payload?.versand_id) {
        return;
      }
      toggleSpinner({ showSpinner: true });
      let response = await reprintVersand(payload?.versand_id);
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(INFO_MESSAGES.reprintSuccessInfo);
        toggleSpinner({ showSpinner: false });
      } else if (response.status >= 400) {
        setErrorMessage(
          response?.data?.message?.error_message || ERROR_MESSAGES.reprintError
        );
      }
    } catch (error: any) {
      console.log(error?.response);
      if (error.response.status === 404) {
        setErrorMessage("No Reprintable Label Found!");
        toggleSpinner({ showSpinner: false });
      }
    }
  };

  const handleDynamicUnload = async (payload: any) => {
    let response;
    let unloadStatus;
    try {
      response = await postPickingContainerUnload({
        container_id: scannedContainerId as string,
        packer_id: payload.user_id,
        versand_id: payload.versand_id,
      });
      unloadStatus = response.status;
    } catch (error: any) {
      handleContainerReset()
    }
    setScannedContainerId(null)
    return unloadStatus;
  };


  const handleContainerReset = async () => {
    try {
      resetContainer(scannedContainerId as string).then((response) => {
        if (response.status === 200) {
          console.log(response?.data);
          setSuccessMessage(`Success - ${response?.data?.message}`);
        }
      });
    } catch (error: any) {
      console.log(error);
      setErrorMessage(
        ERROR_MESSAGES.containerUnloadingError.replace(
          "{code}",
          scannedContainerId
        )
      );
      toggleSpinner({ showSpinner: false });
    }
  };

  const postShipment = async (payload: any) => {
    let response;
    try {
      toggleSpinner({ showSpinner: true });
      response = await startShipping(payload, true);
      if (response.status === 201) {
        setSuccessMessage(INFO_MESSAGES.ranpakFallbackLabelSuccessfullyPrinted);
        toggleSpinner({ showSpinner: false });
        return true;
      } else if (response.status === 202) {
        setErrorMessage(ERROR_MESSAGES.shippingAlreadyProcessedNoPrint);
        toggleSpinner({ showSpinner: false });
        return false;
      } else if (response.status === 203) {
        setErrorMessage(
          `LS: ${payload?.shipment_head?.lieferschein} || Auftrag: ${payload?.shipment_head?.auftrag}`
        );
        toggleSpinner({ showSpinner: false });
        return false;
      } else {
        return false;
      }
    } catch (error: any) {
      setErrorMessage(
        error?.response?.data?.message?.detail ||
          ERROR_MESSAGES.generalShippingError
      );
      toggleSpinner({ showSpinner: false });
      return false;
    }
  };

  const getContainerContentWithoutNavigate = async (containerId: string) => {
    try {
      const response = await getPickingContainer(containerId);
      if (response.status === 200) {
        return response?.data;
      }
    } catch (error: any) {
      if (error?.response.status === 404) {
        const message = ERROR_MESSAGES.packingNotFound.replace(
          "{code}",
          containerId
        );
        setErrorMessage(error?.response?.data?.message || message);
        toggleSpinner({ showSpinner: false });
      } else {
        setErrorMessage(
          error?.response?.data?.message ||
            ERROR_MESSAGES.noPickingDataAvailableError
        );
      }
      toggleSpinner({ showSpinner: false });
    }
    return false;
  };

  return (
    <div className="bg-app min-h-screen max-w-screen-md mx-auto flex flex-col justify-between pt-[15px]">
      <div className="w-full flex flex-col items-center px-5">
        <InnerHeader title={"Ranpak print"} showBackButton={false} />

        <div className="my-4">
          <img className="max-w-[80px]" src={qr_scan} alt="QR Scanner" />
        </div>

        {showMessage && (
          <div className="w-full flex flex-col justify-center items-center my-4">
            {successMessage && (
              <div
                className="w-full"
                onClick={() => {
                  setSuccessMessage(null);
                }}
              >
                <SuccessBar message={successMessage} />
              </div>
            )}
            {errorMessage && (
              <div
                className="w-full"
                onClick={() => {
                  setErrorMessage(null);
                }}
              >
                <ErrorBar message={errorMessage} />
              </div>
            )}
          </div>
        )}

        <div className="w-full">
          <SearchBar
            labelText={COMMON_MESSAGES.scanContainerBarcode}
            onClick={handleScan}
            inputWidth="w-1/2"
            buttonWidth="w-1/2"
            showValidationMsg={false}
          />
        </div>

        {scannedContainerId && (
          <div className="flex justify-between w-full mt-5 mb-2">
            <div>
              <span className="font-bold">Container: </span>{" "}
              {scannedContainerId}
            </div>
            <div>
              <button
                id="remove_container"
                className="btn btn-sm btn-circle btn-error btn-outline"
                onClick={() => setScannedContainerId(null)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center grow">
          {showPrintButton && (
            <>
              <div className="w-80 mt-5">
                <PrimaryButton
                  type="button"
                  label={COMMON_MESSAGES.printRanpakLabelManually}
                  onClick={handlePrintJob}
                />
              </div>
              <div className="w-80 mt-5">
                <PrimaryButton
                  type="button"
                  onClick={handleReprintSubmit}
                  label={COMMON_MESSAGES.rePrintLabel}
                />
              </div>


              <div className="w-80 mt-10">
                <PrimaryButton
                  type="button"
                  onClick={handleDynamicUnload}
                  label={"FINISH AND RESET CONTAINER"}
                />
              </div>
            </>
          )}
          <div className="w-80 mt-24">
            <LogoutButton onClick={handleLogout} />
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

interface MopProductTableProps{
  productData: any,
  currentContainerId: number
}

export const MopProductTable = ({productData, currentContainerId}: MopProductTableProps) => {
  const getRow = (row: any, index: number, currentContainerId: number) => {
    let extraClass = ''
    if(currentContainerId == row.containerId){extraClass = 'bg-yellow-300'}
    if(row.done == true){extraClass = 'bg-green-500'}
    return (
      <tr key={`${row.id}_${index}`}>
        <td className={extraClass} key={`${row.containerId}`}>{row.containerId}</td>
        <td className={extraClass}>{row.quantity_needed}</td>
        <td className={extraClass}>{row.quantity_scanned}</td>
      </tr>
    );
  };
  const data = productData ? productData : [];
  return (
    <table id="product-table" className="table-fixed w-full">
      <thead className="bg-gray-200 font-bold">
        <tr>
          <td key={"container_id"}>Container ID</td>
          <td key={"soll"}>Soll</td>
          <td key={"ist"}>Ist</td>
        </tr>
      </thead>
      <tbody>
        {data?.containers?.map((row: any, index: any) => {
          return getRow(row, index, currentContainerId);
        })}
      </tbody>
    </table>
  );
};

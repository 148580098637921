import React from "react";
import { classNames } from '../../../shared/utils/classNames';

interface ProductInfoProps {
  product: any
}

export const ProductInfo = ({product}: ProductInfoProps) => {
  return (
    <div className={classNames("relative flex flex-col pt-4 items-center font-bold justify-center w-full text-center")}>
      <h2 className='text-xl'>{product.productName}</h2>
      <h2 className='text-xl'>{product.storageLocation}</h2>
    </div>
  );
};

import React, { FC } from 'react';

import { classNames } from '../utils/classNames';
import { COMMON_MESSAGES } from '../utils/commonMessages';

interface LogoutButtonProp extends React.HTMLProps<HTMLButtonElement>{
    className?: string
}

export const LogoutButton:FC<LogoutButtonProp> = ({ type, className ='rounded-md', ...props }) => {
    return (
        <button
            type="button"
            className={classNames( className, "h-10 border border-gray-800 text-gray-800 w-full max-w-xs py-2 px-5" )}
            {...props}
        >
            {COMMON_MESSAGES.logout}
        </button>
    );
}

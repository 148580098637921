import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { SearchBar } from "../components/SearchBar";
import {
  isB2B,
  isPick,
  isRgbWarehouse,
  urlForVersion,
} from "../../shared/utils/common";
import { InnerHeader } from "../components/InnerHeader";
import {
  getPickingContainer,
  getContainerStatus,
} from "../../shared/network/lib/picking";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { FormikState } from "formik";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { isDirectPrint } from "../../shared/utils/isDirectPrint";
import { getKpis } from "../../shared/network/lib/getKpis";

export const ScanPackagePage = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { toggleSpinner } = useSpinner();
  const [showOrderInContainer, setShowOrderInContainer] = useState(false);
  const [containerId, setcontainerId] = useState("");
  const [packingKpis, setPackingKpis] = useState({
    amount: 0,
    minutes_worked: 0,
    minutes_per_order: 0,
  });
  const [kpisRetrieved, setKpisRetrieved] = useState(false);

  const handleSearch = async (
    value: string,
    resetValue: (
      nextState?: Partial<FormikState<{ packagecode: string }>> | undefined
    ) => void
  ) => {
    let url;
    setShowOrderInContainer(false);
    if (isDirectPrint() && !isPick()) {
      url = "/confirmation";
      try {
        let payload = await getContainerContentWithoutNavigate(value);
        if (!payload) {
          return
        }
        navigate(urlForVersion(url), {
          state: { shipmentPayload: payload, barcode: value },
        });
      } catch (error: any) {
        console.log(error?.response);
        if (error.response.status === 422) {
          setErr(true);
          setErrMsg(error?.response?.data?.message);
          toggleSpinner({ showSpinner: false });
        } else if (error.response.status === 404) {
          navigate(urlForVersion(url));
        }
      }
    } else {
      setErrMsg("");
      const param = `?barcode=${value}`;
      url = isPick() ? `/picking${param}` : `/check-view${param}`;
      toggleSpinner({ showSpinner: true });
      if (isPick()) {
        try {
          const response = await getContainerStatus(value);
          if (response.status === 200) {
            navigate(urlForVersion(url));
          }
        } catch (error: any) {
          if (error.response.status === 404) {
            setErr(true);
            await setErrMsg(error?.response?.data?.message);
            toggleSpinner({ showSpinner: false });
          } else if (error.response.status === 422) {
            setErr(true);
            await setErrMsg(error?.response?.data?.message);

            setShowOrderInContainer(true);
            setcontainerId(value);

            toggleSpinner({ showSpinner: false });
          } else if (error.response.status === 404) {
            navigate(urlForVersion(url));
          }
        }
      } else {
        await getContainerContent(value, url);
      }
    }
    resetValue({ values: { packagecode: "" } });
  };

  const getContainerContentWithoutNavigate = async (containerId: string) => {
    while (true) {
      try {
        const response = await getPickingContainer(containerId);
        if (response.status === 200) {
          return response?.data;
        }
      } catch (error: any) {
        if (error?.response.status === 404) {
          const message = ERROR_MESSAGES.packingNotFound.replace(
            "{code}",
            containerId
          );
          setErr(true);
          setErrMsg(error?.response?.data?.message || message);
        } else {
          setErr(true);
          setErrMsg(
            error?.response?.data?.message ||
              ERROR_MESSAGES.noPickingDataAvailableError
          );
        }
        toggleSpinner({ showSpinner: false });
      }
      return false;
    }
  };

  const navigateToContainerView = async () => {
    navigate(urlForVersion(`/reset-container?containerid=${containerId}`));
  };

  const getContainerContent = async (containerId: string, url: string) => {
    while (true) {
      try {
        const response = await getPickingContainer(containerId);
        if (response.status === 200) {
          navigate(urlForVersion(url));
        }
      } catch (error: any) {
        if (error?.response.status === 404) {
          const message = ERROR_MESSAGES.packingNotFound.replace(
            "{code}",
            containerId
          );
          setErr(true);
          setErrMsg(error?.response?.data?.message || message);
        } else {
          setErr(true);
          setErrMsg(
            error?.response?.data?.message ||
              ERROR_MESSAGES.noPickingDataAvailableError
          );
        }
        toggleSpinner({ showSpinner: false });
      }
      break;
    }
  };

  const handleGetPickingKpis = async () => {
    try {
      const isPicking = isPick()
      let response = await getKpis(isPicking);
      if (response.status === 200) {
        console.log(response);
        setPackingKpis(response?.data);
        setKpisRetrieved(true);
        toggleSpinner({ showSpinner: false });
      }
    } catch (error: any) {
      console.log(error);
      console.log(error?.response?.status);
    }
  };

  useEffect(() => {
    if (isRgbWarehouse()) {
      handleGetPickingKpis();
    }
  }, []);

  return (
    <div className="bg-app min-h-screen">
      <div className="max-w-screen-md min-h-screen mx-auto p-4 flex flex-col justify-between">
        <div className="mt-4 w-full flex flex-col items-center">
          <InnerHeader
            title={
              isB2B()
                ? COMMON_MESSAGES.scanB2BPickPageTitle
                : COMMON_MESSAGES.scanPickPageTitle
            }
            showBackButton={true}
            urlBackButton="/start"
          />
          <div className="mt-8 w-full ml-2 flex flex-col">
            {kpisRetrieved && Object.keys(packingKpis).length > 0 && (
              <>
                <div className="flex justify-center gap-1">
                  <p>Aufträge gepackt heute:</p>
                  <p className="font-bold">{packingKpis?.amount}</p>
                </div>
                <div className="flex justify-center gap-1">
                  <p>Minuten pro Auftrag:</p>
                  <p className="font-bold">{packingKpis?.minutes_per_order}</p>
                </div>
              </>
            )}
          </div>
          <SearchBar onClick={handleSearch} />
          <div>{err && <ErrorBar message={errMsg} />}</div>
          <div className="mt-12 w-full text-center">
            {showOrderInContainer && (
              <PrimaryButton
                type="button"
                label={COMMON_MESSAGES.viewOrderInContainer}
                onClick={navigateToContainerView}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

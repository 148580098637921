import React,{ChangeEvent} from 'react';

interface DropdownProps {
  options: string[];
  onChange: (selectedOption: string) => void;
  selectedOption: string; // New prop for accessing the selected option from outside
  placeholder: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onChange, selectedOption, placeholder }) => {
  const handleOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newOption = event.target.value;
    onChange(newOption);
  };

  return (
    <select
      value={selectedOption}
      onChange={handleOptionChange}
      className="bg-gray-800 text-white px-4 py-2 rounded-md"
    >
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;

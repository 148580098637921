import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserRole, isPick, isRgbWarehouse, urlForVersion } from "../../shared/utils/common";
import tableConfig from "../../shared/config/tableConfig";
import { ScanProductsTable } from "../components/ScanProductsTable";
import { InfoBar } from "../../v1/components/InfoBar";
import {
  getPickingContainer,
  getPickingDetails,
} from "../../shared/network/lib/picking";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { ProcessedData, RowCells } from "../../shared/types/data";
import { InnerHeader } from "../components/InnerHeader";
import {
  PickingDataResponse,
  ShipmentPositionDataResponse,
} from "../../shared/types/picking";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { isVersandOpen } from "../../shared/network/lib/isVersandOpen";
import { WarningBar } from "../../v1/components/WarningBar";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { ProductTable } from "../components/ProductTable";
import { WarningButton } from "../../shared/components/WarningButton";
import { PopupDialog } from "../components/PopupDialog";
import { resetContainer } from "../../shared/network/lib/resetContainer";
import checkStoredShipmentPosition from "../../shared/utils/checkStoredShipmentPosition";
import { getPackageRecommendation } from "../../shared/network/lib/getPackageRecommendation";

export const formatShippingPositionFromAPI = (
  data: ShipmentPositionDataResponse[],
  columnsToShow: string[] = [
    "product_name",
    "storage_location",
    "quantity",
    "ist",
  ]
) => {
  let preppedData: ProcessedData[] = [];
  for (const item of data) {
    let rowCells: RowCells[] = [];
    for (const itemTable of tableConfig) {
      if (columnsToShow.includes(itemTable.column)) {
        const value = itemTable.column === "ist" ? 0 : item[itemTable.column];
        let columnStyles = "";
        let rowStyles = "";
        if (itemTable.column === "quantity" || itemTable.column === "ist") {
          columnStyles = "w-12";
          rowStyles = "w-12";
        }
        rowCells.push({
          columnName: itemTable.column,
          columnHeader: itemTable.columnHeader,
          value,
          columnStyles,
          rowStyles,
        });
      }
    }
    let amountNeeded = item.quantity;
    let amountScanned = 0;
    preppedData.push({
      id: item.sku,
      ean: item.ean,
      key: item.sku,
      done: amountNeeded === amountScanned,
      amountNeeded,
      amountScanned,
      cells: rowCells,
    });
  }
  return preppedData;
};

export const ScanProductsPage = () => {
  const [searchParams] = useSearchParams();
  const [shipmentPosition, setShipmentPosition] = useState<ProcessedData[]>([]);
  const [error, setError] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [warning, setWarning] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [packageRecommendation, setPackageRecommendation] = useState("");
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [info, setInfo] = useState<Boolean>(false);
  const [fullShipmentPayload, setFullShipmentPayload] =
    useState<PickingDataResponse>();
  const [deliveryNumber, setDeliveryNumber] = useState<string>();
  const { toggleSpinner } = useSpinner();
  const navigate = useNavigate();
  const barcode = searchParams.get("barcode");
  const [showDialog, setShowDialog] = useState(false);
  const [scanFinished, setScanFinished] = useState(false);

  async function fetchData(): Promise<PickingDataResponse | null> {
    try {
      toggleSpinner({ showSpinner: true });
      let response;
      if (isPick()) {
        response = await getPickingDetails({ picking_validation: false });
      } else {
        response = await getPickingContainer(barcode as string);
      }
      toggleSpinner({ showSpinner: false });
      if (response.status === 200) {
        setError(false);
        setInfo(false);
        return response?.data;
      }
    } catch (error: any) {
      setError(true);
      setErrMsg(ERROR_MESSAGES.noPickingDataFoundError);
      toggleSpinner({ showSpinner: false });
      if (error?.response.status === 422) {
        setError(true);
        setErrMsg(ERROR_MESSAGES.pickingPreValidationError);
        disputeSubmit();
      }
      if (error?.response.status === 423) {
        console.log(error?.response?.data);
        setError(true);
        setErrMsg(ERROR_MESSAGES.processedLieferscheinError);
        disputeSubmit();
      }
      if (error?.response.status === 424) {
        console.log(error?.response?.data);
        setError(true);
        setErrMsg("Stammdaten Error");
        setInfo(true);
        setInfoMsg(ERROR_MESSAGES.productDataError);
      } else if (error?.response.status === 404) {
        setError(true);
        setErrMsg(ERROR_MESSAGES.noPickingDataFoundError);
      }
    }

    return null;
  }

  const disputeSubmit = () => {
    setInfoMsg(INFO_MESSAGES.disputeSuccess);
    setInfo(true);
  };

  const handleResetContainer = () => {
    toggleSpinner({ showSpinner: true });
    resetContainer(barcode).then((response) => {
      if (response.status === 200) {
        setError(true);
        setShowTable(false);
        setErrMsg(
          "CONTAINER WAS RESET DUE TO A PROBLEM. PLEASE EMPTY CONTAINER AND PUT BACK."
        );
        toggleSpinner({ showSpinner: false });
      }
    });
  };

  const checkVersandStatus = async (versand_id: any) => {
    try {
      const response: any = await isVersandOpen(versand_id);
      if (response.status === 200) {
        return;
      } else if (response.status === 202) {
        setWarning(true);
        setWarningMsg(ERROR_MESSAGES.shippingAlreadyProcessed);
        if (!isPick()) {
          handleResetContainer();
        } else if (isPick()) {
          setTimeout(() => {
            navigate(0);
          }, 1500);
        }
        return;
      }
      return false;
    } catch (error: any) {
      console.log(error);
      console.log("Could not verify Shipment status");
      setWarning(true);
      setWarningMsg("Could not verify shipment status");
      return;
    }
  };

  const compareAndChooseShipmentPosition = (versandIdAPI: any, positionFromAPI: any, positionFromStorage: any) => {
    if (versandIdAPI === positionFromStorage?.versand_id && positionFromStorage?.role == getUserRole()) {
      return positionFromStorage.position
    }
    else {
      return positionFromAPI
    }

  }

  const prepareData = async () => {
    let data: any;
    try {
      data = await fetchData();
      let validated = true;
      setDeliveryNumber(data.shipment_head.lieferschein);
      let chosenPosition = formatShippingPositionFromAPI(data.shipment_position)
      const shipmentPositionFromStorage = checkStoredShipmentPosition()
      if (shipmentPositionFromStorage) {chosenPosition = compareAndChooseShipmentPosition(data?.versand_id, chosenPosition, shipmentPositionFromStorage)}
      if (validated) {
        setShipmentPosition(chosenPosition);
        setFullShipmentPayload(data);
        checkVersandStatus(data.versand_id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetPackageRecommendation = () => {
    getPackageRecommendation(fullShipmentPayload?.versand_id).then((response) => {
      if(response?.data.length > 0){
        setPackageRecommendation(response?.data)
      }
    });

  }

  const handleToScan = () => {
    navigate(0);
  };

  const openClarification = () => {
    navigate(
      urlForVersion(
        "/clarification/?versand_id=" + fullShipmentPayload?.versand_id
      )
    );
  };

  useEffect(() => {
    if (barcode) {
      prepareData();
    } else {
      navigate(urlForVersion("/scan"));
    }
  }, [barcode]);

  useEffect(() => {
    if(isRgbWarehouse() && fullShipmentPayload){
      handleGetPackageRecommendation()
    }
  }, [fullShipmentPayload]);

  const checkScanFinished = (finished: boolean) => {
    setScanFinished(finished);
  };

  console.log(fullShipmentPayload);
  return (
    <div className="bg-app min-h-screen">
      <div className="max-w-screen-md min-h-screen mx-auto py-8 px-2 flex flex-col justify-center items-center">
        <InnerHeader
          title={
            scanFinished
              ? COMMON_MESSAGES.jobDone
              : isPick()
              ? COMMON_MESSAGES.pickingPage
              : COMMON_MESSAGES.packingPage
          }
          showBackButton={!scanFinished}
          urlBackButton="/scan"
        />
        <div className="mt-4 text-center">
          <p>Container: {barcode}</p>
          <p>LS: {deliveryNumber}</p>
          {isRgbWarehouse() && !isPick() && packageRecommendation && <div className="bg-green-600 rounded rounded-md mt-2 py-1"><p className="text-white font-bold">{packageRecommendation}</p></div>}
        </div>

        {error && <div className="divider"></div>}
        {error && <ErrorBar message={errMsg} />}
        {warning && <WarningBar message={warningMsg} />}
        {info && <InfoBar message={infoMsg} />}
        {error && (
          <button className="btn btn-outline mt-2" onClick={handleToScan}>
            Zum nächsten Auftrag
          </button>
        )}
        {error && <div className="divider"></div>}
        {showTable && !!shipmentPosition.length && fullShipmentPayload && (
          <div className="custom-table">
            <ScanProductsTable
              processed={shipmentPosition}
              fullPayload={fullShipmentPayload}
              checkScanFinished={checkScanFinished}
              containerId={barcode ?? ""}
            />
          </div>
        )}
        <div className="grow min-h-8"></div>

        {!scanFinished && (
          <div className="w-full px-7 flex flex-col justify-center items-center gap-4">
            <div className="w-60">
              <PrimaryButton
                type="button"
                label={COMMON_MESSAGES.showCompleteOrder}
                onClick={() => setShowDialog(true)}
              />
            </div>
            <div className="w-36">
              <WarningButton
                name="klarfall-btn"
                placeholder={COMMON_MESSAGES.clarificationBtn}
                onchange={openClarification}
              />
            </div>
          </div>
        )}
      </div>
      <PopupDialog
        showDialog={showDialog}
        title={COMMON_MESSAGES.completeOrder}
        hideDialog={() => setShowDialog(false)}
      >
        <ProductTable shipmentPosition={shipmentPosition} />
        <PrimaryButton
          className="mt-10 rounded w-24"
          type="button"
          label={COMMON_MESSAGES.back}
          onClick={() => setShowDialog(false)}
        />
      </PopupDialog>
    </div>
  );
};

import React from "react";
import "./App.css";
import { StartPage } from "./v1/pages/StartPage";
import { PickingPage } from "./v1/pages/PickingPage";
import { LoginPage } from "./v1/pages/LoginPage";
import { SizeTestPage } from "./v1/pages/SizeTestPage";
import { LoginB2B } from "./v1/components/Loginb2b";
import { ShippingPage } from "./v1/pages/ShippingPage";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./v1/components/RequireAuth";

import { AdminPage } from "./shared/pages/AdminPage";
import { StartPage as StartPageV2 } from "./v2/pages/StartPage";
import { LoginNoRole } from "./v1/components/LoginNoRole";
import { ScanProductsPage } from "./v2/pages/ScanProductsPage";
import { ScanPackagePage } from "./v2/pages/ScanPackage";
import { ClarificationPage } from "./v2/pages/ClarificationPage";
import { ConfirmationPage } from "./v2/pages/ConfirmationPage";
import { QaCheckPage } from "./v2/pages/QaCheckPage";
import { ResetContainerPage } from "./v2/pages/ResetContainerPage";
import { ContainerSelectionPage } from "./v2/pages/MOP/rgb/RgbContainerSelectionPage";
import { MultiOrderPickingPage } from "./v2/pages/MOP/rgb/RgbMultiOrderPickingPage";
import { KakiContainerSelectionPage } from "./v2/pages/MOP/kaki/KaKiContainerSelectionPage";
import { KaKiMultiOrderPickingPage } from "./v2/pages/MOP/kaki/KaKiMultiOrderPickingPage";
import { KakiMopStartPage } from "./v2/pages/MOP/kaki/KaKiMopStartPage";
import { RanpakPrintPage } from "./v2/pages/RanpakPrintPage";
import { SessionWarningOverlay } from "./v1/components/SessionWarningOverlay";
import { DeprecationOverlay } from "./v1/components/DeprecationOverlay";
import { StartPageDummy } from "./v1/pages/StartPageDummy";
import { PickingPageDummy } from "./v1/pages/PickingPageDummy";

export default function App() {
  return (
    <>
      <DeprecationOverlay />
      <SessionWarningOverlay />
      <Routes>
        {/* redeploy */}
        <Route element={<LoginPage />} path="/login" />
        <Route element={<SizeTestPage />} path="/sizetest" />
        <Route element={<LoginB2B />} path="/b2b" />
        <Route element={<LoginNoRole />} path="/v1" />
        <Route element={<LoginPage />} path="/" />

        {/* Protected Routes */}
        <Route element={<RequireAuth />}>
          {/* Admin */}
          <Route element={<AdminPage />} path="/admin" />

          <Route element={<StartPage />} path="/v1/start" />
          <Route element={<PickingPage />} path="/v1/picking" />
          <Route element={<ShippingPage />} path="/v1/shipping" />

          {/* Dummy */}
          <Route element={<StartPageDummy />} path="/v1/start-dmy" />
          <Route element={<PickingPageDummy />} path="/v1/picking-dmy" />

          {/* V2 */}
          <Route element={<StartPageV2 />} path="/v2/start" />
          <Route element={<ScanProductsPage />} path="/v2/picking" />
          <Route element={<ScanProductsPage />} path="/v2/check-view" />
          <Route element={<ScanPackagePage />} path="/v2/scan" />
          <Route element={<ClarificationPage />} path="/v2/clarification" />
          <Route element={<ConfirmationPage />} path="/v2/confirmation" />
          <Route element={<ResetContainerPage />} path="/v2/reset-container" />
          <Route element={<QaCheckPage />} path="/v2/qa" />

          {/* Multi-Order Picking */}
          {/* RGB */}
          <Route
            element={<ContainerSelectionPage />}
            path="/v2/mop/container_selection"
          />
          <Route element={<MultiOrderPickingPage />} path="/v2/mop/picking" />

          {/* KAKI */}
          <Route element={<KakiMopStartPage />} path="/v2/mop/kaki/start" />
          <Route
            element={<KakiContainerSelectionPage />}
            path="/v2/mop/kaki/container_selection"
          />
          <Route
            element={<KaKiMultiOrderPickingPage />}
            path="/v2/mop/kaki/picking"
          />

          {/* Ranpak only */}
          <Route
            element={<RanpakPrintPage />}
            path="/v2/ranpak/kaki/manual_label_print"
          />
        </Route>
      </Routes>
    </>
  );
}

function checkStoredShipmentPosition(): any {
    const valueString = localStorage.getItem('storedShipmentPosition');
    
    if (valueString) {
      try {
        const value = JSON.parse(valueString);
        // console.log(value)
        return value;
      } catch (error) {
        console.error('Error parsing stored value:', error);
        return null;
      }
    }
    
    return null;
  }
  
  export default checkStoredShipmentPosition
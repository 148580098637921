import React, { useEffect } from 'react';

import { useSpinner } from '../context/SpinnerProvider';

export const GlobalSpinner = () => {
  const { spinnerVisible, modalSpinner, textModalSpinner, successIconModalSpinner } = useSpinner();

  useEffect(() => {
    if (spinnerVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [spinnerVisible]);

  if (!spinnerVisible) return null;

  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-white/90">
      {modalSpinner ? (
        <div className='w-3/4 max-w-sm flex flex-col justify-center items-center gap-5 p-6 bg-white rounded-2xl drop-shadow-md'>
            <span className='text-1xl'>{textModalSpinner}</span>
            {successIconModalSpinner ? (
                <svg
                    width="100%"
                    height="100%"
                    className='h-10 w-10 text-secondary-900'
                    viewBox="0 0 24 24"
                    fill="#000"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M23.146 5.4l-2.792-2.8a.5.5 0 00-.708 0L7.854 14.4a.5.5 0 01-.708 0l-2.792-2.8a.5.5 0 00-.708 0L.854 14.4a.5.5 0 000 .707L7.146 21.4a.5.5 0 00.708 0L23.146 6.1a.5.5 0 000-.7z" />
                </svg>
            ) : (
                <div className='spinner' />
            )}
        </div>
      ) : (
        <div className='spinner' />
      )}
      
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useSessionContext } from "../../shared/context/SessionContext";
import { useLocation, useNavigate } from "react-router-dom";
import { InnerHeader } from "../../v2/components/InnerHeader";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { userLogout } from "../../shared/network/lib/userLogout";
import { ErrorBar } from "./ErrorBar";
import { CountdownButton } from "../../shared/components/CountdownButton";
import { createNewUserSession } from "../../shared/network/lib/createNewUserSession";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../v2/utils/localStorageUtils";
import { isRgbWarehouse } from "../../shared/utils/common";

export const SessionWarningOverlay = () => {
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isTerminated, setIsTerminated] = useState(false);
  const location = useLocation();

  const { isActive, userInfo } = useSessionContext();
  const navigate = useNavigate();

  const [cameFromLogin, setCameFromLogin] = useState<boolean>(false);

  const loginPaths = ["/login", "/"];

  const isRgb = isRgbWarehouse()

  useEffect(() => {
    const checkLoginInURL = () => {
      setIsLoginPage(window.location.href.includes("login"));
    };

    checkLoginInURL();

    const handlePopState = () => checkLoginInURL();
    window.addEventListener("popstate", handlePopState);

    return () => window.removeEventListener("popstate", handlePopState);
  }, [location.pathname]);

  useEffect(() => {
    const storedPreviousPathname =
      getLocalStorageItem<string>("previousPathname");
    const initialPathname = getLocalStorageItem<string>("initialPathname");

    if (
      storedPreviousPathname !== window.location.pathname &&
      !loginPaths.includes(window.location.pathname)
    ) {
      setLocalStorageItem<string>(
        "initialPathname",
        storedPreviousPathname || ""
      );
    } else {
      console.log("Refreshed page");
    }
    setLocalStorageItem<string>("previousPathname", window.location.pathname);
    if (
      loginPaths.includes(initialPathname ?? "") ||
      loginPaths.includes(storedPreviousPathname ?? "")
    ) {
      setCameFromLogin((prevLoginState) => true || prevLoginState);
    } else {
      setCameFromLogin(false);
    }
  }, [window.location.pathname]);

  const handleLogout = async () => {
    setIsTerminated(false);
    try {
      await userLogout().then((response) => {
        let url = "/login";
        if (response.status === 200) {
          navigate(url);
        }
      });
    } catch (error) {
      console.log("Error");
    }
  };

  const handleCreateNewUserSession = async () => {
    try {
      await createNewUserSession().then((response) => {
        if (response.status === 200) {
          setIsVisible(false);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (userInfo?.should_be_terminated == true) {
      setIsTerminated(true);
      const timeoutId = setTimeout(handleLogout, 7000);
      return () => clearTimeout(timeoutId);
    }
    return () => setIsTerminated(false);
  }, [userInfo]);

  return isRgb && isTerminated ? (
    <div className="absolute top-0 left-0 z-50 w-full">
      <div className="bg-app flex flex-col items-center rounded h-[100vh]">
        <div className="my-20">
          <ErrorBar message="You were logged out by a key user! Logging out in 10 seconds..." />
        </div>
      </div>
    </div>
  ) : isRgb && isVisible && !isActive && !isLoginPage ? (
    <>
      {cameFromLogin ? (
        <div className="absolute top-0 left-0 z-50 w-full">
          <div className="bg-app flex flex-col items-center rounded h-[100vh]">
            <InnerHeader title="Duplicate Session" showBackButton={false} />
            <div className="my-5">
              <ErrorBar message="There is a session for the same user on another device!" />
            </div>
            <div className="my-6">
              <p className="inline">User: </p>
              <p className="inline font-bold">{userInfo?.user_name}</p>
            </div>
            <div className="w-[80vw] flex flex-col gap-4">
              <PrimaryButton
                type="button"
                label="LOG OUT"
                onClick={handleLogout}
              />
              <CountdownButton
                type="button"
                label="CONTINUE"
                onClick={handleCreateNewUserSession}
              />
            </div>
          </div>
        </div>
      ) : (
        isRgb && isVisible && (
          <div
            className="absolute top-0 left-0 z-50 alert alert-warning shadow-lg mt-4 w-full"
            onClick={() => setIsVisible(false)}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>There is another device with an active session!</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        )
      )}
    </>
  ) : null;
};

import React from "react";
import ReactLoading from "react-loading";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import { startShipping } from "../../shared/network/lib/shipping";
import { useLocation, useNavigate } from "react-router-dom";
import { urlForVersion } from "../../shared/utils/common";
import { ErrorBar } from "../components/ErrorBar";
import { InfoBar } from "../components/InfoBar";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
import { isB2B } from "../../shared/utils/common";
import { printLieferschein } from "../../shared/network/lib/printLieferschein";

export const ShippingPage = () => {
  let [loading, setLoading] = useState(true);
  let [labelLoading, setLabelLoading] = useState(false);
  let [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showReprint, setShowReprint] = useState(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [disputeSuccess, setDisputeSuccess] = useState(false);
  const [info, setInfo] = useState<Boolean>(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { shipmentPayload } = state;

  const handlePrintLieferschein = async () => {
    try {
      console.log(shipmentPayload?.shipment_head?.lieferschein_id);
      const response = await printLieferschein(
        shipmentPayload?.shipment_head?.lieferschein_id
      );
      if (response.status === 201) {
        setInfo(true);
        setInfoMsg(INFO_MESSAGES.packProcessFinished);
        return true;
      } else if (response.status === 202) {
        setError(true);
        setErrMsg(ERROR_MESSAGES.shippingAlreadyProcessedNoPrint);
      } else if (response.status >= 400) {
        setError(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        );
      }
      return false;
    } catch (error: any) {
      console.log(error);
      setError(true);
      setErrMsg(
        error?.data?.message?.error_message ||
          ERROR_MESSAGES.generalShippingError
      );
      handleDisputeSubmit();
      return false;
    }}

  const postShipment = async () => {
    let response;
    setLabelLoading(true);
    try {
      if (isB2B()) {
        await handlePrintLieferschein();
        response = await startShipping(shipmentPayload, true, false);
      } else {
        response = await startShipping(shipmentPayload, true, true);
      }
      if (response.status === 200 || response.status === 201) {
        setChecked(true);
        setLoading(false);
        if (!isB2B()) {
          setTimeout(() => {
            navigate(urlForVersion("/picking"));
          }, 2000);
        }
      } else if (response.status === 203) {
        setLoading(false);
        setError(true);
        setErrMsg(ERROR_MESSAGES.shippingSafetyError);
        setInfo(true);
        setInfoMsg(
          `LS: ${shipmentPayload?.shipment_head?.lieferschein} || Auftrag: ${shipmentPayload?.shipment_head?.auftrag}`
        );
      } else if (response.status >= 400) {
        setError(true);
        setInfo(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        );
        setInfoMsg(INFO_MESSAGES.disputeCreationInfo);
        handleDisputeSubmit();
      }
    } catch (error: any) {
      console.log(error);
      setError(true);
      setInfo(true);
      setErrMsg(
        error?.response?.data?.message?.error_message ||
          ERROR_MESSAGES.generalShippingError
      );
      setInfoMsg(INFO_MESSAGES.disputeCreationInfo);
      handleDisputeSubmit();
    } finally{
      setLabelLoading(false);
    }
  };

  const createAdditionalLabel = async () => {
    setLabelLoading(true);
    try {
      let response = await startShipping(shipmentPayload);
      if (response.status === 200 || response.status === 201) {
        setChecked(true);
        setLoading(false);
        if (!isB2B()) {
          setTimeout(() => {
            navigate(urlForVersion("/picking"));
          }, 2000);
        }
      } else if (response.status >= 400) {
        setError(true);
        setInfo(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        );
        setInfoMsg(INFO_MESSAGES.disputeCreationInfo);
        handleDisputeSubmit();
      }
    } catch (error: any) {
      console.log(error);
      setError(true);
      setInfo(true);
      setErrMsg(
        error?.response?.data?.message?.error_message ||
          ERROR_MESSAGES.generalShippingError
      );
      setInfoMsg(INFO_MESSAGES.disputeCreationInfo);
      handleDisputeSubmit();
    }finally{
      setLabelLoading(false);
    }
  };

  const handleNavigateToPicking = () => {
    navigate(urlForVersion("/picking"));
  };

  const handleDisputeSubmit = () => {
    setInfo(true);
    setInfoMsg(INFO_MESSAGES.disputeSuccess);
    setDisputeSuccess(true);
  };

  useEffect(() => {
    postShipment();
    setTimeout(() => setShowReprint(true), 2500);
  }, []);
  return (
    <>
      <p className="text-2xl font-bold my-4 text-center">Shipping Page</p>
      { labelLoading ? (
        <div className="flex justify-center flex-col items-center h-[50vh]">
          <ReactLoading
                type="spinningBubbles"
                color="#0000FF"
                height={100}
                width={50}
            />
        </div>
      ):
      (
        <div className="flex justify-center flex-col items-center h-[50vh]">
        <div>
          <div className="flex items-center gap-2">
            <label className="label cursor-pointer">
              <input
                type="checkbox"
                checked={checked}
                className="checkbox checkbox-primary"
              />
            </label>
            {isB2B() ? (
              <span className="label-text">B2B-2 Auftrag Verarbeitet</span>
            ) : (
              <span className="label-text">Label Erstellt</span>
            )}
          </div>
          <div className="mt-8">
            <BarLoader
              color={"#498fe2"}
              loading={loading}
              height={8}
              width={200}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
        {showReprint && (
          <button
            className="btn btn-outline mt-4"
            onClick={createAdditionalLabel}
          >
            Erneut drucken
          </button>
        )}
        {error && <ErrorBar message={errMsg} />}
        {info && <InfoBar message={infoMsg} />}
        {disputeSuccess && <InfoBar message={INFO_MESSAGES.disputeSuccess} />}
        {isB2B() && (
          <button
            className="btn btn-outline mt-4"
            onClick={handleNavigateToPicking}
          >
            Nächster Auftrag
          </button>
        )}
        {disputeSuccess && (
          <button
            className="btn btn-outline mt-4"
            onClick={handleNavigateToPicking}
          >
            Nächster Auftrag
          </button>
        )}
      </div>
      )}
      
    </>
  );
};

import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { checkAuth } from '../network/lib/auth';

type SessionContextType = {
  isActive: boolean;
  userInfo: any;
  setIsActive: (isActive: boolean) => void;
  setUserInfo: (isActive: boolean) => void;
};
export const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isActive, setIsActive] = useState<SessionContextType['isActive']>(true);
  const [userInfo, setUserInfo] = useState<SessionContextType['userInfo']>();
  const location = useLocation();

  const fetchData = async () => {
    let response = await checkAuth()
        .then((response: { status: number; data: any; }) => {
        if (response.status === 200) {
          setIsActive(response?.data?.is_active_session ? true: false)
          setUserInfo(response?.data)
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    return response;
  };

  useEffect(() => {
    const handleUrlChange = () => {
      if (!window.location.href.includes('login')) {
        fetchData();
      }
    };

    const intervalId = setInterval(() => {
      if (!window.location.href.includes('login')) {
        fetchData();
      }
    }, 20000);

    // Perform initial fetch only if the URL does not contain 'login'
    if (!window.location.href.includes('login')) {
      fetchData();
    }

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [location.pathname]);


  return (
    <SessionContext.Provider value={{ isActive, setIsActive, userInfo, setUserInfo }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSessionContext must be used inside the SessionContextProvider');
  }

  return context;
};

import React from "react";
import { useState } from "react";

type SliderProps = {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
};

const Slider = ({ value, min, max, step, onChange }: SliderProps) => {
  const [inputValue, setInputValue] = useState(value.toString());

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    onChange(newValue);
    setInputValue(newValue.toString());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    const newValue = parseInt(inputValue);
    if (!isNaN(newValue)) {
      onChange(newValue);
      setInputValue(newValue.toString());
    } else {
      setInputValue(value.toString());
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <input
        type="range"
        className="w-full"
        value={value}
        onChange={handleSliderChange}
        min={min}
        max={max}
        step={step}
      />
      <input
        readOnly
        type="number"
        className="w-1/3 p-1 border border-gray-400 rounded"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        min={min}
        max={max}
        step={step}
      />
    </div>
  );
};

export default Slider;

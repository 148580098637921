import React, { useEffect, useState } from "react";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { useLocation } from "react-router-dom";
import { InnerHeader } from "../components/InnerHeader";
import {
  isB2B,
  isRanpakPack,
  isRgbWarehouse,
  urlForVersion,
} from "../../shared/utils/common";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { InfoBar } from "../../v1/components/InfoBar";
import { reprintVersand } from "../../shared/network/lib/reprintVersand";
import {
  generateAndStoreZplLabelForDynamicContainer,
  startShipping,
} from "../../shared/network/lib/shipping";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { WarningBar } from "../../v1/components/WarningBar";
import {
  postPickingContainerLoad,
  postPickingContainerUnload,
} from "../../shared/network/lib/picking";
import { isPick } from "../../shared/utils/common";
import { printLieferschein } from "../../shared/network/lib/printLieferschein";
import { useNavigate } from "react-router-dom";
import { resetContainer } from "../../shared/network/lib/resetContainer";

export const ConfirmationPage = () => {
  const { toggleSpinner } = useSpinner();
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [reprintMsg, setReprintMsg] = useState<string>("");
  const [showReprintAlert, setShowReprintAlert] = useState<Boolean>(false);
  const { state } = useLocation();
  const { shipmentPayload, barcode } = state;
  const [reprintWarning, setReprintWarning] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [disputeSuccess, setDisputeSuccess] = useState(false);
  const [info, setInfo] = useState<Boolean>(false);

  const navigate = useNavigate();

  const handleUnload = async () => {
    let response;
    let unloadStatus;
    try {
      if (isPick()) {
        response = await postPickingContainerLoad({
          container_id: barcode as string,
          picker_id: shipmentPayload.user_id,
          versand_id: shipmentPayload.versand_id,
        });
      } else {
        response = await postPickingContainerUnload({
          container_id: barcode as string,
          packer_id: shipmentPayload.user_id,
          versand_id: shipmentPayload.versand_id,
        });
        unloadStatus = response.status;
      }
    } catch (error: any) {
      unloadStatus = error?.response?.status;
      setError(true);
      setErrMsg(
        ERROR_MESSAGES.containerUnloadingError.replace("{code}", barcode)
      );
    }
    return unloadStatus;
  };

  const handlePrintLieferschein = async () => {
    try {
      toggleSpinner({ showSpinner: true });
      console.log(shipmentPayload?.shipment_head?.lieferschein_id);
      const response = await printLieferschein(
        shipmentPayload?.shipment_head?.lieferschein_id
      );
      if (response.status === 201 || response.status === 200) {
        setInfo(true);
        setInfoMsg(INFO_MESSAGES.packProcessFinished);
        toggleSpinner({ showSpinner: false });
        return true;
      } else if (response.status === 202) {
        await handleUnload();
        setWarning(true);
        setReprintWarning(true);
        setWarningMsg(ERROR_MESSAGES.shippingAlreadyProcessedNoPrint);
        toggleSpinner({ showSpinner: false });
      } else if (response.status >= 400) {
        setError(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        );
      }
      return false;
    } catch (error: any) {
      console.log(error);
      setError(true);
      setErrMsg(
        error?.data?.message?.error_message ||
          ERROR_MESSAGES.generalShippingError
      );
      handleDisputeSubmit();
      return false;
    }
  };

  // redeploy
  const postShipment = async () => {
    let response;
    try {
      toggleSpinner({ showSpinner: true });
      if (isB2B()) {
        await handlePrintLieferschein();
        response = await startShipping(shipmentPayload, true, false);
      } else if (isRanpakPack()) {
        response = await generateAndStoreZplLabelForDynamicContainer(barcode);
      } else {
        response = await startShipping(shipmentPayload, true);
      }
      if (response.status === 201) {
        if (isRanpakPack()) {
          toggleSpinner({ showSpinner: false });
          setInfo(true);
          setInfoMsg(`Success! Redirecting to scan page...`);
          setTimeout(() => {
            navigate(urlForVersion("/scan"));
          }, 2000);
          return true;
        }
        if (!isRanpakPack()) {
          await handleUnload();
        }
        setInfo(true);
        setInfoMsg(INFO_MESSAGES.packProcessFinished);
        toggleSpinner({ showSpinner: false });
        return true;
      } else if (response.status === 202) {
        await handleUnload();
        setWarning(true);
        setReprintWarning(true);
        setWarningMsg(ERROR_MESSAGES.shippingAlreadyProcessedNoPrint);
        toggleSpinner({ showSpinner: false });
      } else if (response.status === 203) {
        setError(true);
        setErrMsg(ERROR_MESSAGES.shippingSafetyError);
        setInfo(true);
        setInfoMsg(
          `LS: ${shipmentPayload?.shipment_head?.lieferschein} || Auftrag: ${shipmentPayload?.shipment_head?.auftrag}`
        );
        toggleSpinner({ showSpinner: false });
        if (!isRanpakPack()) {
          await handleUnload();
        }
      } else if (response.status >= 400) {
        setError(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        );
      }
      return false;
    } catch (error: any) {
      setError(true);
      setErrMsg(
        JSON.stringify(
          error?.response?.data?.message?.detail ||
            error?.response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        )
      );
      if (error?.response?.data?.message?.hasOwnProperty("carrier")) {
        handleDisputeSubmit();
        return false;
      }
      handleDisputeSubmit();
      toggleSpinner({ showSpinner: false });
      return false;
    }
  };

  const handlePrintNewLabel = async () => {
    try {
      toggleSpinner({ showSpinner: true });
      const response = await startShipping(shipmentPayload, false, false, true);
      if (response.status === 201) {
        setInfo(true);
        setInfoMsg(INFO_MESSAGES.extraLabelPrinted);
        toggleSpinner({ showSpinner: false });
        return true;
      } else if (response.status === 202) {
        setWarning(true);
        setReprintWarning(true);
        setWarningMsg(ERROR_MESSAGES.shippingAlreadyProcessedNoPrint);
        toggleSpinner({ showSpinner: false });
      } else if (response.status >= 400) {
        setError(true);
        setErrMsg(
          response?.data?.message?.error_message ||
            ERROR_MESSAGES.generalShippingError
        );
      }
      return false;
    } catch (error: any) {
      console.log(error);
      setError(true);
      setErrMsg(
        error?.data?.message?.error_message ||
          ERROR_MESSAGES.generalShippingError
      );
      handleDisputeSubmit();
      return false;
    }
  };

  const handleContainerReset = async () => {
    try {
      resetContainer(barcode as string).then((response) => {
        if (response.status === 200) {
          console.log(response?.data);
          setInfo(true);
          setInfoMsg(`Success - ${response?.data?.message}`);
          setTimeout(() => {
            setInfo(false);
          }, 3000);
        }
      });
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        setInfo(true);
        setInfoMsg(`Container Reset Failed ${error?.response?.data?.message}`);
        setTimeout(() => {
          setInfo(false);
        }, 3000);
      } else if (error.response?.status === 400) {
        setInfo(true);
        setInfoMsg(`Container Reset Failed ${error?.response?.data?.message}`);
        setTimeout(() => {
          setInfo(false);
        }, 3000);
      } else if (error.response?.status === 401) {
        setInfo(true);
        setInfoMsg(`Container Reset Failed ${error?.response?.data?.message}`);
        setTimeout(() => {
          setInfo(false);
        }, 3000);
      } else {
        setInfo(true);
        setInfoMsg(`Container Reset Failed ${error?.response?.data?.message}`);
        setTimeout(() => {
          setInfo(false);
        }, 3000);
      }
    }
  };

  const handleDisputeSubmit = () => {
    setDisputeSuccess(true);
    toggleSpinner({ showSpinner: true });
    if (!isRgbWarehouse() && !isRanpakPack()) {
      handleUnload();
    } else if (isRgbWarehouse()) {
      handleContainerReset();
    }
    toggleSpinner({ showSpinner: false });
  };

  const handleReprintSubmit = async () => {
    try {
      toggleSpinner({ showSpinner: true });
      let response = await reprintVersand(shipmentPayload.versand_id);
      if (response.status === 200 || response.status === 201) {
        // setShowReprintAlert(true);
        setReprintMsg(INFO_MESSAGES.reprintSuccessInfo);
        toggleSpinner({ showSpinner: false });
      } else if (response.status >= 400) {
        setError(true);
        setErrMsg(
          response?.data?.message?.error_message || ERROR_MESSAGES.reprintError
        );
      }
    } catch (error: any) {
      console.log(error);
      setError(true);
      setError(
        error?.data?.message?.error_message || ERROR_MESSAGES.reprintError
      );
      setShowReprintAlert(false);
    }
  };

  useEffect(() => {
    setDeliveryNumber(shipmentPayload?.shipment_head?.lieferschein);
    postShipment().then((isShipped) => {
      if (isShipped) {
        console.log(`SHIPPED ${shipmentPayload?.shipment_head?.versand_id}`);
      }
    });
  }, []);

  console.log(shipmentPayload);
  return (
    <div className="bg-app min-h-screen p-4 flex flex-col justify-between">
      <div className="mt-4 w-full flex flex-col">
        <InnerHeader
          title={
            isB2B()
              ? COMMON_MESSAGES.confirmationB2B
              : COMMON_MESSAGES.confirmation
          }
          showBackButton={false}
        />
        <div className="flex items-center justify-center">
          {warning && <WarningBar message={warningMsg} />}
        </div>
        <div className="mt-14 text-center">
          <p>LS: {deliveryNumber}</p>
        </div>
        <div className="mt-8 w-full flex flex-col gap-4 items-center">
          {!isRanpakPack() && (
            <PrimaryButton
              type="button"
              onClick={handleReprintSubmit}
              label={
                (reprintWarning && COMMON_MESSAGES.rePrintLabelWithWarning) ||
                COMMON_MESSAGES.rePrintLabel
              }
            />
          )}
          {showReprintAlert && <InfoBar message={reprintMsg} />}
        </div>
        <div className="flex flex-col items-center gap-4 mb-4">
          {error && <ErrorBar message={errMsg} />}
          {info && <InfoBar message={infoMsg} />}
          {disputeSuccess && <InfoBar message={INFO_MESSAGES.disputeSuccess} />}
        </div>
        {isB2B() && (
          <PrimaryButton
            type="button"
            onClick={handlePrintNewLabel}
            label={COMMON_MESSAGES.printExtraLabel}
          />
        )}
        <div className="flex flex-col items-center gap-4 mt-16">
          <PrimaryButton
            type="button"
            label={COMMON_MESSAGES.done}
            onClick={() => navigate(urlForVersion("/scan"))}
          />
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { userLogin } from "../../shared/network/lib/login";
import { ErrorBar } from './ErrorBar';
import { Selector } from './Selector';
import { getLanes } from '../../shared/network/lib/getLanes';
import { laneDefault } from "../../shared/config/lanes";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { VALIDATION_MESSAGES } from '../../shared/utils/validationMessages';
import { setGlobalRole, urlForVersion } from '../../shared/utils/common'
import { businessRoles } from "../../shared/config/businessRoles";
import { CONFIGURATIONS as C } from "../../shared/utils/configurations";

export const LoginB2B = () => {
  const [errMsg, setErrMsg] = useState("");
  const [selectedRole, setSelectedRole] = useState({value: undefined, label: undefined});
  const [error, setError] = useState(false);
  const [lanes, setLanes] = useState(laneDefault.map((item) => ({
    value: item.kommissionierung_id.toString(),
    label: item.lane_name
  })));
  const roles = businessRoles.map((item: { role_id: any; role_name: any; }) => ({
    value: item.role_id.toString(),
    label: item.role_name
  }));
  const ref = useRef<any>();
  const navigate = useNavigate();


  const fetchLanes = () => {
    let data = getLanes()
      .then(
        (response: any) => {
          setLanes(response?.data.map((item: { kommissionierung_id: string; lane_name: string; }) => {
            return ({value: item.kommissionierung_id.toString(), label: item.lane_name})
          }))
        })
      .catch((error: any) => {
        console.log(error);
      });
    return data;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
    fetchLanes()
  }, []);
  console.log(selectedRole)
  return (
    <>
    <Formik
      initialValues={{username: '', password: '', lane: undefined, role: undefined}}
      validationSchema={Yup.object({
        username: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
        password: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
        role: Yup.number().required(VALIDATION_MESSAGES.roleNotSelected),
        lane: Yup.number()
      })}
      onSubmit={async (data) => {
        try {
            let role = parseInt(data.role || C.ROLE.PICK.ID);
            let lane_id;
            switch (role) {
              case C.ROLE.B2B_1_PICK.ID:
                lane_id = C.LANE.KAKI_B2B_1.kommissionierung_id;
                break;
              case C.ROLE.B2B_1_PACK.ID:
                lane_id = C.LANE.KAKI_B2B_1.kommissionierung_id;
                break;
              case C.ROLE.B2B_2_PICK_PACK.ID:
                lane_id = C.LANE.KAKI_B2B_2.kommissionierung_id;
                break;
              default:
                lane_id = C.LANE.MORE_NONEU.kommissionierung_id;
            }
          await userLogin({username: data.username, password: data.password}, {
            //TODO: fix default lane value send for PACK route
            lane_id: lane_id,
            lane_name: lanes.find(({value}) => value === data.lane)?.label ?? 'B2B'
          })
            .then((response) => {
              if (response.status === 200) {
                setGlobalRole(data.role);
                navigate(urlForVersion(C.URL.START));
              }
            })
        } catch (error: any) {
          setError(true);
          if (!error?.response) {
            setErrMsg("Keine Server-Antwort.");
          } else if (error.response?.status === 400) {
            setErrMsg("Missing Username or Password");
          } else if (error.response?.status === 401) {
            setErrMsg("Falsche Login-Daten.");
          } else {
            setErrMsg("Login Failed");
          }
        }
      }}
    >
      <Form id='login-form'>
        <div className="flex flex-col items-center mt-10 px-2">
          <h1 className="text-3xl font-bold m-3">B2B Kommi-App</h1>
          <div className="flex flex-col w-full max-w-xs">

            <label className="label label-text" htmlFor="username"> Username </label>
            <Field name="username" type="text" className="input input-bordered" id="username"/>
            <ErrorMessage className="text-red-700" name="username" component='div'/>

            <label className="label label-text mt-3" htmlFor="password"> Passwort </label>
            <Field name="password" type="password" className="input input-bordered" id="password"/>
            <ErrorMessage className="text-red-700" name="password" component='div'/>

            <label className="label label-text mt-3" htmlFor="lane"> Rolle </label>
            <Selector options={roles} name="role" placeholder="Bitte Rolle auswählen" setSelected={setSelectedRole}/>
            <ErrorMessage className="text-red-700" name="role" component='div'/>
            <button type="submit" id="btn-login" className="btn btn-primary w-full max-w-xs mt-12">Einloggen</button>
            
            

            {error && <div className="flex justify-center"><ErrorBar message={errMsg}/></div>}
          </div>
        </div>
      </Form>
    </Formik>
    <div className="flex items-center justify-center">
    <button onClick={() => navigate(C.URL.LOGIN)} className="btn btn-outline w-full max-w-xs mt-12">Zum B2C Login</button>
    </div>
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { CONFIGURATIONS as C } from "../../shared/utils/configurations";

interface ModalProps {
  title: string;
  isOpen: boolean;
  truePassword: string;
  onClose: () => void;
}

const VerifyPickerModal = ({ title, isOpen, truePassword, onClose }: ModalProps) => {
  const [password, setPassword] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsValid(password === C.EXPORT_LANE_PW);
    if (password === truePassword) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl mb-4">{title}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="password"
                  id="password"
                  ref={inputRef}
                  value={password}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="submit"
                className="bg-primary-button w-full text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </form>
            {isValid === false && <p className="text-red-500 mt-2">Falsches Kennwort</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyPickerModal;

import React, { useEffect } from "react";
import { useState } from "react";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { ContainerReset } from "../components/ContainerReset";
import { DisputeReset } from "../components/DisputeReset";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useOutletContext } from "react-router-dom";
import { VALIDATION_MESSAGES } from "../utils/validationMessages";
import { InfoBar } from "../../v1/components/InfoBar";
import { userCreateAll } from "../network/lib/userCreateAll";
import { ManifestCreation } from "../components/ManifestCreation";
import { BlockProducts } from "../components/BlockProducts";
import { releaseStuckVersands } from "../network/lib/unstuckVersands";
import { Switch } from "../components/Switch";
import { isRgbWarehouse } from "../utils/common";
import {
  rgb1Lanes,
  rgb2Lanes,
  kakiAllLanes,
} from "../config/lanes";
import { retrieveWarehouseOptSwitchState } from "../network/lib/retrieveWarehouseOptSwitchState";
import { setWarehouseOptSwitchState } from "../network/lib/setWarehouseOptSwitchState";
import { retrieveSevenSendersSwitchState } from "../network/lib/retrieveSevenSendersSwitchState";
import { setSevenSendersSwitchState } from "../network/lib/setSevenSendersSwitchState";
import { setDangerousLabelSwitchState } from "../network/lib/setDangerousLabelSwitchState";
import { retrieveDangerousLabelSwitchState } from "../network/lib/retrieveDangerousLabelSwitchState";
import { forceLogoutForUserName } from "../network/lib/forceLogoutForUsername";
import { forceLogoutForAllUsers } from "../network/lib/forceLogoutForAllUsers";
import { handleErrors } from "../utils/handleErrors";
import SlimTable from "../components/SlimTable";
import { getMonthlyPickingAverages } from "../network/lib/getMonthlyPickingAverages";

export const AdminPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [userLogoutMsg, setUserLogoutMsg] = useState("");
  const [allUserLogoutMsg, setAllUserLogoutMsg] = useState("");
  const [userCreateinfo, setUserCreateinfo] = useState<Boolean>(false);
  const [userCreateinfoMsg, setUserCreateinfoMsg] = useState<string>("");
  const [monthlyAverageData, setMonthlyAverageData] = useState<any[]>([]);

  const [isToggled, setToggled] = useState(true);
  const [sevenSendersSwitchActive, setSevenSendersSwitchActive] =
    useState(true);
  const [isDangerousLabelToggled, setDangerousLabelToggled] = useState(false);

  const [info, setInfo] = useState<Boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;

  const handleToggle = () => {
    const newToggleState = !isToggled;
    handleSetWarehouseOptSwitchState(newToggleState);
  };

  const handleSevenSendersSwitchToggle = () => {
    const newToggleState = !sevenSendersSwitchActive;
    handleSetSevenSendersSwitchState(newToggleState);
  };

  const handleDangerousLabelToggle = () => {
    const newToggleState = !isDangerousLabelToggled;
    handleSetDangerousLabelSwitchState(newToggleState);
  };

  const { userId, userInfo }: any = useOutletContext();
  const handleAdminCheck = () => {
    const scopes = userInfo?.scopes || [];

    if (scopes.includes("admin")) {
      setIsAdmin(true);
      return;
    } else {
      return;
    }
  };

  const handleRetrieveWarehouseOptSwitchState = async () => {
    try {
      await retrieveWarehouseOptSwitchState().then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setToggled(response?.data?.is_on);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleRetrieveDangerousLabelSwitchState = async () => {
    try {
      await retrieveDangerousLabelSwitchState().then(
        (response: { status: number; data: { is_on: boolean } }) => {
          if (response?.status === 200) {
            console.log(response);
            setDangerousLabelToggled(response?.data?.is_on);
          }
        }
      );
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleSetWarehouseOptSwitchState = async (newState: boolean) => {
    try {
      await setWarehouseOptSwitchState(newState).then((response) => {
        if (response.status === 201 || response.status === 200) {
          setToggled(response?.data?.is_on);
        }
      });
    } catch (error: any) {
      handleErrors(
        error,
        { 400: "Bad Request", 401: "Unauthorized", 405: "Method Not Allowed" },
        setError,
        setErrMsg
      );
    }
  };

  const handleRetrieveSevenSendersSwitchState = async () => {
    try {
      await retrieveSevenSendersSwitchState().then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setSevenSendersSwitchActive(response?.data?.is_on);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleGetMonthlyPickingAverages = async (
    month: number = 0,
    year: number = 0
  ) => {
    try {
      await getMonthlyPickingAverages(month, year).then((response) => {
        if (response?.status === 200) {
          console.log(response);
          setMonthlyAverageData(response?.data?.picking_averages);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleSetSevenSendersSwitchState = async (newState: boolean) => {
    try {
      await setSevenSendersSwitchState(newState).then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200) {
          setSevenSendersSwitchActive(response?.data?.is_on);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleSetDangerousLabelSwitchState = async (newState: boolean) => {
    try {
      await setDangerousLabelSwitchState(newState).then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200) {
          setDangerousLabelToggled(response?.data?.is_on);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleCreateAllXentralUsers = async () => {
    try {
      await userCreateAll().then((response) => {
        if (response.status === 201) {
          setUserCreateinfo(true);
          setUserCreateinfoMsg(response?.data?.message);
        }
      });
    } catch (error: any) {
      console.log(error);
      if (!error?.response) {
        setUserCreateinfo(true);
        setUserCreateinfoMsg(
          `User Creation Failed: ${error?.response?.data?.message}`
        );
      } else if (error.response?.status === 400) {
        setUserCreateinfo(true);
        setUserCreateinfoMsg(
          `User Creation Failed: ${error?.response?.data?.message}`
        );
      } else if (error.response?.status === 401) {
        setUserCreateinfo(true);
        setUserCreateinfoMsg(
          `User Creation Failed: ${error?.response?.data?.message}`
        );
      } else {
        setUserCreateinfo(true);
        setUserCreateinfoMsg(
          `User Creation Failed: ${error?.response?.data?.message}`
        );
      }
    }
  };

  const handleForceLogoutAllUsers = async () => {
    try {
      await forceLogoutForAllUsers().then((response) => {
        if (response.status === 200) {
          console.log("logoutresp", response);
          setAllUserLogoutMsg(response?.data?.message);
        }
      });
    } catch (error: any) {
      handleErrors(error, {}, setError, setErrMsg);
    }
  };

  const handleReleaseStuckShipment = async () => {
    await releaseStuckVersands();
    setInfo(true);
    setInfoMsg(
      "All shipments are reset and they will appear as picking in endscan."
    );
    setTimeout(() => {
      setInfo(false);
    }, 3000);
  };

  useEffect(() => {
    if (isRgbWarehouse()) {
      handleRetrieveWarehouseOptSwitchState();
      handleRetrieveDangerousLabelSwitchState();
      handleGetMonthlyPickingAverages();
    }
    handleRetrieveSevenSendersSwitchState();
  }, [isToggled, sevenSendersSwitchActive, isDangerousLabelToggled]);

  useEffect(() => {
    handleGetMonthlyPickingAverages();
  }, []);

  useEffect(() => {
    handleAdminCheck();
  }, [userInfo]);

  console.log(userId);
  console.log(userInfo);
  return (
    <div className="flex flex-col items-center">
      {info && <InfoBar message={infoMsg}></InfoBar>}
      <h1 className="text-3xl font-bold mt-4">Admin Panel</h1>

      {!isAdmin && <h2>Sie scheinen keine Admin Rechte zu haben.</h2>}
      {isAdmin && (
        <>
          <div className="flex flex-col gap-2 items-center"></div>
          <h2 className="text-2xl font-bold mt-2">Wilkommen!</h2>

          <div className="divider"></div>

          {isRgbWarehouse() ? (
            <BlockProducts
              lane_ids={rgb1Lanes}
              SectionTitle={"SKUs für RGB-1 (Ent)Sperren"}
            />
          ) : (
            <BlockProducts
              lane_ids={kakiAllLanes}
              SectionTitle={"SKUs (Ent)Sperren"}
            />
          )}

          <div className="divider"></div>

          {isRgbWarehouse() && (
            <>
              <BlockProducts
                lane_ids={rgb2Lanes}
                SectionTitle={"SKUs für RGB-2 (Ent)Sperren"}
              />
            </>
          )}

          <div className="divider"></div>
          <h2 className="text-2xl font-bold mb-5">
            Durchschnittliche Packzahlen für Monat
          </h2>
          <Formik
            initialValues={{
              year: 2024,
              month: currentMonth,
            }}
            validationSchema={Yup.object({
              year: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
              month: Yup.string().required(VALIDATION_MESSAGES.inputNotFilled),
            })}
            onSubmit={(values) =>
              handleGetMonthlyPickingAverages(values?.month, values?.year)
            }
          >
            {({ status }) => (
              <Form id="monthly-average-form">
                <div className="flex items-center px-2">
                  <div className="flex flex-col w-full max-w-xs">
                    <div className="flex gap-2 justify-center items-end mb-5">
                      <div>
                        <ErrorMessage
                          className="text-red-700"
                          name="year"
                          component="div"
                        />
                        <label className="label label-text" htmlFor="year">
                          Jahr
                        </label>
                        <Field
                          name="year"
                          type="number"
                          className="input input-bordered"
                          id="year"
                        />
                      </div>
                      <div>
                        <ErrorMessage
                          className="text-red-700"
                          name="month"
                          component="div"
                        />
                        <label className="label label-text" htmlFor="month">
                          Monat
                        </label>
                        <Field
                          name="month"
                          type="number"
                          className="input input-bordered"
                          id="month"
                        />
                      </div>
                      <button
                        type="submit"
                        id="btn-login"
                        className="btn btn-primary w-40 mt-6"
                      >
                        Daten aktualisieren
                      </button>

                      {error && (
                        <div className="flex justify-center">
                          <ErrorBar message={errMsg} />
                        </div>
                      )}
                    </div>

                    {userLogoutMsg && (
                      <div className="text-green-700">
                        {" "}
                        {status}
                        <InfoBar message={userLogoutMsg} />
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <SlimTable
            data={monthlyAverageData}
            columnAliases={{
              year_with_month: "Jahr-Monat",
              user_name: "Username",
              amount_packed: "Anzahl Pakete",
              working_days: "Arbeitstage",
              daily_average_for_month: "Durchschnitt Monat",
            }}
          />

          <div className="divider"></div>
          <DisputeReset />
          <div className="divider"></div>
          <div className="flex flex-col items-center">
            <h2 className="text-2xl font-bold">User Anlegen</h2>
            {userCreateinfo && <InfoBar message={userCreateinfoMsg}></InfoBar>}
            <button
              type="submit"
              id="btn-login"
              className="btn btn-primary mt-6"
              onClick={handleCreateAllXentralUsers}
            >
              User aus Xentral in App Importieren
            </button>
          </div>
          <div className="divider"></div>
          <ManifestCreation />
          <div className="divider"></div>
          <ContainerReset />

          <div className="divider"></div>

          {isRgbWarehouse() && (
            <>
              <div className="divider"></div>
              <div className="flex gap-1 flex-col">
                <h2 className="text-2xl font-bold text-center">
                  MOP Optimierung (de)aktivieren:
                </h2>
                <div className="flex justify-center my-6">
                  <label className="flex cursor-pointer">
                    <div className="relative">
                      <input
                        type="checkbox"
                        className="toggle toggle-success"
                        checked={isToggled}
                        onChange={handleToggle}
                      />
                    </div>
                    <div className="ml-3 font-medium">
                      {isToggled ? "Optimierung ist AN" : "Optimierung ist AUS"}
                    </div>
                  </label>
                </div>
              </div>
              <div className="divider"></div>

              <Switch
                switch_title={"Dangerous Goods Label Feature"}
                handleToggle={handleDangerousLabelToggle}
                switch_text_off={"Dangerous goods label printing off"}
                switch_text_on={"Dangerous goods label printing on"}
                isToggled={isDangerousLabelToggled}
              ></Switch>
            </>
          )}

          <>
            <div className="flex gap-1 flex-col">
              <h2 className="text-2xl font-bold text-center">
                Label Hersteller Wechsel
              </h2>
              <div className="flex justify-center my-6">
                <label
                  className="flex cursor-pointer"
                  htmlFor="sevensenderstoggle"
                >
                  <div className="relative">
                    <input
                      id="sevensenderstoggle"
                      type="checkbox"
                      className="toggle toggle-success"
                      checked={sevenSendersSwitchActive}
                      onChange={handleSevenSendersSwitchToggle}
                    />
                  </div>
                  <div className="ml-3 font-medium">
                    {sevenSendersSwitchActive
                      ? "7Senders IST AKTIV"
                      : "DHL IST AKTIV"}
                  </div>
                </label>
              </div>
            </div>
            <div className="divider"></div>
            <div className="flex flex-col items-center">
              <h2 className="text-2xl font-bold">User gezielt Ausloggen</h2>
              <Formik
                initialValues={{
                  user_name: "",
                }}
                validationSchema={Yup.object({
                  user_name: Yup.string().required(
                    VALIDATION_MESSAGES.inputNotFilled
                  ),
                })}
                onSubmit={async (data, { resetForm }) => {
                  try {
                    await forceLogoutForUserName(data.user_name).then(
                      (response) => {
                        if (response.status === 200) {
                          console.log("logoutresp", response);
                          setUserLogoutMsg(response?.data?.message);
                          resetForm();
                        }
                      }
                    );
                  } catch (error: any) {
                    handleErrors(
                      error,
                      {
                        400: "SKU Cannot be Blocked",
                        401: "SKU Cannot be Blocked",
                      },
                      setError,
                      setErrMsg
                    );
                  }
                }}
              >
                {({ status }) => (
                  <Form id="login-form">
                    <div className="flex items-center px-2">
                      <div className="flex flex-col w-full max-w-xs">
                        <div className="flex gap-2 justify-center">
                          <div>
                            <label
                              className="label label-text"
                              htmlFor="user_name"
                            >
                              User Name
                            </label>
                            <Field
                              name="user_name"
                              type="text"
                              className="input input-bordered"
                              id="user_name"
                            />
                            <ErrorMessage
                              className="text-red-700"
                              name="user_name"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="flex gap-4 justify-center">
                          <button
                            type="submit"
                            id="btn-login"
                            className="btn btn-primary w-40 mt-6"
                          >
                            User Ausloggen
                          </button>

                          {error && (
                            <div className="flex justify-center">
                              <ErrorBar message={errMsg} />
                            </div>
                          )}
                        </div>
                        {userLogoutMsg && (
                          <div className="text-green-700">
                            {" "}
                            {status}
                            <InfoBar message={userLogoutMsg} />
                          </div>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="divider"></div>
            <div className="flex flex-col items-center">
              <h2 className="text-2xl font-bold">Alle User Ausloggen</h2>
              <div className="flex gap-4 justify-center">
                <button
                  onClick={handleForceLogoutAllUsers}
                  id="btn-login"
                  className="btn btn-md btn-outline mt-4"
                >
                  Alle User Ausloggen
                </button>

                {error && (
                  <div className="flex justify-center">
                    <ErrorBar message={errMsg} />
                  </div>
                )}
              </div>
              {allUserLogoutMsg && (
                <div className="text-green-700">
                  {" "}
                  <InfoBar message={allUserLogoutMsg} />
                </div>
              )}
            </div>
            <div className="divider"></div>
            <div className="flex gap-1 flex-col">
              <h2 className="text-2xl font-bold text-center">
                Improzess Aufträge zurücksetzen
              </h2>
              <div className="flex justify-center">
                <label
                  className="flex cursor-pointer"
                  htmlFor="release_stuck_versands_button"
                >
                  <button
                    id="release_stuck_versands_button"
                    className="btn btn-md btn-outline mt-4"
                    onClick={() => handleReleaseStuckShipment()}
                  >
                    Ausführen
                  </button>
                </label>
              </div>
            </div>
            <div className="divider"></div>
          </>
        </>
      )}
    </div>
  );
};

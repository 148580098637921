import React from "react";
import { createContext, useState } from "react";

export interface AuthContextType {
    auth:any
    setAuth: any
}

const initialState = {
    auth: null,
    setAuth: null
};

const AuthContext = createContext<AuthContextType>(initialState);


export const AuthProvider = ({children}: any) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
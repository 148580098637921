import React, { FC } from 'react';
import { classNames } from '../utils/classNames'

interface PrimaryButtonProp extends React.HTMLProps<HTMLButtonElement>{
    type?: 'submit' | 'button',
    label: string,
    className?: string
}

export const PrimaryButton:FC<PrimaryButtonProp> = ({ type = 'submit', label, className =' rounded-md', ...props }) => {
    return (
        <button
            type={type}
            className={classNames( className, "bg-primary-button text-white w-full py-2" )}
            {...props}
        >
            {label}
        </button>
    );
}

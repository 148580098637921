import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../shared/network/lib/logout";
import { urlForVersion } from "../../shared/utils/common";

export function StartPageDummy() {
  const navigate = useNavigate();
  useState<boolean>(false);

  const pickingUrl = urlForVersion("/picking-dmy");

  const handleLogout = async () => {
    try {
      await userLogout().then((response) => {
        if (response.status === 200) {
          navigate("/login");
        }
      });
    } catch (error) {
      console.log("Error");
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl font-bold mt-6">Startseite</h1>
      <div className="divider"></div>
      <div className="flex flex-col gap-2 items-center">
        <h2 className="text-2xl font-bold">Willkommen zu G7 Standard Picking!</h2>
      </div>

      <div className="divider"></div>
      <Link to={pickingUrl}>
        <button
          id="go-to-pick-btn"
          type="button"
          className="mt-10 inline-block px-12 py-4 border-2 border-4 border-green-500 text-green-500 font-medium text-md leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Start 
        </button>
      </Link>

      <button
        id="btn-logout"
        type="button"
        onClick={handleLogout}
        className="my-24 inline-block px-6 py-2 border-2 border-gray-800 text-gray-800 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
      >
        Ausloggen
      </button>
    </div>
  );
}

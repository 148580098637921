import Cookies from "universal-cookie";
import { rgb2PlusLanes } from "../config/lanes";
import { CONFIGURATIONS as C } from "../../shared/utils/configurations";

export const isCypressTest = () => {
  // @ts-ignore
  return !!window.Cypress;
};

const dynamicRoles = [C.ROLE.RANPAK_MULTI_ORDER_PICK.ID,
C.ROLE.RANPAK_MULTI_ORDER_PACK.ID,
C.ROLE.RANPAK_PRINT.ID,
].map((r) => r.toString());

export function setGlobalRole(roleId: any) {
  const cookies = new Cookies();
  cookies.set("END_SCAN_ROLE_ID", roleId);
}

export function setFrontEndLane(laneId: any) {
  const cookies = new Cookies();
  cookies.set("END_SCAN_LANE_ID", laneId);
}

export function getCookie(name: string) {
  const cookies = new Cookies();
  return cookies.get(name);
}

export function getUserRole() {
  return getCookie("END_SCAN_ROLE_ID");
}

export function getLane() {
  return getCookie("END_SCAN_LANE_ID");
}

export function urlForVersion(url: string) {
  const cookieRole = getCookie("END_SCAN_ROLE_ID");
  return (cookieRole === C.ROLE.PICK_PACK.ID.toString()
    || cookieRole === C.ROLE.B2B_2_PICK_PACK.ID.toString() ? C.URL.VERSION_1_PREFIX : C.URL.VERSION_2_PREFIX) + url;
}

export function isDynamicRole() {
  return dynamicRoles.includes(String(getUserRole()));
}

export function isPick() {
  const cookieRole = getCookie("END_SCAN_ROLE_ID");
  return cookieRole === "1" || cookieRole === "4";
}
export function isB2B() {
  const cookieRole = getCookie("END_SCAN_ROLE_ID");
  return cookieRole === "4" || cookieRole === "5" || cookieRole === "6";
}

export function isMultiPicking() {
  const cookieRole = getCookie("END_SCAN_ROLE_ID");
  return cookieRole === "18";
}

export const isMultiPickingRanpak = () => {
  const cookieRole = getCookie("END_SCAN_ROLE_ID");
  return !isRgbWarehouse() && cookieRole === "30";
};

export const isRanpakPack = () => {
  const cookieRole = getCookie("END_SCAN_ROLE_ID");
  return !isRgbWarehouse() && cookieRole === "31";
};

export const isRgbWarehouse = () => {
  if (window.location.hostname.includes("rgb-endscan.testing")) {
    return true;
  }
  if (window.location.hostname.includes("rgb-")) {
    return true;
  } else if (window.location.hostname.includes("localhost")) {
    return true;
  } else {
    return false;
  }
};

export const isRgbTwo = () => {
  return (
    isRgbWarehouse() &&
    rgb2PlusLanes.map((num) => num.toString()).includes(String(getLane()))
  );
};

import React, { createContext, useContext, useState } from 'react';

interface toggleSpinnerProps {
    showSpinner: boolean;
    modalSpinner?: boolean;
    textModalSpinner?: string;
    successIconModalSpinner?: boolean;
}

interface ContextProps {
  spinnerVisible: boolean;
  modalSpinner?: boolean;
  textModalSpinner?: string;
  successIconModalSpinner?: boolean;
  toggleSpinner({showSpinner, modalSpinner, textModalSpinner, successIconModalSpinner}: toggleSpinnerProps): void;
}

const initialState = {
    spinnerVisible: false, 
    toggleSpinner: () => null
};

const SpinnerContext = createContext<ContextProps>(initialState);

export const SpinnerContextProvider = ({ children }: any) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [modalSpinner, setModalSpinner] = useState(false);
  const [textModalSpinner, setTextModalSpinner] = useState('');
  const [successIconModalSpinner, setSuccessIconModalSpinner] = useState(false);

  const toggleSpinner = ({showSpinner, modalSpinner = false, textModalSpinner = '', successIconModalSpinner = false}: toggleSpinnerProps) => {
    setSpinnerVisible(showSpinner);
    setModalSpinner(modalSpinner);
    setTextModalSpinner(textModalSpinner);
    setSuccessIconModalSpinner(successIconModalSpinner);
  };

  return (
    <SpinnerContext.Provider value={{ 
        spinnerVisible,
        modalSpinner,
        textModalSpinner,
        successIconModalSpinner,
        toggleSpinner 
    }}>
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = (): ContextProps => {
  const spinner = useContext(SpinnerContext);

  return spinner;
};

export default SpinnerContext;

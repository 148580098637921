const savePayload = (
    payload: any
  ): void => {
    try {
      const data = {
        fullPayload: payload
      };
      const jsonData: string = JSON.stringify(data);
      localStorage.setItem("fullPayload", jsonData);

      localStorage.setItem("hasScan", false.toString());

  
    } catch (error) {
      // Error occurred while saving the data
      console.error("Error saving data to storedMopScans:", error);
    }
  };
  
  export default savePayload;
  
const saveToStoredShipmentPosition = (data: any): void => {
    try {
      const jsonData: string = JSON.stringify(data);
      localStorage.setItem('storedShipmentPosition', jsonData);
    } catch (error) {
      // Error occurred while saving the data
      console.error('Error saving data to storedShipmentPosition:', error);
    }
  };

  export default saveToStoredShipmentPosition
import { useEffect, useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormikState } from "formik";

import { ProcessedData } from "../../shared/types/data";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
import { getUserRole, isPick, isRgbWarehouse, urlForVersion } from '../../shared/utils/common';
import { PickingDataResponse } from "../../shared/types/picking";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { postPickingContainerLoad } from "../../shared/network/lib/picking";
import { resetSearchBar } from "../../shared/utils/resetSearchBar";
import { isB2B } from "../../shared/utils/common";
import saveToStoredShipmentPosition from "../../shared/utils/saveToStoredShipmentPosition";

type UseScanProps = {
    processed: ProcessedData[];
    fullPayload: PickingDataResponse;
}

export const useScan = ({ processed, fullPayload, }: UseScanProps) => {
  const [searchParams] = useSearchParams();
  const {toggleSpinner} = useSpinner();
  const [shipmentPosition, setShipmentPosition] = useState<ProcessedData[]>([]);
  const [payload, setPayload] = useState<PickingDataResponse>(fullPayload);
  const [error, setError] = useState(false);
  const [scanFinished, setScanFinished] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgLoad, setErrMsgLoad] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [maxScanAmount, setMaxScanAmount] = useState(1);
  const [multipleProduct, setMultipleProduct] = useState();
  const [productName, setProductName] = useState();
  const [productEan, setProductEan] = useState();
  const barcode = searchParams.get('barcode');
  const navigate = useNavigate();


  const productIsMultiple = (ean: any) => {
    let multipleAmount = 5
    if (isB2B() && !isRgbWarehouse()){multipleAmount = 1}
    let hasNonMultiple = shipmentPosition.find((obj) => {
      return obj.ean === ean && obj.done === false && obj.amountNeeded - obj.amountScanned >= 1 && obj.amountNeeded - obj.amountScanned < 2;
    });

    const getWareHouseName = () => {
      switch (window.location.hostname) {
        case "rgb-endscan.testing.haute-cuisine.io":
          return "rgb";
        case "rgb-endscan.haute-cuisine.io":
          return "rgb";
        case "kaki-endscan.haute-cuisine.io":
          return "kaki";
        default:
          return "rgb";
      }
    };

    let warehouseName = getWareHouseName();

    if (warehouseName === "kaki") {
      if (hasNonMultiple || !isB2B()) {
        return [false, {}];
      }
    } else {
      if (hasNonMultiple) {
        return [false, {}];
      }
    }

  
    // Check if Product Amount is Above 5
    let result = shipmentPosition.find((obj) => {
      return obj.ean === ean && obj.done === false && obj.amountNeeded - obj.amountScanned >= multipleAmount;
    });
    if (result == null) {
      return [false, {}];
    } else {
      let productName = result.cells?.find((obj: any) => {
        return obj.columnName === "product_name";
      })?.value;
      return [
        true,
        {
          ean: ean,
          productName: productName,
          maxAmount: result.amountNeeded - result.amountScanned,
        },
      ];
    }
  };

  const handleModalClose = () => {
    setShowModal(!showModal);
  };


  const handleScan = (value: string, scanAmount: number) => {
    if (!barcode) {
      setErrorState(ERROR_MESSAGES.needCode);
      return;
    }
    console.log(scanAmount)
    setShipmentPosition((prevItems: any[]) => {
      let hadArticleMatch = false;
      let wasIncremented = false;
      let wasAmountReached = false
      console.log('prevItems: ',prevItems)
      const updatedItems = prevItems.map((item: any) => {
        const { ean, amountNeeded, amountScanned, cells } = item;
        const istCellIndex = cells.findIndex(
          (cell: any) => cell.columnName === "ist"
        );

        if (ean !== value) {
          return item;
        }

        if (amountNeeded === amountScanned || amountNeeded < amountScanned) {
          wasAmountReached = true
          return item;
        }

        if (hadArticleMatch) {
          return item;
        }


        const itemCopy = { ...item, amountScanned: amountScanned + scanAmount };
        const cellCopy = [...cells];
        cellCopy[istCellIndex].value += scanAmount;
        wasIncremented = true;
        hadArticleMatch = true;

        if (amountNeeded === itemCopy.amountScanned || amountNeeded <= itemCopy.amountScanned) {
          itemCopy.done = true;
          cellCopy[istCellIndex].value = amountNeeded
        }
        setShowModal(false);
        resetSearchBar()
        return { ...itemCopy, cells: cellCopy };
      });
      if (!hadArticleMatch && !wasAmountReached) {
        setErrorState(ERROR_MESSAGES.productNotInListError);
      } else if (wasAmountReached && !wasIncremented) {
        setErrorState(ERROR_MESSAGES.productAmountFulfilledError);
      }
      const storagePosition = {versand_id: payload.versand_id, position: updatedItems, role: getUserRole()}
      saveToStoredShipmentPosition(storagePosition)
      return updatedItems;
    });
  };

  const handlePreScan = (value: string, resetValue: (nextState?: Partial<FormikState<{packagecode: string}>> | undefined) => void) => {
    const multipleResult: any = productIsMultiple(value || "");
    console.log(resetValue)
    if (multipleResult[0] === false) {
      handleScan(value, 1);
    } else {
      // Open the modal
      setShowModal(true);
      setMaxScanAmount(multipleResult[1].maxAmount);
      setMultipleProduct(multipleResult);
      setProductName(multipleResult[1].productName)
      setProductEan(multipleResult[1].ean)
    }
  };

  const setErrorState = (errorMsg: string) => {
    setErrMsg(errorMsg);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  const checkScansDone = async () => {
    if (shipmentPosition == null || undefined) {
      return;
    } else if (shipmentPosition.length > 0) {
      let unfinshedPositions = shipmentPosition.filter(
        (position: any) => position.amountNeeded > position.amountScanned
      );
      if (unfinshedPositions.length === 0) {
        setScanFinished(true);
      }
    }
  };

  const handleScanDoneNextStep = async () => {
    toggleSpinner({
      showSpinner: true,
      modalSpinner: true,
      textModalSpinner: isPick()? INFO_MESSAGES.processingPickProducts: INFO_MESSAGES.processingPackProducts,
    });
    try {
      let response = null;
      if (isPick()) {
        response = await postPickingContainerLoad({
          container_id: barcode as string,
          picker_id: payload.user_id,
          versand_id: payload.versand_id
        });
      } else {
        response = {"status": 200}
      }

      if (response.status===200) {
        toggleSpinner({
          showSpinner: true,
          modalSpinner: true,
          textModalSpinner: isPick() ? INFO_MESSAGES.processedPickProducts : INFO_MESSAGES.processedPackProducts,
          successIconModalSpinner: true
        });
        await new Promise(resolve => setTimeout(resolve, 2000));
        toggleSpinner({showSpinner: false});
      }
      const url = isPick() ? '/scan' : '/confirmation';
      navigate(urlForVersion(url), { state: { shipmentPayload: payload, barcode: barcode} });
    } catch(e:any) {
      toggleSpinner({showSpinner: false});
      setErrMsgLoad(e.response.data.message);
    }
  }

  useEffect(() => {
    checkScansDone();
  }, [shipmentPosition]);

  useEffect(() => {
    setShipmentPosition(processed);
    setPayload(fullPayload);
  }, [processed, fullPayload]);

  return {
    productName,
    productEan,
    handleModalClose,
    showModal,
    maxScanAmount,
    multipleProduct,
    handleScan,
    handlePreScan,
    shipmentPosition,
    payload,
    error,
    errMsg,
    errMsgLoad,
    scanFinished,
    handleScanDoneNextStep
  }
}

import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface PopupDialogrProps {
  showDialog: boolean;
  fullScreen?: boolean;
  title?: string;
  children: React.ReactNode;
  hideDialog: () => void;
}

export const SmallPopupDialog = ({
  title,
  showDialog,
  children,
  hideDialog,
}: PopupDialogrProps) => {
  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex items-center justify-center z-50"
        onClose={hideDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 w-full" />
        </Transition.Child>
        <div className="relative bg-white max-w-md mx-auto p-10 rounded-md shadow-lg">
          <Dialog.Title>
            <div className="bg-blue-100 text-blue-900 font-bold p-2">
              {title}
            </div>
          </Dialog.Title>
          <div className="mt-4 px-4">{children}</div>
        </div>
      </Dialog>
    </Transition>
  );
};

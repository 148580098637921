import React from "react";


interface TableRow {
    product_sku : string;
    product_name: string;
    storage_location: string;
    lieferscheine_amount: number;
    total_amount: number;
}

interface BlockedSKUTableProps {
  remainingLieferscheine: TableRow[]
}

export const BlockedSKUTable= ({remainingLieferscheine}: BlockedSKUTableProps) => {

    return <>
        <table id="product-table" className="table-fixed mt-10">
                <thead className="bg-gray-200 font-bold">
                  <tr>
                    <td key="product_sku_col">SKU</td>
                    <td key="product_title_col">Name</td>
                    <td key="product_storage_location">Lagerort</td>
                    <td key="product_sku_col_ls">Anzahl Lieferscheine</td>
                    <td key="product_sku_col_mng">Produkt Menge</td>
                  </tr>
                </thead>
                <tbody>
                  {remainingLieferscheine.map((item: TableRow) => (
                    <tr key={item.product_sku}>
                      <td>{item?.product_sku}</td>
                      <td>{item?.product_name}</td>
                      <td>{item?.storage_location}</td>
                      <td>{item?.lieferscheine_amount}</td>
                      <td>{item?.total_amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
    </>
}
import React from "react";
import { classNames } from "../../../../shared/utils/classNames";

interface KakiMopProductTableProps{
  productData: any,
  currentContainerId: number
}

export const KakiMopProductTable = ({productData, currentContainerId}: KakiMopProductTableProps) => {
  const getRow = (row: any, currentContainerId: number) => {
    let extraClass = ''
    if(currentContainerId == row.containerId){extraClass = 'bg-yellow-300'}
    if(row.done == true){extraClass = 'bg-green-500'}
    return (
      <tr>
        <td className={classNames(extraClass, 'text-center')}>{row.quantity_needed}</td>
        <td className={classNames(extraClass, 'text-center')}>{row.quantity_scanned}</td>
      </tr>
    );
  };
  const data = productData ? productData : [];
  const currentContainerData = data?.containers?.filter(
    (containerData: any) => containerData.containerId === currentContainerId
  );

  return (
    <table id="product-table" className="table-fixed w-full">
      <thead className="bg-gray-200 font-bold">
        <tr>
          <td key={"target"} className="text-center">Target</td>
          <td key={"current"} className="text-center">Current</td>
        </tr>
      </thead>
      <tbody>
         {currentContainerData && currentContainerData.length > 0 && getRow(currentContainerData[0], currentContainerId)}
      </tbody>
    </table>
  );
};

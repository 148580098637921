interface TableCell {
  columnName: string;
  value: string | number;
}

interface Item {
  id?: string;
  ean?: string;
  key?: string;
  done?: boolean;
  amountNeeded?: number;
  amountScanned?: number;
  cells: TableCell[];
}

export const RgbStorageLocationSortingService = (a: Item, b: Item): number => {
  const alwaysAtTop = ["M-Sonstige", "Sonstige"];
  const alwaysAtBottom = ["M-Aktion","Aktion", "M-Packtisch", "Packtisch"];

  const getPriority = (item: Item): {
    position: number;
    type: 'top' | 'bottom' | 'normal';
    storageLocation: string;
  } => {
    const storageLocationCell = item.cells.find(
      (cell) => cell.columnName === "storage_location"
    );
    const storageLocation =
      storageLocationCell && storageLocationCell.value
        ? storageLocationCell.value.toString()
        : "";

    // Check for partial matches in alwaysAtTop
    for (let i = 0; i < alwaysAtTop.length; i++) {
      const prefix = alwaysAtTop[i];
      if (storageLocation.indexOf(prefix) !== -1) {
        // Extract numeric suffix if present
        const suffix = storageLocation.substring(
          storageLocation.indexOf(prefix) + prefix.length
        );
        const numericSuffix = parseInt(suffix, 10);

        return {
          position: (i * 1000) + (isNaN(numericSuffix) ? 0 : numericSuffix),
          type: 'top',
          storageLocation,
        };
      }
    }

    // Check for partial matches in alwaysAtBottom
    for (let i = 0; i < alwaysAtBottom.length; i++) {
      const prefix = alwaysAtBottom[i];
      if (storageLocation.indexOf(prefix) !== -1) {
        // Extract numeric suffix if present
        const suffix = storageLocation.substring(
          storageLocation.indexOf(prefix) + prefix.length
        );
        const numericSuffix = parseInt(suffix, 10);

        return {
          position: (i * 1000) + (isNaN(numericSuffix) ? 0 : numericSuffix),
          type: 'bottom',
          storageLocation,
        };
      }
    }

    // Neither in alwaysAtTop nor alwaysAtBottom
    return { position: -1, type: 'normal', storageLocation };
  };

  const priorityA = getPriority(a);
  const priorityB = getPriority(b);

  // Compare priorities
  if (priorityA.type === 'top' && priorityB.type !== 'top') {
    return -1; // a comes before b
  }
  if (priorityB.type === 'top' && priorityA.type !== 'top') {
    return 1; // b comes before a
  }
  if (priorityA.type === 'top' && priorityB.type === 'top') {
    // Lower position comes first
    return priorityA.position - priorityB.position;
  }
  if (priorityA.type !== 'bottom' && priorityB.type === 'bottom') {
    return -1; // a comes before b
  }
  if (priorityB.type !== 'bottom' && priorityA.type === 'bottom') {
    return 1; // b comes before a
  }
  if (priorityA.type === 'bottom' && priorityB.type === 'bottom') {
    // Lower position comes first
    return priorityA.position - priorityB.position;
  }

  // Proceed with existing sorting logic
  const getStorageLocationParts = (storageLocation: string): number[] => {
    if (!storageLocation) {
      return [999]; // Treat missing or invalid storage_location as 999
    }

    // Define regex pattern for valid storage_location
    const regex = /^[A-Z]?\d+(-\d+)*$/;
    if (!regex.test(storageLocation)) {
      return [999]; // Invalid format, treat as 999
    }

    // Remove letters and split into parts
    const sanitized = storageLocation.replace(/[a-zA-Z]/g, "");
    const parts = sanitized.split("-").map(Number);

    // Map NaN parts to 999
    return parts.map((part) => (isNaN(part) ? 999 : part));
  };

  const partsA = getStorageLocationParts(priorityA.storageLocation);
  const partsB = getStorageLocationParts(priorityB.storageLocation);

  const numA_A = partsA[0];
  const numB_A = partsB[0];

  if (numA_A < numB_A) return -1;
  if (numA_A > numB_A) return 1;

  const isOddA = numA_A % 2 === 1;
  const isOddB = numB_A % 2 === 1;

  const numA_B = partsA[1] || 999;
  const numB_B = partsB[1] || 999;

  if (isOddA && isOddB) {
    if (numA_B < numB_B) return -1;
    if (numA_B > numB_B) return 1;
  }

  if (!isOddA && !isOddB) {
    if (numA_B > numB_B) return -1;
    if (numA_B < numB_B) return 1;
  }

  const numA_C = partsA[2] || 999;
  const numB_C = partsB[2] || 999;

  if (numA_C < numB_C) return -1;
  if (numA_C > numB_C) return 1;

  return 0;
};

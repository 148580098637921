import React, { Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';

// redeploy
interface PopupDialogrProps {
  showDialog: boolean
  fullScreen?: boolean
  title?: string
  children: React.ReactNode
  hideDialog: () => void;
}

export const PopupDialog = ({title, showDialog, children, hideDialog}: PopupDialogrProps) => {

  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={hideDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 w-full"/>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto flex flex-col items-center justify-center w-full">
          <div className="bg-white h-full w-full" >

            <Dialog.Panel className='h-full'>
              <Dialog.Title>
                <div className='bg-blue-100 text-blue-900 font-bold p-2'>
                  { title }
                </div>
              </Dialog.Title>
              <div className='px-4'>
                {children}
              </div>
            </Dialog.Panel>

          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

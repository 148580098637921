import React, { useEffect, useState } from "react";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InnerHeader } from "../components/InnerHeader";
import { Selector } from "../../v1/components/Selector";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { VALIDATION_MESSAGES } from "../../shared/utils/validationMessages";
import { INFO_MESSAGES } from "../../shared/utils/infoMessages";
import { ERROR_MESSAGES } from "../../shared/utils/errorMessages";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { InfoBar } from "../../v1/components/InfoBar";
import { getDisputes, saveDispute } from "../../shared/network/lib/disputes";
import { urlForVersion } from "../../shared/utils/common";
import { PopupDialog } from "../components/PopupDialog";
import { disputeConfig } from "../../shared/config/disputeConfig";
import { SearchBarCustomFocus } from "../components/SearchBarCustomFocus";

export const ClarificationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const versand_id = searchParams.get("versand_id");
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [info, setInfo] = useState<Boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState(false);
  const [showDialog, setShowDialog] = useState(true);
  const backToPreviousPage = () => {
    navigate(-1);
  };

  const logo = require("../../assets/dispute_icon.png");

  const handleSubmit = (disputeType: string) => {
    saveDispute({
      versand_id: versand_id,
      dispute_type: disputeType,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setInfo(true);
          setInfoMsg(INFO_MESSAGES.disputeAndRedirectInfo);
          setTimeout(() => {
            navigate(urlForVersion("/scan"));
          }, 3000);
        } else if (response.status >= 400) {
          setError(true);
          setErrMsg(ERROR_MESSAGES.DisputeCreationError);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const [disputes, setDisputes] = useState([]);
  const fetchDisputes = () => {
    let data = getDisputes()
      .then((response: any) => {
        setDisputes(
          response?.data.map(
            (item: { index: string; dispute_type: string }) => {
              return { value: item.dispute_type, label: item.dispute_type };
            }
          )
        );
      })
      .catch((error: any) => {
        console.log(error);
      });
    return data;
  };

  const handleDisputeCodeScan = (value: string) => {
    console.log(value);
    if (value === disputeConfig.disputeCode) {
      setShowDialog(false);
    }
  };

  const handleTryFocus = () => {
    const input = document.getElementById("disputescan");
    input?.focus();
  };

  useEffect(() => {
    fetchDisputes();
    handleTryFocus();
  }, []);

  return (
    <div className="bg-app min-h-screen p-4 flex flex-col justify-between">
      <div className="mt-4 w-full flex flex-col">
        <InnerHeader
          title={COMMON_MESSAGES.clarificationPageTitle + " Anlegen"}
          showBackButton={false}
        />
        <div className="mt-12 w-full flex flex-col gap-4 items-center">
          <Formik
            initialValues={{ dispute: "" }}
            validationSchema={Yup.object({
              dispute: Yup.string().required(
                VALIDATION_MESSAGES.inputNotFilled
              ),
            })}
            onSubmit={async (data) => {
              try {
                handleSubmit(data.dispute);
              } catch (error: any) {}
            }}
          >
            <Form id="dispute-form" className="w-68">
              <div className="flex flex-col mt-10 items-start w-full">
                <label className="label label-text mt-3" htmlFor="dispute">
                  {" "}
                  {COMMON_MESSAGES.dispute}{" "}
                </label>
                <Selector
                  options={disputes}
                  name="dispute"
                  placeholder={COMMON_MESSAGES.selectDispute}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="dispute"
                  component="div"
                />
              </div>

              <PopupDialog
                showDialog={showDialog}
                hideDialog={() => setShowDialog(false)}
                title={"Klärfall QR-Code abscannen"}
              >
                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/3/35/Basic_human_drawing.png" alt="Logo" /> */}

                <InnerHeader
                  title={"Klärfall Freigabe"}
                  showBackButton={true}
                />
                <div className="flex flex-col justify-center items-center w-full my-10">
                  <img className="max-w-[275px]" src={logo} alt="Logo" />
                </div>
                <div className="flex  mt-2 justify-center">
                  <h1>{COMMON_MESSAGES.disputeGoToCaptain}</h1>
                </div>
                <SearchBarCustomFocus
                  fieldId="disputescan"
                  focusOnIdWhenDone="barcodescan"
                  onClick={handleDisputeCodeScan}
                  labelText={"Scan Klärfall QR-Code"}
                  changeFocusWhenDone={false}
                />
              </PopupDialog>
              <PrimaryButton
                type="submit"
                className="rounded-lg w-full my-4"
                label={COMMON_MESSAGES.save}
              />
            </Form>
          </Formik>
          {error && <ErrorBar message={errMsg} />}
          {info && <InfoBar message={infoMsg} />}
        </div>
      </div>

      <div className="w-full px-20  justify-center flex flex-row">
        <PrimaryButton
          type="button"
          label={COMMON_MESSAGES.back}
          onClick={backToPreviousPage}
        />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { PrimaryButton } from '../../shared/components/PrimaryButton';

import { InnerHeader } from '../components/InnerHeader';
import { ErrorBar } from '../../v1/components/ErrorBar';
import { ProcessedData } from '../../shared/types/data';
import { ProductTable } from '../components/ProductTable';
import { useSpinner } from '../../shared/context/SpinnerProvider';
import { SearchBar } from '../components/SearchBar';
import { ERROR_MESSAGES } from '../../shared/utils/errorMessages';
import { FormikState } from 'formik';
import { checkContentByContainerId, checkContentByTrackingCode } from '../../shared/network/lib/quality';
import { formatShippingPositionFromAPI } from './ScanProductsPage';
import { resetContainer } from '../../shared/network/lib/resetContainer';
import { InfoBar } from '../../v1/components/InfoBar'
import { reprintVersand } from '../../shared/network/lib/reprintVersand'
import { INFO_MESSAGES } from '../../shared/utils/infoMessages'
import { PopupDialog } from '../components/PopupDialog'

export const QaCheckPage = () => {
  const [errMsg, setErrMsg] = useState("");
  const [containerId, setContainerId] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [pickDate, setPickDate] = useState("");
  const [packDate, setPackDate] = useState("");
  const [picker, setPicker] = useState("");
  const [packer, setPacker] = useState("");
  const [shippingId, setShippingId] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [shipmentPosition, setShipmentPosition] = useState<ProcessedData[]>([]);
  const {toggleSpinner} = useSpinner();


  const handleSearch = async (value: string, resetValue: (nextState?: Partial<FormikState<{ packagecode: string }>> | undefined) => void) => {
    resetViewValues();
    try {
      setInfoMsg('');
      toggleSpinner({showSpinner: true});
      let response;
      if (value.length === 5) {
        response = await checkContentByContainerId(value);
      } else if (value.length > 5) {
        response = await checkContentByTrackingCode(value);
      } else {
        toggleSpinner({showSpinner: false});
        setErrMsg(ERROR_MESSAGES.qualityCodeNotMatch)
        return;
      }
      if (response.status === 200) {
        setShipmentPosition(
          formatShippingPositionFromAPI(response?.data?.shipping_positions, ['product_name', 'storage_location', 'quantity'])
        );
        setContainerId(response?.data?.container_id);
        setDeliveryNumber(response?.data?.lieferschein);
        setPickDate(response?.data?.picked_at);
        setPicker(response?.data?.picker_name);
        setPacker(response?.data?.packer_name);
        setPackDate(response?.data?.picked_at);
        setShippingId(response?.data?.versand_id);
        setShowDialog(true);
        setErrMsg('');
      }
      toggleSpinner({showSpinner: false});
    } catch (error: any) {
      if (error?.response?.status === 404) {
        const message = ERROR_MESSAGES.packingNotFound.replace(
          "{code}",
          value
        );
        setErrMsg(error?.response?.data?.message || message);
      } else {
        setErrMsg(
          error?.response?.data?.message ||
          ERROR_MESSAGES.noPickingDataAvailableError
        );
      }
      toggleSpinner({showSpinner: false});
    }
    resetValue({values: {packagecode: ''}});
  };

  const handleResetContainer = () => {
    toggleSpinner({showSpinner: true});
    resetContainer(containerId).then((response) => {
      if (response.status === 200) {
        setInfoMsg(response?.data?.message);
        toggleSpinner({showSpinner: false});
        setShowDialog(false);
        setErrMsg('');
      }
    });
  };

  const handleReprintSubmit = async () => {
    try {
      toggleSpinner({showSpinner: true});
      let response = await reprintVersand(shippingId);
      if (response.status === 200 || response.status === 201) {
        setInfoMsg(INFO_MESSAGES.reprintSuccessInfo);
        toggleSpinner({showSpinner: false});
      } else if (response.status >= 400) {
        setErrMsg(
          response?.data?.message?.error_message || ERROR_MESSAGES.reprintError
        );
      }
      setShowDialog(false);
    } catch (error: any) {
      setErrMsg(
        error?.data?.message?.error_message || ERROR_MESSAGES.reprintError
      );
    }
  };

  const resetViewValues = () => {
    setContainerId('');
    setDeliveryNumber('');
    setPickDate('');
    setPicker('');
    setPacker('');
    setPackDate('');
  };

  return (

    <div className='bg-app min-h-screen p-4 flex flex-col justify-between'>

      <div className='mt-4 w-full flex flex-col'>
        <InnerHeader title={COMMON_MESSAGES.qaCheckPageTitle} showBackButton={true}/>
        <div className='mt-12 w-full flex flex-col gap-4 items-center'>
          <SearchBar onClick={handleSearch} labelText={COMMON_MESSAGES.scanByTracking}/>

          {errMsg && <ErrorBar message={errMsg}/>}
          {infoMsg && <InfoBar message={infoMsg}/>}

        </div>
      </div>


      <PopupDialog showDialog={showDialog}
                   title={!!packer ? COMMON_MESSAGES.trackingCodeView : COMMON_MESSAGES.containerView}
                   hideDialog={() => setShowDialog(false)}>
        <div className='flex flex-col h-full mt-4'>
          <div className='flex flex-col gap-1 justify-center items-center'>
            {!!containerId && <p><b>Container:</b> {containerId} </p>}
            {!!deliveryNumber && <p><b> LS:</b> {deliveryNumber}</p>}
            {!!picker &&
                <div>
                    <b> Picker:</b> {picker} <span className='text-gray-500'>( {pickDate} )</span>
                </div>
            }
            {!!packer &&
                <div>
                    <b> Packer:</b> {packer} <span className='text-gray-500'>( {packDate} )</span>
                </div>
            }

            <ProductTable shipmentPosition={shipmentPosition}/>
          </div>

          <div className='flex flex-col gap-2 justify-center items-center mx-4 mt-12'>
            {!!packer ?
              <PrimaryButton
                type="button"
                onClick={handleReprintSubmit}
                label={COMMON_MESSAGES.rePrintLabel}
              />
              :
              <PrimaryButton
                type='button'
                label={COMMON_MESSAGES.resetContainer}
                onClick={handleResetContainer}
              />
            }

            <PrimaryButton
              className='rounded-md w-24 w-full'
              type='button'
              label={COMMON_MESSAGES.back}
              onClick={() => setShowDialog(false)}
            />
          </div>
        </div>
      </PopupDialog>

    </div>
  );
}

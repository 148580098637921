const saveMopScans = (
  transformedProducts: any,
  productIndex: any,
  currentContainerId: any,
  disputeContainer: any = [],
  isDone: any = false,
): void => {
  try {
    const data = {
      transformedProducts,
      productIndex,
      currentContainerId,
    };
    const jsonData: string = JSON.stringify(data);
    localStorage.setItem("storedMopScans", jsonData);

    // Add current timestamp to localStorage
    const timestamp: number = Date.now();
    localStorage.setItem("lastSaved", timestamp.toString());
    localStorage.setItem("hasScan", true.toString());
    localStorage.setItem("isDone", isDone.toString());

    if (Array.isArray(disputeContainer) && disputeContainer.length) {
      // array exists and is not empty
      localStorage.setItem(
        "disputeContainer",
        JSON.stringify(disputeContainer)
      );
    }
  } catch (error) {
    // Error occurred while saving the data
    console.error("Error saving data to storedMopScans:", error);
  }
};

export default saveMopScans;

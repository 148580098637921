import React from "react";
import { isRgbWarehouse } from "../../shared/utils/common";

export const DeprecationOverlay = () => {
  const isRgb = isRgbWarehouse();
  return (
    !isRgb ? (
    <>
      <div className="absolute top-0 left-0 z-50 w-full">
        <div className="bg-app flex flex-col items-center text-center rounded h-[100vh]">
          <h1 className="text-3xl font-bold mt-5">
            The Endscan-App has been discontinued!
          </h1>
          <div className="my-5"></div>
          <div className="my-6">
            <h1 className="inline">This app is no longer in use.</h1> <br></br>
            <h1 className="inline">Please speak to your Team Captain!</h1>
          </div>
          <div className="w-[80vw] flex flex-col gap-4"></div>
        </div>
      </div>
    </>
    ) : null
  );
};

import React from "react";
import { useState } from "react";

interface ModalProps{
  options: any
  submit: any
}
export const Modal = ({ options, submit }: ModalProps) => {
  const [selectedIndex, setSelectedIndex] = useState();
  
  return (
    <div className="flex items-center justify-center mt-4">
    <label htmlFor="my-modal-3" className="btn btn-error text-white">
      Klärfall Anlegen
    </label>

    <input type="checkbox" id="my-modal-3" className="modal-toggle" />
    <div className="modal">
      <div className="modal-box relative">
        <label
          htmlFor="my-modal-3"
          className="btn btn-sm btn-circle absolute right-2 top-2"
        >
          ✕
        </label>
        <h3 className="text-lg font-bold">Klärfall Grund Wählen</h3>

        {options && options.map(function (item: any, i: any) {
          return (
            <div className="form-control" key={i}>
              <label className="label cursor-pointer">
                <span className="label-text">{item.dispute_type}</span>
                <input
                  type="radio"
                  name="radio-10"
                  className="radio checked:bg-blue-500"
                  checked= {selectedIndex === i}
                  onChange={() => setSelectedIndex(i)}
                />
              </label>
            </div>
          );
        })}

        <div className="flex gap-2 justify-between mt-4">
          <label htmlFor="my-modal-3" className="btn btn-md btn-error">
            Abbrechen
          </label>

          <label
            htmlFor="my-modal-3"
            className="btn btn-md btn-success"
            onClick={() => submit(selectedIndex)}
          >
            Speichern
          </label>
        </div>
      </div>
    </div>
  </div>
  );
};

import { CONFIGURATIONS as C}  from "../../shared/utils/configurations";

export const laneDefault = [
  { kommissionierung_id: C.LANE.MORE_EU.kommissionierung_id, lane_name: C.LANE.MORE_EU.lane_name },
  { kommissionierung_id: C.LANE.MORE_NONEU.kommissionierung_id, lane_name: C.LANE.MORE_NONEU.lane_name },
  { kommissionierung_id: C.LANE.SYN_EU.kommissionierung_id, lane_name: C.LANE.SYN_EU.lane_name },
  { kommissionierung_id: C.LANE.SYN_NONEU.kommissionierung_id, lane_name: C.LANE.SYN_NONEU.lane_name },
  { kommissionierung_id: C.LANE.GOT7_EU.kommissionierung_id, lane_name: C.LANE.GOT7_EU.lane_name },
  { kommissionierung_id: C.LANE.GOT7_NONEU.kommissionierung_id, lane_name: C.LANE.GOT7_NONEU.lane_name },
  { kommissionierung_id: C.LANE.FTESN_EU.kommissionierung_id, lane_name: C.LANE.FTESN_EU.lane_name },
  { kommissionierung_id: C.LANE.FTESN_NONEU.kommissionierung_id, lane_name: C.LANE.FTESN_NONEU.lane_name },
  { kommissionierung_id: C.LANE.FTESN_EXPRESS.kommissionierung_id, lane_name: C.LANE.FTESN_EXPRESS.lane_name },
  { kommissionierung_id: C.LANE.SONDERFALL_EU.kommissionierung_id, lane_name: C.LANE.SONDERFALL_EU.lane_name },
  { kommissionierung_id: C.LANE.SONDERFALL_NONEU.kommissionierung_id, lane_name: C.LANE.SONDERFALL_NONEU.lane_name },
  { kommissionierung_id: C.LANE.MORE_FL.kommissionierung_id, lane_name: C.LANE.MORE_FL.lane_name },
  { kommissionierung_id: C.LANE.GOT7_FL.kommissionierung_id, lane_name: C.LANE.GOT7_FL.lane_name },
  { kommissionierung_id: C.LANE.SYN_FL.kommissionierung_id, lane_name: C.LANE.SYN_FL.lane_name },
  { kommissionierung_id: C.LANE.FTESN_FL.kommissionierung_id, lane_name: C.LANE.FTESN_FL.lane_name },
  { kommissionierung_id: C.LANE.FTESN_EXPRESS_FL.kommissionierung_id, lane_name: C.LANE.FTESN_EXPRESS_FL.lane_name },
  { kommissionierung_id: C.LANE.SONDERFALL_FL.kommissionierung_id, lane_name: C.LANE.SONDERFALL_FL.lane_name },
  { kommissionierung_id: C.LANE.MOP.kommissionierung_id, lane_name: C.LANE.MOP.lane_name }
];

export const rgb1Lanes = [1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 16, 17]

export const rgb2Lanes =  [18, 19, 20]

export const rgb2PlusLanes = [18, 19, 20]

export const kakiAllLanes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13]

export const defaultRanpakPickLane = { kommissionierung_id: 1, lane_name: "ESN" };
import React from 'react';

interface SlimTableProps {
  data: any[];
  columnAliases: { [key: string]: string };
}

const SlimTable: React.FC<SlimTableProps> = ({ data, columnAliases }) => {
  const columns = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div className="max-h-[600px] overflow-x-auto overflow-y-scroll shadow-md sm:rounded-md">
      <table className=" table table- w-full  border-separate">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{columnAliases[column] || column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SlimTable;

export const getBaseUrl = () => {
  switch(window.location.hostname) {
    case 'rgb-endscan.testing.haute-cuisine.io':
      return 'https://api-rgb-endscan.testing.haute-cuisine.io';
    case 'rgb-endscan.haute-cuisine.io':
      return 'https://api-rgb-endscan.haute-cuisine.io';
    case 'kaki-endscan.haute-cuisine.io':
      return 'https://api-kaki-endscan.haute-cuisine.io';
    default:
      return 'http://localhost:8000';
  }
}

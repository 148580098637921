function retrieveMopScans(): any {
    const valueString = localStorage.getItem('storedMopScans');
    const lastSavedString = localStorage.getItem('lastSaved');
    const savedDisputeContainer = localStorage.getItem('disputeContainer');
    const fullPayloadString = localStorage.getItem('fullPayload');
    const hasScanString = localStorage.getItem('hasScan');
    const isDoneString = localStorage.getItem('isDone');
    let lastSaved = null;
    let disputeContainer: any = []
    let fullPayload: any = ''
    let hasScan: boolean = false
    let isDone: boolean = false
  
    if (lastSavedString) {
      try {
        lastSaved = JSON.parse(lastSavedString);
      } catch (error) {
        console.error('Error parsing lastSaved value:', error);
      }
    }

    if (savedDisputeContainer) {
      try {
        disputeContainer = JSON.parse(savedDisputeContainer);
      } catch (error) {
        console.error('Error parsing lastSaved value:', error);
      }
    }

    if (fullPayloadString) {
      try {
        fullPayload = JSON.parse(fullPayloadString);
      } catch (error) {
        console.error('Error parsing lastSaved value:', error);
      }
    }

    if (hasScanString) {
      try {
        hasScan = JSON.parse(hasScanString);
      } catch (error) {
        console.error('Error parsing lastSaved value:', error);
      }
    }

    if (isDoneString) {
      try {
        isDone = JSON.parse(isDoneString);
      } catch (error) {
        console.error('Error parsing lastSaved value:', error);
      }
    }
  
    if (valueString) {
      try {
        const value = JSON.parse(valueString);
        return {
          storedMopScans: value,
          lastSaved: lastSaved,
          disputeContainer: disputeContainer,
          fullPayload: fullPayload,
          hasScan: hasScan,
          isDone: isDone
        };
      } catch (error) {
        console.error('Error parsing storedMopScans value:', error);
        return null;
      }
    }
    else {
      return {
        disputeContainer: disputeContainer,
        fullPayload: fullPayload,
        hasScan: hasScan,
        isDone: isDone
      };
    }
  
  }
  
  export default retrieveMopScans;
  
import React from "react";
import { useField, useFormikContext } from 'formik';
import { Listbox } from '@headlessui/react';
import { SelectItem } from '../../shared/types/selectItem'

interface SelectorProps {
  name: string;
  placeholder: string;
  options: SelectItem[],
  setSelected?: (val: any) => void
}

export const Selector = ({options, name, placeholder, setSelected}: SelectorProps) => {

  const {setFieldValue} = useFormikContext();
  const [field] = useField(name);
  const selectedOption = options.find(({value}) => value === field.value);

  return (
    <Listbox
      as="div"
      onChange={(value) => {
        setFieldValue(name, value, true);
        if (setSelected) {
          setSelected({label: name, value: value});
        }
      }
      }
      className="relative mb-4 flex flex-col font-body"
      name={name}
    >
      <Listbox.Button
        as="input"
        placeholder={placeholder}
        value={selectedOption ? selectedOption.label : ''}
        readOnly
        className="bg-gray-700 p-2 font-bold rounded text-white"
      />
      <Listbox.Options
        className="absolute top-8 left-0 z-10 flex w-full flex-col rounded bg-gray-700 text-white shadow-lg">
        {options.map(({value, label, disabled}) => (
          <Listbox.Option
            key={value}
            value={value}
            disabled={disabled}
            className={({active}) =>
              `w-full cursor-pointer py-2 px-3 ${
                active ? 'bg-sky-600' : 'text-white'
              }`}
          >
            {label}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );

};
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { urlForVersion } from "../../shared/utils/common";
import { PickingTableDummy } from "../components/PickingTableDummy";
import { auftragData } from "../../shared/config/dummyOrders";
import { RgbStorageLocationSortingService } from "../services/RgbStorageLocationSortingService";

export function PickingPageDummy() {
  const [shipmentPosition, setShipmentPosition] = useState<any[]>([]);
  const [info, setInfo] = useState<Boolean>(false);
  const navigate = useNavigate();

  const handleBackToStart = () => {
    navigate(urlForVersion("/start-dmy"));
  };

  useEffect(() => {
    setTimeout(() => {
      setInfo(false);
    }, 3500);
  }, [info]);

  interface Cell {
    columnName: string;
    value: string | number;
    columnStyles: string;
    rowStyles: string;
  }

  interface ResultItem {
    id: string;
    ean: string;
    key: string;
    done: boolean;
    amountNeeded: number;
    amountScanned: number;
    cells: Cell[];
  }


  function convertOrdersToResult(orders: any[]): ResultItem[] {
    return orders.map((order: { id: any; ean: any; amount_needed: any; product_name_value: any; storage_location_value: any; quantity_value: any; }) => {
      return {
        id: String(order.id),
        ean: order.ean,
        key: String(order.id),
        done: false,
        amountNeeded: order.amount_needed,
        amountScanned: 0,
        cells: [
          {
            columnName: "product_name",
            value: order.product_name_value,
            columnStyles: "text-xxs text-left w-[30vw] border-gray-500 border-y-2 break-all",
            rowStyles: "font-semibold text-xxs text-left border-gray-400 border-b-2 border-r-2 break-all"
          },
          {
            columnName: "storage_location",
            value: order.storage_location_value,
            columnStyles: "text-xxs text-center w-[35vw] border-gray-500 border-y-2 break-all",
            rowStyles: "text-xs text-center font-bold border border-gray-400 border-b-2 border-r-2 break-all"
          },
          {
            columnName: "quantity",
            value: order.quantity_value,
            columnStyles: "text-xxs text-center w-[12vw] border-gray-500 border-y-2 break-all",
            rowStyles: "text-xs text-center border border-gray-400 border-x-2 border-b-2 break-all"
          },
          {
            columnName: "ist",
            value: 0,
            columnStyles: "text-xxs text-center w-[12vw] border-gray-500 border-y-2 break-all",
            rowStyles: "text-xs text-center border border-gray-400 border-b-2 break-all"
          }
        ]
      };
    });
  }

  useEffect(() => {
    let convertedData = convertOrdersToResult(auftragData)
    const sortedItems = convertedData.sort(RgbStorageLocationSortingService);
    setShipmentPosition(sortedItems);
  }, []);

  
  return (
    <>
      <h2 className="text-2xl font-bold text-center my-4">Picking Page</h2>
      <div className="flex flex-col items-center">
        <div className="absolute top-4 left-4">
          <button className="btn btn-ghost gap-2" onClick={handleBackToStart}>
            <svg
              className="h-6 w-6 fill-current md:h-8 md:w-8"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path>
            </svg>
          </button>
        </div>

        {/* {error && (
          <button className="btn btn-outline mt-4" onClick={refreshPage}>
            Seite neu laden
          </button>
        )} */}

        <div>
          <PickingTableDummy processed={shipmentPosition} />
        </div>
      </div>
    </>
  );
}

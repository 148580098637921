import React from "react";
import Slider from "./Slider";
import { useState } from "react";

interface ScanMultipleModalProps {
    product: any;
    maxScanAmount: number;
    handleClose: () => void;
    handleSubmit: any;
    productName: any;
    productEan: any;
}

const ScanMultipleModal: React.FC<ScanMultipleModalProps> = ({
    maxScanAmount, handleClose, handleSubmit, product, productName, productEan
}) => {
  const [value, setValue] = useState<number>(1);

  const handleSliderChange = (newValue: number) => {
    setValue(newValue);
  };
  console.log(product)
  return (
    <div className="flex items-center justify-center mt-4">
      <label
        htmlFor="my-modal-14"
        className="btn text-white invisible absolute"
      ></label>
      <input
        type="checkbox"
        id="my-modal-14"
        className="modal-toggle invisible absolute"
        onChange={() => {console.log('Changed')}}
        checked
      />
      <div className="modal">
        <div className="modal-box relative">
          <div onClick={handleClose}>
            <label
              htmlFor="my-modal-14"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
          </div>
          <div>
            <h3 className="text-lg font-bold">Menge Eintragen</h3>
            <div>
              <Slider
                value={value}
                min={0}
                max={maxScanAmount}
                step={1}
                onChange={handleSliderChange}
              />
            <span className="text-sm">{productName}</span>
            </div>
            <div className="flex gap-2 justify-between mt-4">
              <label
                htmlFor="my-modal-14"
                className="btn btn-md btn-error"
                onClick={handleClose}
              >
                Abbrechen
              </label>

              <label
                htmlFor="my-modal-14"
                className="btn text-white bg-primary-button btn-md w-1/2 outline-0"
                onClick={() => handleSubmit(productEan, value)}
              >
                Scan
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanMultipleModal;

import React, { useEffect, useState } from "react";
import { ErrorBar } from "../../v1/components/ErrorBar";
import { ProcessedData } from "../../shared/types/data";
import { useScan } from "../hooks/useScan";
import { SearchBar } from "./SearchBar";
import { PickingDataResponse } from "../../shared/types/picking";
import { COMMON_MESSAGES } from "../../shared/utils/commonMessages";
import { ProductTable } from "./ProductTable";
import { PrimaryButton } from "../../shared/components/PrimaryButton";
import { isB2B, isPick, isRanpakPack, isRgbWarehouse } from "../../shared/utils/common";
import { PopupDialog } from "./PopupDialog";
import ScanMultipleModal from "./ScanMultipleModal";

interface ScanProductsTableProps {
  processed: ProcessedData[];
  fullPayload: PickingDataResponse;
  checkScanFinished: (finished: boolean) => void;
  containerId: string;
}

// Redeploy Force
export const ScanProductsTable = ({
  processed,
  fullPayload,
  checkScanFinished,
  containerId,
}: ScanProductsTableProps) => {
  const {
    productEan,
    productName,
    handleModalClose,
    showModal,
    maxScanAmount,
    multipleProduct,
    handleScan,
    handlePreScan,
    shipmentPosition,
    error,
    errMsg,
    errMsgLoad,
    scanFinished,
    handleScanDoneNextStep,
  } = useScan({ processed, fullPayload });
  const [showDialog, setShowDialog] = useState(false);
  const [lastScanMsg, setLastScanMsg] = useState("");

  const handleLastScanContainer = (value: string) => {
    setLastScanMsg("");
    if (value === containerId) {
      finishScanProcess();
    } else {
      setLastScanMsg(COMMON_MESSAGES.containerNotMatch);
    }
  };

  const finishScanProcess = () => {
    handleScanDoneNextStep();
  };
  
  useEffect(() => {
    checkScanFinished(scanFinished);
    if (scanFinished && !isRgbWarehouse()) {
      setShowDialog(true);
    }
    if (scanFinished && isRgbWarehouse()) {
      finishScanProcess()
    }
  }, [scanFinished]);
  
  const scanFinishedPackLabel = isRanpakPack() ? COMMON_MESSAGES.finishPack : COMMON_MESSAGES.printLabel;

  return (
    <>
      {!scanFinished && (
        <div className="flex flex-col justify-center items-center">
          <SearchBar
            onClick={handlePreScan}
            labelText={COMMON_MESSAGES.scanProductBarcode}
          />
          {error && <ErrorBar message={errMsg} />}
        </div>
      )}
      <ProductTable shipmentPosition={shipmentPosition} />
      {errMsgLoad && (
        <div className="flex justify-center">
          <ErrorBar message={errMsgLoad} />
        </div>
      )}
      {showModal && (
        <ScanMultipleModal
          productName={productName}
          productEan={productEan}
          product={multipleProduct}
          handleClose={handleModalClose}
          handleSubmit={handleScan}
          maxScanAmount={maxScanAmount}
        />
      )}
      {scanFinished && (
        <div className="w-full flex flex-col justify-between py-8">
          {!isPick() || isRgbWarehouse() ? (
            <PrimaryButton
              type="button"
              label={
                isPick()
                  ? COMMON_MESSAGES.finishJob
                  : scanFinishedPackLabel
              }
              onClick={() => finishScanProcess()}
            />
          ) : (
            <PopupDialog
              showDialog={showDialog}
              hideDialog={() => setShowDialog(false)}
              title={isB2B() ? COMMON_MESSAGES.finalContainerScanTitleB2B : COMMON_MESSAGES.finalContainerScanTitle}
            >
              <SearchBar
                onClick={handleLastScanContainer} />
              {!!lastScanMsg && <ErrorBar message={lastScanMsg} />}
            </PopupDialog>
          )}
        </div>
      )}
    </>
  );
};

export const handleErrors = (
    error: any,
    customErrorMessages: { [key: number]: string },
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    setErrMsg: React.Dispatch<React.SetStateAction<string>>,
    defaultMessage: string = "Error"
  ) => {
    setError(true);
  
    if (!error?.response) {
      setErrMsg("Keine Server-Antwort.");
    } else {
      const errorMessage =
        customErrorMessages[error?.response?.status] || defaultMessage;
      setErrMsg(errorMessage);
    }
  };
  
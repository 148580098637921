import { useState, useEffect } from "react";
import UnauthorizedAlert from "./UnauthorizedAlert";
import { Outlet, useLocation } from "react-router-dom";
import React from "react";
import { checkAuth } from "../../shared/network/lib/auth";
import { useSpinner } from "../../shared/context/SpinnerProvider";
import { updateActionTsForSession } from "../../shared/network/lib/updateActionTsForSession";

export const RequireAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [userInfo, setUserInfo] = useState({});
  const { toggleSpinner } = useSpinner();
  const location = useLocation();

  const fetchData = async () => {
    toggleSpinner({ showSpinner: true });
    setLoading(true);
    let response = await checkAuth()
      .then((response: { status: number; data: any }) => {
        if (response.status === 200) {
          setIsAuthenticated(true);
          setIsDone(true);
          toggleSpinner({ showSpinner: false });
          setLoading(false);
          setUserId(response?.data?.user_id);
          setUserInfo(response?.data);
        } else if (response.status === 401) {
          setIsDone(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
        toggleSpinner({ showSpinner: false });
        setLoading(false);
        setIsDone(true);
      });
    return response;
  };


  const handleUpdateActionTimestamp = async () => {
    console.log('updating action timestamp')
    try {
      await updateActionTsForSession();
    } catch (error) {
      console.log("Error");
    }
  };

  useEffect(() => {
    handleUpdateActionTimestamp();
  }, [location.pathname]);

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? null : isAuthenticated && isDone ? (
    <Outlet context={{ userId, userInfo }} />
  ) : (
    <UnauthorizedAlert />
  );
};

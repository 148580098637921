import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { COMMON_MESSAGES } from "../../../../shared/utils/commonMessages";
import { SearchBarCustomFocus } from "../../SearchBarCustomFocus";

const qrScanIcon = require("../../../../assets/qr_scan.png");
const readyStockIcon = require("../../../../assets/PNG/ready-stock_4947506.png");

interface CompleteContainerOverlayProps {
  showDialog: boolean;
  setShowDialog: any;
  currentContainerId: string;
  handleScanCallback: () => void;
}

export const CompleteContainerOverlay = ({
  showDialog,
  setShowDialog,
  currentContainerId,
  handleScanCallback,
}: CompleteContainerOverlayProps) => {
  const handleScan = (value: string) => {
    if (currentContainerId === value) {
      setShowDialog(false);
      handleScanCallback();
    }
  }

  return (
    <Transition appear show={showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed flex items-center justify-center z-50 top-[6px] right-[6px] left-[6px]"
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 w-full" />
        </Transition.Child>
        <div className="relative bg-white max-w-md mx-auto px-[14px] py-[15px] rounded-md shadow-lg w-full mr-[5px] ml-[5px]">
          <Dialog.Title>
            <div className="bg-blue-100 text-blue-900 py-[7px] px-[48px] text-center text-[23px]">
              <div>Complete Container</div>
              <div className="font-bold">{currentContainerId}</div>
            </div>
          </Dialog.Title>
          <div className="mt-4">
            <div className="flex gap-2 mt-[19px] justify-center">
              <img className="max-w-[70px] max-h-[70px]" src={readyStockIcon} alt="stock-ready-icon" />
              <img className="max-w-[70px] max-h-[70px]" src={qrScanIcon} alt="qr-scan-icon" />
            </div>

            <div className="mt-[19px]">
              <SearchBarCustomFocus
                fieldId="scan_container_barcode"
                focusOnIdWhenDone="scan_container_barcode"
                onClick={handleScan}
                labelText={COMMON_MESSAGES.scanContainerBarcode}
                changeFocusWhenDone={false}
                inputWidth="w-1/2"
                buttonWidth="w-1/2"
                showValidationMsg={false}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

import React from "react";
import * as Yup from 'yup';
import { VALIDATION_MESSAGES } from '../../shared/utils/validationMessages';
import { ErrorMessage, Field, Form, Formik, FormikState } from 'formik';
import { COMMON_MESSAGES } from '../../shared/utils/commonMessages';
import { PrimaryButton } from '../../shared/components/PrimaryButton';

interface SearchBarProps{
  onClick: (
    inputText:string, 
    callback: (nextState?: Partial<FormikState<{packagecode: string}>> | undefined) => void
  ) => void,
  labelText?: string,
  buttonText?: string,
  inputWidth?: string,
  buttonWidth?: string,
  showValidationMsg?: boolean,
  elementKey?: string
}
export const SearchBar = ({ onClick, labelText = COMMON_MESSAGES.scanBarcode, buttonText=COMMON_MESSAGES.scan, inputWidth= "w-3/4", buttonWidth = "w-1/4", showValidationMsg=true, elementKey='packagecode'}: SearchBarProps) => {
  const setFocus = () => {
   const input = document.getElementById(elementKey);
   input?.focus();
  }
  return (
    <Formik
      initialValues={{packagecode: ''}}
      validationSchema={Yup.object({
        packagecode: Yup.string().required(showValidationMsg? VALIDATION_MESSAGES.inputNotFilled :""),
      })}
      onSubmit={async (data, {resetForm}) => {
        try {
          onClick(data.packagecode.trim(), resetForm);
          resetForm()
          setFocus();
        } catch (error: any) {

        }
      }}
    >
      <Form id='search-form' className="w-full">
        <label className="label label-text" htmlFor="packagecode"> {labelText} </label>
        <div className="flex flex-row items-end w-full">
          <div className={inputWidth}>
            <Field autoFocus={true}
              name="packagecode"
              type="search"
              className="rounded-l-lg h-12 w-full border border-l-sky-400 border-y-sky-400 px-2"
              id={elementKey}
              inputMode="none"
              />
          </div>
          <div className={buttonWidth}>
            <PrimaryButton
              type='submit'
              label={buttonText}
              className="rounded-r-lg h-12"
            />
          </div>
        </div>
        {showValidationMsg && (
          <div className='h-4'>
            <ErrorMessage className="text-red-700" name={elementKey} component='div'/>
          </div>
        )}
      </Form>
    </Formik>
  );
};

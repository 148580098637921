import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getBaseUrl } from "../utils/baseUrl";

const BASE_URL = getBaseUrl();
// const BASE_URL = "https://endscan.testing.haute-cuisine.io"
// const BASE_URL = "http://localhost:8000";

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

interface AxiosRetryConfig extends AxiosRequestConfig {
  retry?: number;
}

const handleRequestRetry = (error: any) => {
  const config = error.config as AxiosRetryConfig;
  if (
    error.response &&
    (error.response.status === 429 ||
      (error.response.status >= 400 && error.response.status <= 599))
  ) {
    if (!config.retry) {
      config.retry = 0;
    }

    if (config.retry < 1) {
      console.log('RETRY')
      config.retry += 1;

      console.log(`Retrying request (${config.retry})`);

      return new Promise<AxiosResponse>((resolve) => {
        setTimeout(() => resolve(axiosPrivate(config)), 250); // wait 250ms between retries
      });
    }
  }

  return Promise.reject(error);
};

axiosPrivate.interceptors.response.use(
  (response) => response,
  handleRequestRetry
);

export default axiosPrivate;

import React, { useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, FormikState } from 'formik';
import { COMMON_MESSAGES } from '../../shared/utils/commonMessages';
import { PrimaryButton } from '../../shared/components/PrimaryButton';
import { useState } from "react";

interface SearchBarCustomFocusProps{
  onClick: (
    inputText:string, 
    callback: (nextState?: Partial<FormikState<{packagecode: string}>> | undefined) => void
  ) => void,
  labelText?: string,
  buttonText?: string,
  fieldId: string,
  focusOnIdWhenDone: string,
  changeFocusWhenDone: boolean,
  inputWidth?: string,
  buttonWidth?: string,
  showValidationMsg?: boolean
}
export const SearchBarCustomFocus = ({ 
  onClick,
  labelText = COMMON_MESSAGES.scanBarcode,
  buttonText=COMMON_MESSAGES.scan,
  fieldId,
  focusOnIdWhenDone,
  changeFocusWhenDone,
  inputWidth= "w-3/4",
  buttonWidth = "w-1/4",
  showValidationMsg = true
}: SearchBarCustomFocusProps) => {
  const [autoFocus, setAutoFocus] = useState(true)

  const setFocus = () => {
   const input = document.getElementById(`${focusOnIdWhenDone}`);
   input?.focus();
  }

  useEffect(() => {
    /*
     * this is necessary because in some cases the input
     * has initially no focus. There seems to be an issue
     * with transitions and autofocus.
     */
    if (autoFocus) {
      document.getElementById(fieldId)?.focus();
    }
  }, []);

  return (
    <Formik
      initialValues={{packagecode: ''}}
      onSubmit={async (data, {resetForm}) => {
        try {
          onClick(data.packagecode.trim(), resetForm);
          resetForm()
          if(changeFocusWhenDone){setAutoFocus(false)}
          setFocus();
        } catch (error: any) {

        }
      }}
    >
      <Form id='search-form' className="w-full">
        <label className="label label-text" htmlFor="packagecode"> {labelText} </label>
        <div className="flex flex-row items-end w-full">
          <div className={inputWidth}>
            <Field autoFocus={autoFocus}
              name="packagecode"
              type="search"
              className="rounded-l-lg h-12 w-full border border-l-sky-400 border-y-sky-400 px-2"
              id={fieldId}
              inputMode="none"/>
          </div>
          <div className={buttonWidth}>
            <PrimaryButton
              type='submit'
              label={buttonText}
              className="rounded-r-lg h-12"
            />
          </div>
        </div>
        {showValidationMsg && (
          <div className='h-4'>
            <ErrorMessage className="text-red-700" name="packagecode" component='div'/>
          </div>
        )}
      </Form>
    </Formik>
  );
};

import React from "react";
import { Link } from "react-router-dom";

const UnauthorizedAlert = () => {
  return (
    <div className="h-screen flex justify-center items-center">
    <div className="alert shadow-lg w-[80vw]">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info flex-shrink-0 w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>You are not authenticated. Please Log In.</span>
      </div>
      <div className="flex-none">
        <Link to="/login">
          <button className="btn btn-sm btn-primary">Go to Login</button>
        </Link>
      </div>
    </div>
    </div>
  );
};

export default UnauthorizedAlert
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import retrieveMopScans from "../../../utils/retrieveMOPScans";
import { PrimaryButton } from "../../../../shared/components/PrimaryButton";
import { InnerHeader } from "../../../components/InnerHeader";
import { LogoutButton } from "../../../../shared/components/LogoutButton";
import { COMMON_MESSAGES } from "../../../../shared/utils/commonMessages";
import { isPick, urlForVersion, isB2B, isMultiPicking, isMultiPickingRanpak, isDynamicRole } from "../../../../shared/utils/common";
import { userLogout } from "../../../../shared/network/lib/logout";
import { isDynamicContainerPayload } from "../../../../shared/utils/isContainerPayloadDynamic";

export const KakiMopStartPage = () => {
  const navigate = useNavigate();
  const { userInfo }: any = useOutletContext();
  const [showContinuewithLastJobButton, setShowContinuewithLastJobButton] =
    useState(false);

  const handleLogout = async () => {
    try {
      await userLogout().then((response) => {
        let url;
        if(isB2B()){url = '/b2b'}
        else {url = '/login'}
        if (response.status === 200) {
          navigate(url);
        }
      });
    } catch (error) {
      console.log("Error");
    }
  };
  console.log(userInfo)

  const handleStart = () => {
    if (isMultiPicking() || isMultiPickingRanpak()){
      const url = "/mop/kaki/container_selection"
      navigate(urlForVersion(url))
    }
    else{
    navigate(urlForVersion("/scan"))
    }
  }

  const handleContinueWithPrevious = async () => {
    try {
      navigate(urlForVersion("/mop/kaki/picking"), {
        state: { fullPayload: {} },
      });
    } catch (error: any) {
      console.log(error);
      console.log(error?.response?.status);
    }
  };

  const isFullEmptyPayloadObject = (obj: any) => {
    if (typeof obj === "undefined") {
      return false;
    }

    return (
      typeof obj === "object" && // Check if it's an object
      obj !== null && // Ensure it's not null
      Object.keys(obj).length === 1 && // Ensure it has only one key
      obj.hasOwnProperty("fullPayload") && // Check if it has the 'fullPayload' key
      typeof obj.fullPayload === "object" && // Check if the value of 'fullPayload' is an object
      obj.fullPayload !== null && // Ensure the value of 'fullPayload' is not null
      Object.keys(obj.fullPayload).length === 0 // Check if the 'fullPayload' object is empty
    );
  };

  const storagePayloadMatchesRole = () => {
    return isDynamicRole() === isDynamicContainerPayload(retrieveMopScans())
  }

  useEffect(() => {
    const storage = retrieveMopScans();
    if (storage.hasOwnProperty("storedMopScans")) {
      // 'storedMopScans' attribute exists in the storage object

      // Check if 'storedMopScans' is an object and not null
      if (
        typeof storage.storedMopScans === "object" &&
        storage.storedMopScans !== null
      ) {
        if (
          Object.keys(storage.storedMopScans).length != 0 &&
          !isFullEmptyPayloadObject(storage?.fullPayload)
        ) {

          let storageUserId = 0;
          try {
            storageUserId = storage.fullPayload.fullPayload[0].user?.user_id;
          } catch (error) {
            console.log(error);
          }

          if (storagePayloadMatchesRole() && storageUserId == userInfo?.user_id && !storage?.isDone) {
            setShowContinuewithLastJobButton(true);
          }
        }
      }
    }
  }, [userInfo]);

  return (
    <div className="bg-app min-h-screen py-8 flex flex-col items-center">
      <InnerHeader
        title={"KaKi MOP Start Page"}
        showBackButton={false}
      />
      <div className="mt-14">
        <p>
          Nutzername: <span className="font-bold">{userInfo?.pre_name}</span>
        </p>
        {isPick() && (
          <p>
            Ort: <span className="font-bold">{userInfo?.lane_name}</span>
          </p>
        )}
      </div>
      <div className="flex flex-col justify-center grow">
        <div className="w-80 mt-5">
          <PrimaryButton
            type="button"
            label={COMMON_MESSAGES.startNewJob}
            onClick={handleStart}
          />
        </div>
        {showContinuewithLastJobButton && (
          <div className="w-80 mt-5">
            <PrimaryButton
              type="button"
              label={COMMON_MESSAGES.continuePreviousJob}
              onClick={handleContinueWithPrevious}
            />
          </div>
        )}
        <div className="w-80 mt-5">
          <LogoutButton onClick={handleLogout} />
        </div>
      </div>
    </div>
  );
};
